export class ClaimPayment {
  id: number;
  Name: string;
  periodStartDate: Date;
  periodEndDate: Date;
  claimStartDate: Date;
  claimEndDate: Date;
  paymentDate: Date;
  paymentPercentage: number;
  groupReferenceId: string;
  groupName: string;
  totalAmount: number;
  claimPaymentId: number;
}
export class ClaimLineItem {
  memberId: string;
  claimId: number;
  claimPaymentId: number;
  planId: number;
  paymentAmount: number;
  notes: string;
}
