import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { first } from 'rxjs/operators';
import { IndemnityBenefitService } from 'src/app/services/indemnityBenefit.service';
import { ValidatorsService } from '../../../../helpers/validators.service';

@Component({
  selector: 'app-add-indemnity-benefit',
  templateUrl: './add-edit.component.html'
})
export class AddEditIndemnityBenefitComponent implements OnInit {
  @Input()
  indemnityBenefitId: number;
  indemnityBenefitForm: FormGroup;
  loading = false;
  submitted = false;
  errorMessage: string[] = [];
  successMessage = '';

  constructor(
    private _formBuilder: FormBuilder,
    private _indemnityBenefitService: IndemnityBenefitService, private validatorsService: ValidatorsService
  ) { }

  ngOnInit() {
    this.indemnityBenefitForm = this._formBuilder.group({
      name: ['', Validators.required],
      amount: [0, [Validators.required, this.validatorsService.nonNegativeValidator()]],
      monthlyLimit: [0, [Validators.required, this.validatorsService.nonNegativeValidator()]],
      isActive: [false],
      description: ['', Validators.required],
      code: ['', Validators.required]
      //broker: [''],
    });

    if (!!this.indemnityBenefitId) {
      this._indemnityBenefitService.getIndemnityBenefit(this.indemnityBenefitId).subscribe(indemnityBenefit => {
        this.indemnityBenefitForm.patchValue(indemnityBenefit);
      });
    }
  }
  
  // convenience getter for easy access to form fields
  get f() { return this.indemnityBenefitForm.controls; }

  onSubmit() {
    this.submitted = true;

    // reset alerts on submit
    this.successMessage = '';
    this.errorMessage = [];

    // stop here if form is invalid
    if (this.indemnityBenefitForm.invalid) {
      return;
    }

    this.loading = true;
    const indemnityBenefitId = this.indemnityBenefitId;
    // delete this.groupForm.value['broker'];
    if (!!indemnityBenefitId) {
      this._indemnityBenefitService.updateIndemnityBenefit(indemnityBenefitId, this.indemnityBenefitForm.value)
        .pipe(first())
        .subscribe(
          () => {
            // return to list
            this.successMessage = 'Indemnity Benefit updated successfully.';
            this.loading = false;
            this.errorMessage = [];
          },
          error => {
            if (error.error instanceof Array) {
              error.error.map(err => this.errorMessage.push(err.value));
            } else if (typeof error.error === 'string') {
              this.errorMessage.push(error.error);
            } else {
              this.errorMessage.push(error);
            }
            this.loading = false;
          });
    } else {
      this._indemnityBenefitService.addIndemnityBenefit(this.indemnityBenefitForm.value)
        .pipe(first())
        .subscribe(
          (indemnityBenefit) => {
            // return to list
            this.successMessage = 'Indemnity Benefit added successfully.';
            this.loading = false;
            this.indemnityBenefitId = indemnityBenefit.id;
            this.errorMessage = [];
          },
          error => {
            if (error.error instanceof Array) {
              error.error.map(err => this.errorMessage.push(err.value));
            } else if (typeof error.error === 'string') {
              this.errorMessage.push(error.error);
            } else {
              this.errorMessage.push(error);
            }
            this.loading = false;
          });
    }
  }
}


