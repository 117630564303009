import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';
import { auditTime } from 'rxjs/operators';
import { User } from 'src/app/models/User';
import { UserState, UserStore } from './user.store';

@Injectable({
    providedIn: 'root'
})
export class UserQuery extends QueryEntity<UserState> {

    isUserDetailsLoaded$ = this.select(state => {
        return state.isUserDetailLoaded;
    });

    userDetails$ = this.selectFirst(state => {
        return state;
    });

    constructor(protected store: UserStore) {
        super(store);
    }

    public getUserDetail(): Observable<User> {
        return this.selectFirst().pipe(auditTime(0));
    }
}
