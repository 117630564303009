import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './helpers/auth.guard';
import { LoginComponent } from './controls/login/login.component';
import { ChangePasswordComponent } from './controls/change-password/change-password.component';
import { ForgotPasswordComponent } from './controls/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './controls/reset-password/reset-password.component';
import { HRAFormComponent } from './controls/hra/hra.component';
import { AdminAuthGuard } from './helpers/auth-admin.gaurd';
import { ThankYouComponent } from './controls/ThankYou/thankyou.component';
import { WelcomeComponent } from './controls/Members/welcome/welcome.component';
import { SetPasswordComponent } from './controls/set-password/set-password.component';
import { BrokerAuthGuard } from './helpers/auth-broker.gaurd';
import { BrokerListComponent } from './controls/Admin/broker/broker-list/broker-list.component';
import { GroupListComponent } from './controls/Admin/groups/group-list/group-list.component';
import { UpdateGroupComponent } from './controls/Admin/groups/update-group/update-group.component';
import { UpdateVideoComponent } from './controls/Admin/videos/update-video/update-video.component';
import { VideoListComponent } from './controls/Admin/videos/video-list/video-list.component';
import { BrokerGroupsComponent } from './controls/Broker/broker-groups/broker-groups.component';
import { BrokerGroupMembersComponent } from './controls/Broker/broker-group-members/broker-group-members.component';
import { ClaimsListComponent } from './controls/Admin/claims/claims-list/claims-list.component';
import { EnrollmentComponent } from './controls/enrollment/enrollment.component';
import { CompletedLinkComponent } from './controls/Admin/employee/completionLink/completed-link/completed-link.component';
import { InvalidEnrollmentLinkComponent } from './controls/error-redirection/invalid-enrollment-link/invalid-enrollment-link.component';
import { MyVideoListComponent } from './controls/Members/my-video-list/my-video-list.component';
import { VideoPlayerComponent } from './controls/Members/video-player/video-player/video-player.component';
import { CategoriesListingComponent } from './controls/Members/categories-listing/categories-listing/categories-listing.component';
import { MemberAuthGuard } from './helpers/auth-member.guard';
import { GroupMemberListComponent } from './controls/Admin/groups/group-members/group-member-list/group-member-list.component';
import { ViewMemberHraComponent } from './controls/Admin/groups/group-members/view-member-hra/view-member-hra.component';
import { AddClaimComponent } from './controls/Admin/groups/group-members/add-claim/add-claim.component';
import { UpdateMemberComponent } from './controls/Admin/groups/group-members/update-member/update-member.component';
import { IndemnityBenefitListComponent } from './controls/Admin/indemnitybenefits/indemnitybenefit-list/indemnitybenefit-list.component';
import { UpdateIndemnityBenefitComponent } from './controls/Admin/indemnitybenefits/update-indemnitybenefit/update-indemnitybenefit.component';
import { MedicalReceiptListComponent } from './controls/Admin/wellnessprogresses/medicalreceipt-list/medicalreceipt-list.component';
import { UpdateMedicalReceiptComponent } from './controls/Admin/wellnessprogresses/update-medicalreceipt/update-medicalreceipt.component';
import { AddMedicalReceiptComponent } from './controls/Members/wellnessprogresses/add-medicalreceipt/add-medicalreceipt.component';
import { ViewMedicalReceiptComponent } from './controls/Members/wellnessprogresses/view-medicalreceipt/view-medicalreceipt.component';
import { PlanListComponent } from './controls/Admin/plans/plan-list/plan-list.component';
import { UpdatePlanComponent } from './controls/Admin/plans/update-plan/update-plan.component';
import { ViewWellnessprogressComponent } from './controls/Members/wellnessprogresses/view-wellnessprogress/view-wellnessprogress.component';
import { GetLeadComponent } from '../get-lead/get-lead.component';
import { MemberListComponent } from './controls/Admin/members/member-list/member-list.component';
import { MemberViewComponent } from './controls/Admin/members/member-view/member-view.component';
import { MemberEnrollmentComponent } from './controls/Admin/members/enrollment/enrollment.component';
import { LeadListComponent } from './controls/Admin/leads/lead-list/lead-list.component';
import { SelectPlansComponent } from './controls/Plans/select-plans/select-plans.component';
import { GroupMembersFamilyListComponent } from './controls/Admin/groups/group-members-family/group-members-family-list/group-members-family-list.component';
import { UpdateFamilyMembersComponent } from './controls/Admin/groups/group-members-family/update-family-members/update-family-members.component';
import { UploadLeadComponent } from './controls/Admin/leads/upload-lead/upload-lead.component';
import { ManageProfileComponent } from './controls/manage-profile/manage-profile.component';
import { PeriodListComponent } from './controls/Admin/period/period-list/period-list.component';
import { PeriodScheduleComponent } from './controls/Admin/period/period-schedule/period-schedule.component';
import { EditPeriodScheduleComponent } from './controls/Admin/period/period-schedule/edit-period-schedule/edit-period-schedule.component';
import { UpdatePeriodScheduleComponent } from './controls/Admin/period/period-schedule/update-period-schedule/update-period-schedule.component';
import { GroupViewComponent } from './controls/Admin/groups/group-view/group-view.component';
import { ClaimPaymentComponent } from './controls/Admin/claims/Claim-payment/claim-payment.component';
import { ClaimLineItemViewComponent } from './controls/Admin/claims/claim-line-item/claim-view/claim-view.component';
import { ClaimPaymentsListComponent } from './controls/Admin/claims/claim-payments-list/claim-payments-list.component';
import { InvoiceListComponent } from './controls/Admin/Invoice/invoice-list/invoice-list.component';
import { InvoiceViewComponent } from './controls/Admin/Invoice/invoice-list/invoice-view/invoice-view.component';
import { AddInvoiceComponent } from './controls/Admin/Invoice/add-invoice/add-invoice.component';
import { PeriodSchedulePlanComponent } from './controls/Admin/period/period-schedule-plan/period-schedule-plan.component';
import { UpdatePeriodSchedulePlanComponent } from './controls/Admin/period/period-schedule-plan/update-period-schedule-plan/update-period-schedule-plan.component';
import { InvoiceDetailComponent } from './controls/Admin/Invoice/invoice-detail/invoice-detail.component';

const routes: Routes = [
  { path: "Get/Lead", component: GetLeadComponent },
  /* { path: 'admin/enroll/member/:memberid', component: MemberEnrollmentComponent },*/
  //{ path: "Enrollment/Lead", component: EnrollmentLeadComponent }, 
  {
    path: "enrollment",
    children: [
      { path: "enroll", component: EnrollmentComponent },
      { path: "complete", component: CompletedLinkComponent },
      { path: "error", component: InvalidEnrollmentLinkComponent }
    ]
  },
  {
    path: "plans",
    children: [
      { path: "selection", component: SelectPlansComponent },
    ]
  },
  {
    path: 'admin',
    canActivate: [AdminAuthGuard],
    children: [
      { path: 'agents', component: BrokerListComponent },
      { path: 'groups', component: GroupListComponent },
      { path: 'groups/:groupId', component: UpdateGroupComponent },
      { path: 'groups/:groupId/members', component: GroupMemberListComponent },
      { path: "groups/:memberId/familyMembers", component: GroupMembersFamilyListComponent },
      { path: 'groups/:groupId/members/:memberId', component: UpdateMemberComponent },
      {
        path: "groups/:memberId/familymember/:id",
        component: UpdateFamilyMembersComponent,
      },
      { path: 'groups/:groupId/members/:memberId/view-hra', component: ViewMemberHraComponent },
      { path: 'groups/:groupId/lead-list', component: LeadListComponent },
      { path: 'members/:memberId/view-hra', component: ViewMemberHraComponent },
      { path: 'groups/:groupId/members/:memberId/add-claim', component: AddClaimComponent },
      {
        path: 'groups/:groupId/upload-lead', component: UploadLeadComponent
      },
      { path: 'videos', component: VideoListComponent },
      { path: 'videos/:videoId', component: UpdateVideoComponent },
      { path: 'claims', component: ClaimsListComponent },
      { path: 'invoices', component: InvoiceListComponent },
      { path: 'invoice-view/:id', component: InvoiceViewComponent },
      { path: 'invoice-detail/:id', component: InvoiceDetailComponent },
      { path: 'claim-payment-view/:id', component: ClaimLineItemViewComponent },
      { path: 'indemnitybenefits', component: IndemnityBenefitListComponent },
      { path: 'indemnitybenefits/:indemnityBenefitId', component: UpdateIndemnityBenefitComponent },
      { path: 'medicalreceipts', component: MedicalReceiptListComponent },
      { path: 'medicalreceipts/:medicalReceiptId', component: UpdateMedicalReceiptComponent },
      { path: 'plans', component: PlanListComponent },
      { path: 'plans/:id', component: UpdatePlanComponent },
      { path: "members", component: MemberListComponent },
      { path: "periods", component: PeriodListComponent },
      { path: "period/:periodid/period-schedule", component: PeriodScheduleComponent },
      { path: "periodschedule/:id", component: UpdatePeriodScheduleComponent },
      { path: 'periodSchedule-plan/:id', component: PeriodSchedulePlanComponent },
      { path: 'members/:memberid', component: MemberViewComponent },
      { path: 'enroll/member/:memberid', component: MemberEnrollmentComponent },
      { path: 'group-view/:id', component: GroupViewComponent },
      { path: 'claim-payment/:id', component: ClaimPaymentComponent },
      { path: 'invoice/:id', component: AddInvoiceComponent },
      { path: 'claim-payment-list/:id', component: ClaimPaymentsListComponent },
      { path: "period-schedule-plan/:id", component: UpdatePeriodSchedulePlanComponent },
      { path: '', redirectTo: 'admin/members', pathMatch: 'full' },
      // loadChildren: './controls/Admin/admin-routing.module#AdminRoutingModule'
    ]
  },
  {
    path: 'agents',
    canActivate: [BrokerAuthGuard],
    children: [
      { path: 'groups', component: BrokerGroupsComponent },
      { path: 'groups/:groupId', component: BrokerGroupMembersComponent },
      { path: '', redirectTo: 'agents/groups', pathMatch: 'full' },
      // loadChildren: () => import('./controls/Broker/broker-routing.module').then(m => m.BrokerRoutingModule)
    ]
  },
  {
    path: '',
    canActivate: [MemberAuthGuard],
    children: [
      { path: '', component: WelcomeComponent },
      // { path: 'videos', component: MyVideoListComponent },
      { path: 'videos/categories/:category', component: MyVideoListComponent },
      { path: 'videos/:videoId', component: VideoPlayerComponent },
      { path: 'categories', component: CategoriesListingComponent },
      { path: 'medicalreceipt', component: AddMedicalReceiptComponent },
      { path: 'medicalreceipt/view', component: ViewMedicalReceiptComponent },
      { path: 'wellnessprogress/view', component: ViewWellnessprogressComponent },
      { path: '', redirectTo: '/', pathMatch: 'full' },
    ]
  },

  { path: 'change-password', component: ChangePasswordComponent, canActivate: [AuthGuard] },
  { path: 'hra-form/:groupId/:memberId', component: HRAFormComponent },
  { path: 'hra-form/thankyou', component: ThankYouComponent },

  // { path: 'register', component: RegisterComponent },
  { path: 'login', component: LoginComponent },
  { path: 'set-password', component: SetPasswordComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'manage-profile', component: ManageProfileComponent },
  { path: '**', redirectTo: '' } // otherwise redirect to home
];

export const appRoutingModule = RouterModule.forRoot(routes);
