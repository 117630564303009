import { Component } from '@angular/core';
import { PlanService } from 'src/app/services/plan.service';
import { Plan } from 'src/app/models/plan';
import { ColDef } from 'ag-grid-community';
import { ActivatedRoute, Router } from '@angular/router';
import { BtnCellRendererComponent } from 'src/app/shared/controls/renderers/button-renderer/btn.renderer';

@Component({
  selector: "app-plan",
  templateUrl: "./plan-list.component.html",
  styleUrls: ["./plan-list.component.scss"],
})
export class PlanListComponent {
  selectedPlanId: number;
  addingPlan = false;
  signatureData: string;
  plans: Plan[];
  columnDefs: ColDef[] = [];
  defaultColDef = {
    flex: 1,
    minWidth: 150,
    resizable: true,
  };

  private gridApi;

  constructor(
    private _planService: PlanService,
    private _route: ActivatedRoute,
    private _router: Router,
    
  ) {
    this._route.params.subscribe((params) => {
      this.selectedPlanId = params["id"];
    });
    if (this.selectedPlanId) {
      this.addingPlan = true;
    }
    const _this = this;
    _this.getAllPlans();

    this.columnDefs = [
      { headerName: "Name", field: "name", suppressMovable: true, sortable: true, filter: true },
      {
        headerName: "Monthly Premium",
        field: "monthlyPremiumAmount",
        suppressMovable: true,
        sortable: true,
        filter: true,
        valueFormatter: (params) => "$" + params.data.monthlyPremiumAmount,
      },
      {
        headerName: "Monthly Claim",
        field: "monthlyClaimAmount",
        suppressMovable: true,
        sortable: true,
        filter: true,
        valueFormatter: (params) => "$" + params.data.monthlyClaimAmount,
      },
      {
        headerName: "Description",
        field: "description",
        suppressMovable: true,
        sortable: true,
        filter: true,
      },
    ];

    this.columnDefs.push({
      headerName: "",
      field: "id",
      suppressMovable: true,
      cellRendererFramework: BtnCellRendererComponent,
      cellRendererParams: {
        //showDeleteBtn: true,
        showEditBtn: true,
        clicked: function (isDelete: boolean, id: any) {
          if (isDelete) {
            _this.deletePlan(id);
          } else {
            // console.log(_this._router);
            // console.log(id);
            _this._router.navigate([`/admin/plans/${id}`]);
          }
        },
      },
    });
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridApi.setDomLayout("autoHeight");
  }

  getAllPlans() {
    this._planService.getAllPlans().subscribe((plans) => {
      this.plans = plans;
      // console.log('this.plans: ', this.plans);
    });
  }

  deletePlan(id: number) {
    this._planService.deletePlan(id).subscribe((x) => {
      this.plans = this.plans.filter((g) => g.id !== id);
      this.gridApi.setRowData(this.plans);
    });
  }

  addPlan() {
    this.addingPlan = true;
  }

  addUpdatePlan(planId: string) {
    this._router.navigate([`admin/addplan/${planId}`]);
  }

  // Route Static-- groupId/memberId/planId
  AddUpdateEmployee(planId: string) {
    this._router.navigate(['admin/enrollment'], {
      queryParams: {
        groupid: 1,
        id: planId
      }
    });

    // this._router.navigate([`admin/enrollment/1/${planId}`]);
  }

  showAllPlans() {
    this.getAllPlans();
    this.addingPlan = false;
  }
}
