import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { getApiUrl } from '../helpers/get-url';
import { Member } from '../models/member';
import { graph } from '../models/graph';
import { Observable } from 'rxjs/internal/Observable';
import { FamilyMembers } from '../models/family-member';
import { MemberStatus } from '../models/enums/memberStatus';

@Injectable({ providedIn: "root" })
export class MemberService {
  constructor(private http: HttpClient) { }

  getAllMembers(): Observable<Member[]> {
    return this.http.get<Member[]>(getApiUrl("members"));
  }

  AddMember(groupId: string = "b247edcd-2c8a-458a-a12b-39c142df1705", member: Member): Observable<Member> {
    return this.http.post<Member>(
      getApiUrl(`members/AddMemberDetails/${groupId}`), member);
  }
  getAllSearchMembers(memberStatus?: MemberStatus): Observable<Member[]> {
    let url = getApiUrl('members');
    if (!!memberStatus) {
      url += `?status=${memberStatus}`;
    }    
    return this.http.get<Member[]>(url);
  }

  getMember(externamMemberId: string): Observable<Member> {
    return this.http.get<Member>(getApiUrl(`members/${externamMemberId}`));
  }

  getMemberInfo(externamMemberId: string): Observable<Member> {
    return this.http.get<Member>(
      getApiUrl(`members/GetMemberInfo${externamMemberId}`)
    );
  }
  getMemberDetails(externamMemberId: string): Observable<Member> {
    return this.http.get<Member>(
      getApiUrl(`members/${externamMemberId}/info`)
    );
  }
  getMemberDetailsByMember(): Observable<Member> {
    return this.http.get<Member>(getApiUrl(`members/info`));
  }
  getMemberAddress(externamMemberId: string): Observable<any> {
    return this.http.get<any>(
      getApiUrl(`members/${externamMemberId}/address`)
    );
  }
  getMemberProvider(externamMemberId: string): Observable<any> {
    return this.http.get<any>(
      getApiUrl(`members/${externamMemberId}/externalProvider`)
    );
  }
  getMemberDependents(externamMemberId: string): Observable<any> {
    return this.http.get<any>(
      getApiUrl(`members/${externamMemberId}/dependents`)
    );
  }
  getMemberSignature(externamMemberId: string): Observable<any> {
    return this.http.get<any>(
      getApiUrl(`members/${externamMemberId}/signature`)
    );
  }
  getMemberAddressByMember(): Observable<any> {
    return this.http.get<any>(
      getApiUrl(`members/address`)
    );
  }
  getMemberDependentsByMember(): Observable<any> {
    return this.http.get<any>(
      getApiUrl(`members/dependents`)
    );
  }
  getMemberSignatureByMember(): Observable<any> {
    return this.http.get<any>(
      getApiUrl(`members/signature`)
    );
  }
  updateMemberStatus(member: any): Observable<any>{
    return this.http.put<any>(getApiUrl(`members/updateMemberStatus`),member);
  }
  addUpdateMemberExternalProvider(externalMemberId: string, member: Member): Observable<any> {
    return this.http.put<any>(getApiUrl(`members/${externalMemberId}/addUpdateMemberExternalProvider`), member);
  }
  updateMemberPersonalDetails(externamMemberId: string, member: Member): Observable<Member> {
    return this.http.put<any>(getApiUrl(`members/${externamMemberId}/updateMemberPersonalDetails`), member);
  }
  updateMemberInsurance(externamMemberId: string, member: Member): Observable<Member> {
    return this.http.put<any>(getApiUrl(`members/${externamMemberId}/updateMemberInsurance`), member);
  }
  addEditDependent(dependentId: number, member: Member, dependent: any): Observable<any> {
    member.dependents.push(dependent);
    return this.http.put<any>(
      getApiUrl(`members/${member.externalId}/addEditDependent/${dependentId}`), member);
  }
  deleteDependent(dependentId: number) {
    return this.http.delete(getApiUrl(`members/deleteDependent/${dependentId}`));
  }




  updateUserInfoByMember(user: any): Observable<Member> {
    return this.http.post<any>(getApiUrl(`user/update-user`), user);
  }
  updateMemberPersonalDetailsByMember(member: Member): Observable<Member> {    
    return this.http.put<any>(getApiUrl(`members/updateMemberPersonalDetailsByMember`),member);
  }
  updateMemberInsuranceByMember(member: Member): Observable<Member> {    
    return this.http.put<any>(getApiUrl(`members/updateMemberInsuranceByMember`),member);
  }
  updateMemberClaimByMember(member: Member): Observable<Member> {    
    return this.http.put<any>(getApiUrl(`members/updateMemberClaimByMember`),member);
  }
  addEditDependentByMember(dependentId: number, member: Member, dependent: any): Observable<any> {
    member.dependents.push(dependent);
    return this.http.put<any>(
      getApiUrl(`members/${dependentId}/addEditDependentByMember/`), member);
  }
  deleteDependentByMember(dependentId: number) {
    return this.http.delete(getApiUrl(`members/deleteDependentByMember/${dependentId}`));
  }





  AddMemberToDB(groupId: string, member: Member, dependentList: any[]): Observable<Member> {
    member.dependents = dependentList;
    return this.http.post<Member>(
      getApiUrl(`enroll/EnrollMember/`), member);
  }

  getFamilyMemberInfo(memberId: string): Observable<FamilyMembers> {
    return this.http.get<FamilyMembers>(getApiUrl(`MemberFamily/${memberId}`));
  }
  getSSNByMemberId(externamMemberId: string) {
    return this.http.get<any>(getApiUrl(`members/getSSNByMemberId/${externamMemberId}`));
  }
  deleteMember(externamMemberId: string) {
    return this.http.delete(getApiUrl(`members/${externamMemberId}`));
  }

  addMember(externalGroupId: string, memberInfo: Member) {
    return this.http.post<Member>(
      getApiUrl(`members/${externalGroupId}`),
      memberInfo
    );
  }
  addFamilyMember(memberInfo: Member) {
    return this.http.post<Member>(
      getApiUrl(`MemberFamily`),
      memberInfo
    );
  }

  updateMember(
    externalGroupId: string,
    externamMemberId: string,
    memberInfo: Member
  ) {
    return this.http.patch<Member>(
      getApiUrl(`members/${externalGroupId}/${externamMemberId}`),
      memberInfo
    );
  }
  updateFamilyMember(memeberfamilyid: string, memberInfo: Member) {
    return this.http.put<Member>(
      getApiUrl(`MemberFamily/UpdateFamilyMember/${memeberfamilyid}`),
      memberInfo
    );
  }

  deactivateMember(externalGroupId: string, externamMemberId: string) {
    return this.http.put<Member>(
      getApiUrl(`members/${externalGroupId}/${externamMemberId}`),
      ""
    );
  }

  getGraphDataForMember(): Observable<graph> {
    return this.http.get<graph>(getApiUrl("members/graph"));
  }

  checkIsEligibleForMedicalReceipts(): Observable<Member> {
    return this.http.get<Member>(
      getApiUrl("members/IsEligibleMedicalReceipts")
    );
  }

  getAllMembersWithoutClaim(
    month?: number,
    year?: number
  ): Observable<Member[]> {
    let url = getApiUrl("members/withoutClaims");
    let queryParamAdded = false;
    if (!!month) {
      queryParamAdded = true;
      url += `?month=${month}`;
    }

    if (!!year) {
      url += queryParamAdded ? "&" : "?";
      url += `year=${year}`;
    }

    return this.http.get<Member[]>(url);
  }

  getCityStateByZip(zipXml: string): Observable<any> {
    return this.http.post<any>(
      getApiUrl(`members/GetCityStateByZip?xml=` + zipXml), { responseType: 'text' }
    );
  }
  sendCertificateByAdmin(externamMemberId: string) {
    return this.http.get<any>(getApiUrl(`members/SendEnrollmentCertificate?externalMemberId=${externamMemberId}`));
  }

}
