import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { IAfterGuiAttachedParams } from 'ag-grid-community';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'member-name-renderer',
  templateUrl: './member-name-renderer.html',
  styleUrls: ["./member-name-renderer.scss"],
})
export class MemberNameRendererComponent implements ICellRendererAngularComp {

  params: any;
  constructor(private router: Router) {

  }
  refresh(params: any): boolean {
    return true;
  }

  afterGuiAttached?(params?: IAfterGuiAttachedParams): void {

  }

  agInit(params: any): void {
    this.params = params;
  }

  clickHandler() {
    this.params.clicked(this.params);
  }
  OnRedirectToMemberView() {
    let memberId = this.params.data.externalId;
    this.router.navigate([`/admin/members/${memberId}`]);
  }
  OnRedirectToEnrollFormView() {
    let memberId = this.params.data.externalId;
    this.router.navigate([]).then(result => { window.open(`/admin/enroll/member/${memberId}`, '_blank'); });
  }

  show(): boolean {
    return typeof this.params.show !== "undefined" ? this.params.show(this.params) : true;
  }
}
