import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-update-period-schedule',
  templateUrl: './update-period-schedule.component.html'
})
export class UpdatePeriodScheduleComponent {
   selectedId: string;
  constructor(private _route: ActivatedRoute, private _router: Router) {
    this._route.params.subscribe(params => { this.selectedId = params['id']; });
    }
}
