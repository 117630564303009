import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { DynamicControlBase } from '../dynamic-control.base';

@Component({
    selector: 'app-dynamic-form',
    templateUrl: './dynamic-form.component.html',
    styleUrls: ['./dynamic-form.component.scss']
})

export class DynamicFormComponent {

    @Input()
    questions: DynamicControlBase<string>[] = [];

    @Input()
    form: FormGroup;

    constructor() { }
}
