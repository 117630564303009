import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { MustMatch } from 'src/app/helpers/must-match.validators';

@Component({
    templateUrl: './set-password.component.html',
    styleUrls: ['./set-password.component.scss']
})
export class SetPasswordComponent implements OnInit {
    forgotPasswordForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    successMessage: string[] = [];
    errorMessage: string[] = [];

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private authenticationService: AuthenticationService) {
        // redirect to home if already logged in
        if (this.authenticationService.currentUserValue) {
            this.router.navigate(['/']);
        }
    }

    ngOnInit() {
        this.forgotPasswordForm = this.formBuilder.group({
            username: ['', [Validators.required, Validators.email]],
            lastName: ['', [Validators.required]],
            dob: ['', [Validators.required]],
            password: ['', [Validators.required, Validators.minLength(6)]],
            confirmPassword: ['', Validators.required],
        }, {
            validator: MustMatch('password', 'confirmPassword')
        });
    }

    // convenience getter for easy access to form fields
    get f() { return this.forgotPasswordForm.controls; }

    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.forgotPasswordForm.invalid) {
            return;
        }

        this.successMessage = [];
        this.errorMessage = [];
        this.loading = true;
        this.authenticationService.setPassword(this.forgotPasswordForm.value)
            .pipe(first())
            .subscribe(
                (): void => {
                    this.loading = false;
                    this.router.navigate(['/']);
                    // this.successMessage.push('Check you registered email, password reset link is sent.');
                },
                error => {
                    const _this = this;
                    if (error.error instanceof Array) {
                        error.error.map(err => _this.errorMessage.push(err.value));
                    } else if (typeof error.error === 'string') {
                        this.errorMessage.push(error.error);
                    } else {
                        this.errorMessage.push(error);
                    }
                    this.loading = false;
                });
    }
}
