import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ColDef } from 'ag-grid-community';
import { BrokerService } from 'src/app/services/broker.service';
import { BtnCellRendererComponent } from 'src/app/shared/controls/renderers/button-renderer/btn.renderer';

@Component({
  selector: 'app-broker-groups',
  templateUrl: './broker-groups.component.html',
  styleUrls: ['./broker-groups.component.scss']
})
export class BrokerGroupsComponent {

  groups;
  columnDefs: ColDef[] = [
    { hide: true, field: 'externalId' },
    { headerName: 'Group Name', field: 'name', suppressMovable: true, sortable: true, filter: true },
  ];

  defaultColDef = {
    flex: 1,
    minWidth: 150,
    resizable: true,
  };

  constructor(private _brokerservice: BrokerService, private _router: Router) {
    const _this = this;
    this._brokerservice.getAllGroups().subscribe(x => {
      this.groups = x;
    });

    this.columnDefs.push({
      headerName: '',
      field: 'externalId',
      suppressMovable: true,
      cellRendererFramework: BtnCellRendererComponent,
      cellRendererParams: {
        showClickableBtn: true,
        btnText: 'View Employees',
        clicked: function (boolean, referenceId: any) {
          _this._router.navigate([`/agents/groups/${referenceId}`]);
        }
      },
    });
  }
}
