import { InvoiceStatus } from "./enums/invoiceStatus";

export class Invoice {
  id: string;
  startDate: Date;
  endDate: Date;
  type: string;
  groupId: string;
  status: InvoiceStatus;
  amount: number;
  number: number;
  groupName: string;
  dueDate:Date;
  
}


