import { Component, OnInit } from '@angular/core';
import { VideoWithUserDetails } from 'src/app/models/VideoWithUserDetails';
import { VideoService } from 'src/app/services/video.service';
import { first } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-my-video-list',
  templateUrl: './my-video-list.component.html',
  styleUrls: ['./my-video-list.component.scss']
})
export class MyVideoListComponent implements OnInit {
  videos: VideoWithUserDetails[] = [];
  currentSelectedVideo: VideoWithUserDetails;
  playVideo = false;
  videoCategory: string;
  header = 'Videos';
  constructor(private _route: ActivatedRoute, private _router: Router, private _videoService: VideoService) {
    this._route.params.subscribe(params => { this.videoCategory = params['category']; });
  }

  ngOnInit() {
    if (!!this.videoCategory) {
      this.header += ` - ${decodeURI(this.videoCategory)}`;
    }

    this._videoService.getAllVideosWithUserStatus(this.videoCategory).pipe(first()).subscribe(x => this.videos = x);
  }

  playthisVideo(video: VideoWithUserDetails) {
    this._router.navigate([`/videos/${video.referenceId}`]);
  }

  getDate(date: string) {
    return date == null ? null : new Date(date + 'Z');
  }

  async showList() {
    this.playVideo = false;
  }

  videoCompleted() {
    this.currentSelectedVideo.status = 3; // completed
    this.videos[this.currentSelectedVideo.referenceId] = this.currentSelectedVideo;
  }

  toHoursMinutesSeconds(totalSeconds: number) {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return `${hours.toString()}:${minutes
      .toString()
      .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }
}
