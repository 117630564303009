import { NgModule } from '@angular/core';
import { AgGridModule } from 'ag-grid-angular';
import { AppComponent } from './app.component';
import { NavMenuComponent } from './shared/controls/nav-menu/nav-menu.component';
import { AlertComponent } from './shared/controls/alert/alert.component';
import { LoginComponent } from './controls/login/login.component';
import { RegisterComponent } from './controls/register/register.component';
import { ChangePasswordComponent } from './controls/change-password/change-password.component';
import { ForgotPasswordComponent } from './controls/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './controls/reset-password/reset-password.component';
import { VimeoPlayerComponent } from './controls/Members/vimeo-player/vimeo-player.component';
import { HRAFormComponent } from './controls/hra/hra.component';
import { VimeoUrlPipe } from './shared/pipes/vimeo-url.pipe';
import { DynamicFormComponent } from './data-driven/dynamic-form/dynamic-form.component';
import { DynamicFormBuilderComponent } from './data-driven/dynamic-form-builder/dynamic-form-builder.component';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { appRoutingModule } from './app.routing';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { ErrorInterceptor } from './helpers/error.interceptor';
import { VimeoService } from './services/vimeo.service';
import { DynamicControlService } from './data-driven/dynamic-control.Service';
import { QuestionService } from './data-driven/question.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from './shared/controls/modal/modal.component';
import { CommonModule, DatePipe } from '@angular/common';
import { SharedLayoutModule } from './shared/controls/layout/shared-layout.module';
import { SharedInputIconModule } from './shared/controls/input-icon';
import { FooterControl } from './shared/controls/layout/footer/footer.ctrl';
import { ThankYouComponent } from './controls/ThankYou/thankyou.component';
import { WelcomeComponent } from './controls/Members/welcome/welcome.component';
import { SetPasswordComponent } from './controls/set-password/set-password.component';
import { BrokerModule } from './controls/Broker/broker.module';
import { SharedModule } from './shared/shared.module';
import { AdminModule } from './controls/Admin/admin.module';
import {EnrollmentModule } from './controls/enrollment/enrollment.module';
import { MyVideoListComponent } from './controls/Members/my-video-list/my-video-list.component';
import { VideoPlayerComponent } from './controls/Members/video-player/video-player/video-player.component';
import { CategoriesListingComponent } from './controls/Members/categories-listing/categories-listing/categories-listing.component';
import { ViewMemberHraComponent } from './controls/Admin/groups/group-members/view-member-hra/view-member-hra.component';
import { AddMedicalReceiptComponent } from './controls/Members/wellnessprogresses/add-medicalreceipt/add-medicalreceipt.component';
import { ViewMedicalReceiptComponent } from './controls/Members/wellnessprogresses/view-medicalreceipt/view-medicalreceipt.component';
import { BtnCellRendererComponent } from './shared/controls/renderers/button-renderer/btn.renderer';
import { GaugeChartModule } from 'angular-gauge-chart';
import { ViewWellnessprogressComponent } from './controls/Members/wellnessprogresses/view-wellnessprogress/view-wellnessprogress.component';
import { RecaptchaModule } from 'ng-recaptcha';
import { CompletedLinkComponent } from './controls/Admin/employee/completionLink/completed-link/completed-link.component';
import { InvalidEnrollmentLinkComponent } from './controls/error-redirection/invalid-enrollment-link/invalid-enrollment-link.component';
import { PopupComponent } from './controls/pop-up/popup/popup.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
//import { EnrollmentLeadComponent } from 'src/enrollment-lead/enrollment-lead.component';
import { MatProgressBarModule, MatDatepickerModule, MatFormFieldModule, MatProgressSpinnerModule, MatNativeDateModule } from '@angular/material';
import { MaskPipe } from 'src/mask.pipe';
import { GetLeadComponent } from '../get-lead/get-lead.component';
import { SelectPlansComponent } from './controls/Plans/select-plans/select-plans.component';
import { SignaturePadModule } from 'angular2-signaturepad';
import { ManageProfileComponent } from './controls/manage-profile/manage-profile.component';
import { EditButtonRendererComponent } from './controls/Admin/period/renderButton/view-button-renderer.component';

 @NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    AlertComponent,
    MyVideoListComponent,
    VideoPlayerComponent,
    LoginComponent,
    RegisterComponent,
    ChangePasswordComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    VimeoPlayerComponent,
    HRAFormComponent,
    VimeoUrlPipe,
    DynamicFormComponent,
    DynamicFormBuilderComponent,
    ModalComponent,
    FooterControl,
    ThankYouComponent,
    WelcomeComponent,
    SetPasswordComponent,
    CategoriesListingComponent,
    ViewMemberHraComponent,
    AddMedicalReceiptComponent,
    ViewMedicalReceiptComponent,
    ViewWellnessprogressComponent,
    CompletedLinkComponent,
    InvalidEnrollmentLinkComponent,
    PopupComponent,
    //EnrollmentLeadComponent,
    MaskPipe,
    GetLeadComponent,
    SelectPlansComponent,
     ManageProfileComponent, EditButtonRendererComponent 
    
   ],

   
   entryComponents: [ModalComponent, PopupComponent, EditButtonRendererComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: "ng-cli-universal" }),
    HttpClientModule,
    BrowserModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NgbModule,
    CommonModule,
    SharedModule,
    appRoutingModule,
    SharedLayoutModule,
    SharedInputIconModule,
    AgGridModule.withComponents([BtnCellRendererComponent]),
    BrokerModule,
    AdminModule,
    EnrollmentModule,
    GaugeChartModule,
    RecaptchaModule,
    MatDialogModule,
    MatButtonModule,
    NoopAnimationsModule,
    MatProgressBarModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    MatNativeDateModule,
    SignaturePadModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },    
    DatePipe,
    VimeoService,
    DynamicControlService,
    QuestionService,
    
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
