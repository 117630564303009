import { Component, Input } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ColDef } from "ag-grid-community";
import { MemberWithHraStatus } from "src/app/models/member";
import { GroupService } from "src/app/services/group.service";
import { MemberService } from "src/app/services/member.service";
import { BtnCellRendererComponent } from "src/app/shared/controls/renderers/button-renderer/btn.renderer";
import { GroupNameRendererComponent } from "../../group-list/group-name-renderer/group-name-renderer";

@Component({
  selector: "app-group-member-list",
  templateUrl: "./group-member-list.component.html",
  styleUrls: ["./group-member-list.component.scss"],
})
export class GroupMemberListComponent {
  //@Input()
  //memberId: string;
  groupId: string;
  members: MemberWithHraStatus[];
  header: string = "";
  groupName: string = "";
  headerForAddMember: string = "";
  addingMember = false;
  selectedGroupId;
  selectedMemberId;

  defaultColDef = {
    
    resizable: true,
  };
  columnDefs: ColDef[] = [];

  private _gridApi;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _groupService: GroupService,
    private _memberService: MemberService
  ) {
    
    this._route.params.subscribe((params) => {
      this.groupId = params["id"];
    });
    //this.selectedMemberId = this.memberId;
    this._route.params.subscribe((params) => {
    //  this.selectedMemberId = params["memberId"];
    });
    this._groupService.getGroup(this.groupId).subscribe((group) => {
      if (!!group) {
        this.header = `Members of ${group.name} Group`;
        this.headerForAddMember = `Member of ${group.name} Group`;
        this.groupName = group.name;
        this.selectedGroupId = group.externalId;
      }
    });

    this.getAllMembers();
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const currentDate = new Date();
    const _this = this;
    this.columnDefs = [
      {
        headerName: "External Id",
        field: "externalId",
        suppressMovable: true,
        sortable: true,
        filter: true,
        width:150
      },
      {
        headerName: "First Name",
        field: "firstName",
        suppressMovable: true,
        sortable: true,
        filter: true,
        width:150
        // cellRendererFramework: GroupNameRendererComponent,
        // cellRendererParams: {
        //   clicked: function (params: any) {
        //     _this._router.navigate([
        //       `/admin/groups/${params.data.externalId}/familyMembers`,
        //     ]);
        //   },
        // },
      },
      {
        headerName: "Last Name",
        field: "lastName",
        suppressMovable: true,
        sortable: true,
        filter: true,
        width: 150
      },
      { headerName: "Email", field: "email", suppressMovable: true, sortable: true, filter: true },
      {
        headerName: "Plan Name",
        field: "planName",
        suppressMovable: true,
        sortable: true,
        filter: true,
        width: 150
      },
      {
        headerName: "Member Status",
        field: "memberStatus",
        suppressMovable: true,
        sortable: true,
        filter: true,
        width: 150
      },
      {
        headerName: "Questionnaire Status",
        field: "isHraCompleted",
        suppressMovable: true,
        width: 150,
        cellRendererFramework: GroupNameRendererComponent,
        cellRendererParams: {
          btnText: "View",
          NoShowText: "Add",
          isNoShowTextClickable: true,
          clicked: function (params: any) {
            if (!!params.data.isHraCompleted) {
              _this._router.navigate([
                `admin/groups/${_this.groupId}/members/${params.data.externalId}/view-hra`,
              ]);
            } else {
              _this._router.navigate([
                `/hra-form/${_this.selectedGroupId}/${params.data.externalId}`,
              ]);
            }
          },
          show: function (params: any) {
            return params.value;
          },
        },
      },
      {
        headerName: `${monthNames[currentDate.getMonth()]} Claim Status`,
        field: "isClaimCompletedForCurrentMonth",
        suppressMovable: true,
        width: 150,
        cellRendererFramework: GroupNameRendererComponent,
        cellRendererParams: {
          btnText: "Add Claim",
          NoShowText: "Completed",
          clicked: function (params: any) {
            _this._router.navigate([
              `admin/groups/${_this.groupId}/members/${params.data.externalId}/add-claim`,
            ]);
          },
          show: function (params: any) {
            return !params.value;
          },
        },
      },
      {
        headerName: "",
        field: "",
        suppressMovable: true,         
        cellRendererFramework:
        GroupNameRendererComponent, cellRendererParams: {
          showViewBtn: true,
          showEnrollViewBtn: true,
          clicked: function (params: any) {
            _this._router.navigate([`/admin/members/${params.data.externalId}`]);
          }
        }
      },
    ];
  }
  exportMembers() {    
    this._groupService.exportMembers(this.groupId)
      .subscribe((response) => {
        let blob: any = new Blob([response], {
          type: "text/csv; charset=utf-8",
        });
        var filename = `${this.groupName}-members.csv`;
        this.downloadContent(blob, filename);
      });
  }
  private downloadContent(body: Blob, fileName: string): void {
    let url = window.URL.createObjectURL(body);
    let a = document.createElement("a");
    document.body.appendChild(a);
    a.setAttribute("style", "display: none");
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }
  deactivateMember(memberId: string) {
    return this._memberService
      .deactivateMember(this.selectedGroupId, memberId)
      .subscribe();
  }

  onGridReady(params) {
    this._gridApi = params.api;
    this._gridApi.setDomLayout("autoHeight");
  }

  addMember() {
    this.addingMember = true;
  }

  showAllMembers() {
    this.getAllMembers();
    this.addingMember = false;
  }

  getAllMembers() {
    this._groupService.getGroupMembers(this.groupId).subscribe((members) => {     
      this.members = members;
    });
  }
}
