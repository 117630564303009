import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { MustMatch } from 'src/app/helpers/must-match.validators';
import { Member } from 'src/app/models/member';
import { AlertifyService } from 'src/app/services/alertify.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { EnrollmentService } from 'src/app/services/enrollment.service';
import { MemberService } from 'src/app/services/member.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-manage-profile',
  templateUrl: './manage-profile.component.html',
  styleUrls: ['./manage-profile.component.css']
})
export class ManageProfileComponent implements OnInit {
  //memberId: string;
  memberForm: FormGroup;
  addDependentForm: FormGroup;
  userDetails: any;
  memberData: Member = new Member();
  ipAddress: string;
  loading: boolean;
  submitted: boolean;
  showStartDate: boolean = false;
  startDate: Date;
  minStartDate: string;
  isStartDateValid: boolean = true;

  // for user Details
  userForm: FormGroup;
  user: any;
  // for personal details
  minPrimaryAge: string;
  maxPrimaryAge: string;
  isMinPrimaryAgeValid: boolean = true;
  isMaxPrimaryAgeValid: boolean = true;
  city: string = '';
  state: string = '';
  // for dependent
  dependentText: string = '';
  dependentId: number = 0;
  minDependentAge: string;
  maxDependentAge: string;
  minChildAge: string;
  isMinDependentAgeValid: boolean = true;
  isMaxDependentAgeValid: boolean = true;
  noDependent = 'No Dependent Found';

  // for insurance
  addInsuranceForm: FormGroup;
  // for claim
  addClaimForm: FormGroup;
  routingMessage: string;
  isValidRoutingNumber: boolean;
  constructor(private _route: ActivatedRoute,
    private _router: Router,
    private _memberService: MemberService,
    private _fb: FormBuilder,
    private _userService: UserService,
    private _enrollmentService: EnrollmentService,
    private _authenticationService: AuthenticationService,
    private _alertify: AlertifyService) {
    // status activate start
    var minSD = new Date();
    minSD.setMonth(minSD.getMonth() - 1);
    var yr = minSD.getFullYear();
    var mn = (minSD.getMonth() + 1) > 9 ? (minSD.getMonth() + 1) : '0' + (minSD.getMonth() + 1);
    var dt = minSD.getDate();
    this.minStartDate = yr + '-' + mn + '-' + (dt < 10 ? '0' + dt : dt);
    //for personal
    var minPA = new Date();
    minPA.setFullYear(minPA.getFullYear() - 100);
    yr = minPA.getFullYear();
    mn = (minPA.getMonth() + 1) > 9 ? (minPA.getMonth() + 1) : '0' + (minPA.getMonth() + 1);
    dt = minPA.getDate();
    this.minPrimaryAge = yr + '-' + mn + '-' + (dt < 10 ? '0' + dt : dt);
    var maxPA = new Date();
    maxPA.setFullYear(maxPA.getFullYear() - 21);
    yr = maxPA.getFullYear();
    mn = (maxPA.getMonth() + 1) > 9 ? (maxPA.getMonth() + 1) : '0' + (maxPA.getMonth() + 1);
    dt = maxPA.getDate();
    this.maxPrimaryAge = yr + '-' + mn + '-' + (dt < 10 ? '0' + dt : dt);
    this.maxDependentAge = yr + '-' + mn + '-' + (dt < 10 ? '0' + dt : dt);  // for dependent

    var minDA = new Date();
    yr = minDA.getFullYear();
    mn = (minDA.getMonth() + 1) > 9 ? (minDA.getMonth() + 1) : '0' + (minDA.getMonth() + 1);
    dt = minDA.getDate();
    this.minChildAge = yr + '-' + mn + '-' + (dt < 10 ? '0' + dt : dt);
    minDA.setFullYear(minDA.getFullYear() - 100);
    yr = minDA.getFullYear();
    mn = (minDA.getMonth() + 1) > 9 ? (minDA.getMonth() + 1) : '0' + (minDA.getMonth() + 1);
    dt = minDA.getDate();
    this.minDependentAge = yr + '-' + mn + '-' + (dt < 10 ? '0' + dt : dt);
    this.getMemberForm();
    this.getDependentForm();
    this.getInsuranceForm();
    this.getUserForm();
    this.getClaimForm();
  }
  ngOnInit() {
    //this.getUserDetails();
    this.getMemberById();//for member details
    this.getMemberAddress(); // for address   
    this.getMemberSignature(); // signature details
  }
  getMemberById() {
    this._memberService.getMemberDetailsByMember().subscribe(member => {
      this.memberData = member;
      this.getMemberDependents(); // for dependents
    });
  }
  getMemberDependents() {
    this._memberService.getMemberDependentsByMember().subscribe((dependents: any) => {
      this.memberData.dependents = dependents;
    }, error => {
      this.loading = false;
      this.memberData.dependents = [];
    });
  }
  getMemberAddress() {
    this._memberService.getMemberAddressByMember().subscribe((address: any) => {
      this.memberData.addressLine1 = address.mailingAddress;
      this.memberData.addressLine2 = address.apartmentNumber;
      this.memberData.city = address.city;
      this.memberData.zipCode = address.zipCode;
      this.memberData.state = address.state;
    });
  }


  getMemberSignature() {
    this._memberService.getMemberSignatureByMember().subscribe((signature: any) => {
      if (signature) {
        this.memberData.browserName = signature.browserName;
        this.memberData.browserVersion = signature.browserVersion;
        this.ipAddress = signature.ipAddress;
        this.memberData.signatureDateTime = signature.signatureDateTime;
      }
    });
  }
  updateStatus(status: any) {
    this.submitted = true;
    if (status == 1) {
      this.showStartDate = true;
      if (!this.startDate) {
        return;
      }
      this.loading = true;
      const updateMember: any = {
        startDate: this.startDate,
        memberStatus: status
      }
      this._memberService.updateMemberStatus(updateMember).subscribe(res => {
        this.memberData.memberStatus = res.memberStatus;
        this.loading = false;
        this.submitted = false;
        this.showStartDate = false;
        this.startDate = null;
        this._alertify.success("Status updated successfully");

      }, error => {

        this.loading = false;
        this.submitted = false;
        this._alertify.error(error.error);
      });
    }
    else {
      this.loading = true;
      const updateMember: any = {
        startDate: this.startDate,
        memberStatus: status
      }
      this._memberService.updateMemberStatus(updateMember).subscribe(res => {
        this.memberData.memberStatus = res.memberStatus;
        this.loading = false;
        this.submitted = false;
        this.showStartDate = false;
        this.startDate = null;
        this._alertify.success("Status updated successfully");

      }, error => {

        this.loading = false;
        this.submitted = false;
        this._alertify.error(error.error);
      });
    }

  }
  submitActivation() {
    this.submitted = true;
    if (new Date(this.startDate) > new Date(this.minStartDate)) {
      this.isStartDateValid = true;
      this.updateStatus(1);
    }
    else {
      this.isStartDateValid = false;
    };
  }

  onSubmit() {    
    this.submitted = true;
    if (!this.isMaxPrimaryAgeValid || !this.isMinPrimaryAgeValid) {     
      return;
    }
    this.memberForm.controls.city.setValue(this.city);
    this.memberForm.controls.state.setValue(this.state);
    if (!this.memberForm.valid) {      
      return;
    }
    this.loading = true;
    this._memberService.updateMemberPersonalDetailsByMember(this.memberForm.value)
      .pipe(first())
      .subscribe(
        (response) => {
          this.memberData = response;
          this.getMemberDependents();
          this.getMemberAddress();
          this.loading = false;
          this.submitted = false;
          const button = document.getElementById('closeP')!;
          button.click();
          this._alertify.success("Personal details updated successfully");
        },
        (error) => {
          if (error.error instanceof Array) {
            this._alertify.error(error.error);
            error.error.map((err) => this._alertify.error(err.value));
          } else if (typeof error.error === "string") {
            this._alertify.error(error.error);
          } else {
            this._alertify.error(error.error);
          }
          this.loading = false;
        }
      );
  }

  onUserUpdate() {
    this.submitted = true;

    if (!this.userForm.valid) {
      return;
    }

    this.loading = true;
    this._memberService.updateUserInfoByMember(this.userForm.value)
      .pipe(first())
      .subscribe(
        (response) => {
          this.loading = false;
          this.submitted = false;
          const button = document.getElementById('closeU')!;
          button.click();
          this._alertify.success("User updated successfully");
          if (this.userDetails.userName != this.userForm.value.userName) {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
          this.getUserDetails();
        },
        (error) => {
          if (error.error instanceof Array) {
            this._alertify.error(error.error);
            error.error.map((err) => this._alertify.error(err.value));
          } else if (typeof error.error === "string") {
            this._alertify.error(error.error);
          } else {
            this._alertify.error(error.error);
          }
          this.loading = false;
        }
      );
  }
  showAllMembers() {
    this._router.navigate([`/admin/members`]);
  }
  getUserForm() {
    this.userForm = this._fb.group({
      userName: ["", Validators.email],
      email: ["", [Validators.required, Validators.email]],
      phoneNumber: ["", Validators.required]
    });
  }
  get u() {
    return this.userForm.controls;
  }
  setUserForm() {
    this.u.userName.setValue(this.userDetails.userName);
    this.u.phoneNumber.setValue(this.userDetails.phoneNumber);
    this.u.email.setValue(this.userDetails.email);
  }

  getMemberForm() {
    this.memberForm = this._fb.group({
      id: [""],
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      addressLine1: ["", Validators.required],
      addressLine2: [""],
      middleName: [""],
      zipCode: ["", Validators.required],
      mobile: ["", Validators.required],
      secondaryMobile: [""],
      email: ["", [Validators.required, Validators.email]],
      dob: ["", Validators.required],
      gender: ["", Validators.required],
      maritalStatus: ["", Validators.required],
      city: [""],
      state: [""]
    });
  }
  get f() {
    return this.memberForm.controls;
  }
  dobValidatePrimary() {
    const minDate = new Date();
    minDate.setFullYear(minDate.getFullYear() - 100);
    const maxDate = new Date();
    maxDate.setFullYear(maxDate.getFullYear() - 21);
    const dob = new Date(this.memberForm.controls.dob.value);
    if (dob < minDate) {
      this.isMaxPrimaryAgeValid = false;
    }
    else {
      this.isMaxPrimaryAgeValid = true;
    }

    if (dob > maxDate) {
      this.isMinPrimaryAgeValid = false;
    }
    else {
      this.isMinPrimaryAgeValid = true;
    }
  }
  setMemberForm() {
    this.f.id.setValue(this.memberData.id);
    this.f.firstName.setValue(this.memberData.firstName);
    this.f.lastName.setValue(this.memberData.lastName);
    this.f.addressLine1.setValue(this.memberData.addressLine1);
    this.f.addressLine2.setValue(this.memberData.addressLine2);
    this.f.middleName.setValue(this.memberData.middleName);
    this.f.zipCode.setValue(this.memberData.zipCode);
    this.f.mobile.setValue(this.memberData.mobile);
    this.f.secondaryMobile.setValue(this.memberData.secondaryMobile);
    this.f.email.setValue(this.memberData.email);
    this.f.dob.setValue(this.memberData.dob == null ? '' : this.memberData.dob.toString().substr(0, 10));
    //this.dobValidatePrimary();
    if (this.memberData.gender != 'NotSpecified') {
      this.f.gender.setValue(this.memberData.gender);
    }
    if (this.memberData.maritalStatus != null && this.memberData.maritalStatus != 'Yes') {
      this.f.maritalStatus.setValue(this.memberData.maritalStatus);
    }
    this.f.city.setValue(this.memberData.city);
    this.f.state.setValue(this.memberData.state);
  }

  handleOnChange(event: any) {
    if (event.target.value.length >= 5) {
      let xml: string = '<CityStateLookupRequest USERID="8H6THINK42633"><ZipCode ID="0"><Zip5>' + event.target.value + '</Zip5></ZipCode></CityStateLookupRequest>';
      this._memberService.getCityStateByZip(xml).subscribe((res) => {
        if (res) {
          this.city = res.zipCode.city;
          this.state = res.zipCode.state;
          this.f.city.setValue(this.city);
          this.f.state.setValue(this.state);
        }
      });
    }
  }
  getDependentForm() {
    this.addDependentForm = this._fb.group({
      type: ["", Validators.required],
      firstName: ["", Validators.required],
      middleName: [""],
      lastName: ["", Validators.required],
      dob: ["", Validators.required],
      gender: ["", Validators.required],
    });
  }

  get d() {
    return this.addDependentForm.controls;
  }

  dobValidate() {
    const minDate = new Date();
    const minAge = this.addDependentForm.controls.type.value == 'Child' ? 21 : 100
    minDate.setFullYear(minDate.getFullYear() - minAge);
    const maxDate = new Date();
    const maxAge = this.addDependentForm.controls.type.value == 'Child' ? 0 : 21
    maxDate.setFullYear(maxDate.getFullYear() - maxAge);
    const dob = new Date(this.addDependentForm.controls.dob.value);
    if (dob < minDate) {
      this.isMaxDependentAgeValid = false;
    }
    else {
      this.isMaxDependentAgeValid = true;
    }

    if (dob > maxDate) {
      this.isMinDependentAgeValid = false;
    }
    else {
      this.isMinDependentAgeValid = true;
    }
  }

  updateAgeValidation() {
    if (this.addDependentForm.controls.type.value == 'Spouse') {
      var minPA = new Date();
      minPA.setFullYear(minPA.getFullYear() - 100);
      var yr = minPA.getFullYear();
      var mn = (minPA.getMonth() + 1) > 9 ? (minPA.getMonth() + 1) : '0' + (minPA.getMonth() + 1);
      var dt = minPA.getDate();
      this.minDependentAge = yr + '-' + mn + '-' + (dt < 10 ? '0' + dt : dt);
      var maxPA = new Date();
      maxPA.setFullYear(maxPA.getFullYear() - 21);
      yr = maxPA.getFullYear();
      mn = (maxPA.getMonth() + 1) > 9 ? (maxPA.getMonth() + 1) : '0' + (maxPA.getMonth() + 1);
      dt = maxPA.getDate();
      this.maxDependentAge = yr + '-' + mn + '-' + (dt < 10 ? '0' + dt : dt);
    }
    else if (this.addDependentForm.controls.type.value == 'Child') {
      var maxPA = new Date();
      maxPA.setFullYear(maxPA.getFullYear() - 21);
      yr = maxPA.getFullYear();
      mn = (maxPA.getMonth() + 1) > 9 ? (maxPA.getMonth() + 1) : '0' + (maxPA.getMonth() + 1);
      dt = maxPA.getDate();
      this.minDependentAge = yr + '-' + mn + '-' + (dt < 10 ? '0' + dt : dt);
      this.maxDependentAge = this.minChildAge;
    }
  }
  editDependent(dependent: any) {
    if (dependent == null) {
      this.dependentId = 0;
      this.dependentText = "Add";
      this.getDependentForm();
    }
    else {
      this.dependentText = "Update";
      this.setDependentForm(dependent);
    }
  }
  removeDependent(dependent: any, index: number) {
    this._memberService.deleteDependentByMember(dependent.dependentId).subscribe(res => {
      this.loading = false;
      this.submitted = false;
      this.getMemberDependents();
      this._alertify.success("Dependent removed successfully");
    }, error => {
      if (error.error instanceof Array) {
        error.error.map((err) => this._alertify.error(err.value));
      } else if (typeof error.error === "string") {
        this._alertify.error(error.error);
      } else {
        this._alertify.error(error.error);
      }
      this.loading = false;
    })
  }
  setDependentForm(dependent: any) {
    this.dependentId = dependent.dependentId;
    this.d.firstName.setValue(dependent.firstName);
    this.d.lastName.setValue(dependent.lastName);
    this.d.type.setValue(dependent.type);
    this.d.middleName.setValue(dependent.middleName);
    this.d.dob.setValue(dependent.dob.toString().substr(0, 10));
    this.dobValidate();
    this.d.gender.setValue(dependent.gender);
  }

  onDependentSubmit() {
    this.submitted = true;
    if (!this.isMaxDependentAgeValid || !this.isMinDependentAgeValid) {
     return;
    }
    if (this.addDependentForm.valid) {
      if (this.dependentId == 0) {
        if (this.addDependentForm.controls.type.value == 'Spouse' && this.memberData.dependents != undefined && this.memberData.dependents.filter(x => x.type.toLowerCase() == 'spouse').length > 0) {
          this._alertify.error("You have already added Spouse");
        }
        else {
          const member: any = {
            dependents: []
          };
          this._memberService.addEditDependentByMember(this.dependentId, member, this.addDependentForm.value).subscribe(res => {
            this.loading = false;
            this.submitted = false;
            this._alertify.success("Dependent added successfully");
            this.getMemberDependents();
            this.getDependentForm();
            const button = document.getElementById('closeD')!;
            button.click();
          }, error => {
            if (error.error instanceof Array) {
              error.error.map((err) => this._alertify.error(err.value));
            } else if (typeof error.error === "string") {
              this._alertify.error(error.error);
            } else {
              this._alertify.error(error.error);
            }
            this.loading = false;
          })
        }
      }
      else {
        const spouse = this.memberData.dependents.filter(x => x.type.toLowerCase() == 'spouse');
        if (spouse.length > 0) {
          if (this.addDependentForm.controls.type.value == 'Spouse' && spouse[0].dependentId != this.dependentId) {
            this._alertify.error("You have already added Spouse");
          }
          else {
            const member: any = {
              dependents: []
            };
            this._memberService.addEditDependentByMember(this.dependentId, member, this.addDependentForm.value).subscribe(res => {
              this.loading = false;
              this.submitted = false;
              this.getMemberDependents();
              this.getDependentForm();
              this._alertify.success("Dependent updated successfully");
              const button = document.getElementById('closeD')!;
              button.click();
            }, error => {
              if (error.error instanceof Array) {
                error.error.map((err) => this._alertify.error(err.value));
              } else if (typeof error.error === "string") {
                this._alertify.error(error.error);
              } else {
                this._alertify.error(error.error);
              }
              this.loading = false;
            })
          }
        }
        else {
          const member: any = {
            dependents: []
          };
          this._memberService.addEditDependentByMember(this.dependentId, member, this.addDependentForm.value).subscribe(res => {
            this.loading = false;
            this.submitted = false;
            this.getMemberDependents();
            this._alertify.success("Dependent updated successfully");
            const button = document.getElementById('closeD')!;
            button.click();
          }, error => {
            if (error.error instanceof Array) {
              error.error.map((err) => this._alertify.error(err.value));
            } else if (typeof error.error === "string") {
              this._alertify.error(error.error);
            } else {
              this._alertify.error(error.error);
            }
            this.loading = false;
          })
        }
      }

    }
  }

  getInsuranceForm() {
    this.addInsuranceForm = this._fb.group({
      hasInsurance: ["1", Validators.required],
      healthInsuranceCompanyName: ["", Validators.required],
      insurancePolicyNo: ["", Validators.required],
      insuranceEffectiveDate: ["", Validators.required],
      insuranceEndDate: ["", Validators.required],
      insuranceAddress: ["", Validators.required]
    });
  }

  get i() {
    return this.addInsuranceForm.controls;
  }

  setInsuranceForm() {
    this.i.hasInsurance.setValue(this.memberData.insurancePolicyNo == null || this.memberData.insurancePolicyNo == '' ? "2" : "1");
    this.i.healthInsuranceCompanyName.setValue(this.memberData.healthInsuranceCompanyName);
    this.i.insurancePolicyNo.setValue(this.memberData.insurancePolicyNo);
    this.i.insuranceEffectiveDate.setValue(this.memberData.insuranceEffectiveDate == null || this.memberData.insuranceEffectiveDate == '' ? '' : this.memberData.insuranceEffectiveDate.substr(0, 10));
    this.i.insuranceEndDate.setValue(this.memberData.insuranceEndDate == null || this.memberData.insuranceEndDate == '' ? '' : this.memberData.insuranceEndDate.substr(0, 10));
    this.i.insuranceAddress.setValue(this.memberData.insuranceAddress);
  }

  onInsuranceSubmit() {
    if (this.addInsuranceForm.controls.hasInsurance.value == 1) {
      this.addInsuranceForm.get('healthInsuranceCompanyName').setValidators([Validators.required]);
      this.addInsuranceForm.get('healthInsuranceCompanyName').updateValueAndValidity();
      this.addInsuranceForm.get('insurancePolicyNo').setValidators([Validators.required]);
      this.addInsuranceForm.get('insurancePolicyNo').updateValueAndValidity();
      this.addInsuranceForm.get('insuranceAddress').setValidators([Validators.required]);
      this.addInsuranceForm.get('insuranceAddress').updateValueAndValidity();
      this.addInsuranceForm.get('insuranceEffectiveDate').setValidators([Validators.required]);
      this.addInsuranceForm.get('insuranceEffectiveDate').updateValueAndValidity();
      this.addInsuranceForm.get('insuranceEndDate').setValidators([Validators.required]);
      this.addInsuranceForm.get('insuranceEndDate').updateValueAndValidity();
    } else {
      this.addInsuranceForm.get('healthInsuranceCompanyName').clearValidators();
      this.addInsuranceForm.get('healthInsuranceCompanyName').updateValueAndValidity();
      this.addInsuranceForm.get('insurancePolicyNo').clearValidators();
      this.addInsuranceForm.get('insurancePolicyNo').updateValueAndValidity();
      this.addInsuranceForm.get('insuranceAddress').clearValidators();
      this.addInsuranceForm.get('insuranceAddress').updateValueAndValidity();
      this.addInsuranceForm.get('insuranceEffectiveDate').clearValidators();
      this.addInsuranceForm.get('insuranceEffectiveDate').updateValueAndValidity();
      this.addInsuranceForm.get('insuranceEndDate').clearValidators();
      this.addInsuranceForm.get('insuranceEndDate').updateValueAndValidity();
      this.addInsuranceForm.controls.healthInsuranceCompanyName.setValue('');
      this.addInsuranceForm.controls.insurancePolicyNo.setValue('');
      this.addInsuranceForm.controls.insuranceAddress.setValue('');
      this.addInsuranceForm.controls.insuranceEffectiveDate.setValue(null);
      this.addInsuranceForm.controls.insuranceEndDate.setValue(null);
    }

    this.submitted = true;
    if (this.addInsuranceForm.invalid) {
      return;
    }
    this._memberService.updateMemberInsuranceByMember(this.addInsuranceForm.value).subscribe(res => {
      this.loading = false;
      this.submitted = false;
      this.getMemberById();
      this._alertify.success("Insurance updated successfully");
      const button = document.getElementById('closeI')!;
      button.click();
    }, error => {
      if (error.error instanceof Array) {
        error.error.map((err) => this._alertify.error(err.value));
      } else if (typeof error.error === "string") {
        this._alertify.error(error.error);
      } else {
        this._alertify.error(error.error);
      }
      this.loading = false;
    })
  }
  getUserDetails() {
    this._userService.getUserDetails().subscribe(res => {
      this.userDetails = res;
    })
  }
  changePassword() {
    this._router.navigate(['/change-password']);
  }

  getAddressFromRoutingNumber() {
    var _this = this;
    this._enrollmentService.validateRoutingNumber(this.addClaimForm.value.routingNumber)
      .pipe(first())
      .subscribe(
        (res: any) => {
          _this.routingMessage = res.message;
          _this.isValidRoutingNumber = true;
        },
        (error) => {
          _this.isValidRoutingNumber = false;
          _this.routingMessage = error.error.message;
        }
      );
  }
  getClaimForm() {
    this.addClaimForm = this._fb.group({
      hasClaim: ["1", Validators.required],
      accountNumber: ["", Validators.required],
      confirmAccountNumber: ["", Validators.required],
      routingNumber: ["", Validators.required],
      // accountType: ["", Validators.required]
    },
      {
        validator: MustMatch('accountNumber', 'confirmAccountNumber'),
      });
  }
  get c() {
    return this.addClaimForm.controls;
  }
  setClaimForm() {
    this.c.accountNumber.setValue(this.memberData.accountNumber);
    this.c.confirmAccountNumber.setValue(this.memberData.accountNumber);
    this.c.routingNumber.setValue(this.memberData.routingNumber);
    //this.c.accountType.setValue(this.memberData.accountType);
  }
  onClaimSubmit() {
    if (this.addClaimForm.controls.hasClaim.value == 1) {
      this.addClaimForm.get('accountNumber').setValidators([Validators.required]);
      this.addClaimForm.get('accountNumber').updateValueAndValidity();
      this.addClaimForm.get('confirmAccountNumber').setValidators([Validators.required]);
      this.addClaimForm.get('confirmAccountNumber').updateValueAndValidity();
      this.addClaimForm.get('routingNumber').setValidators([Validators.required]);
      this.addClaimForm.get('routingNumber').updateValueAndValidity();
      if (!this.isValidRoutingNumber) {
        return;
      }
      this.loading = true;
    } else {
      this.addClaimForm.get('accountNumber').clearValidators();
      this.addClaimForm.get('accountNumber').updateValueAndValidity();
      this.addClaimForm.get('confirmAccountNumber').clearValidators();
      this.addClaimForm.get('confirmAccountNumber').updateValueAndValidity();
      this.addClaimForm.get('routingNumber').clearValidators();
      this.addClaimForm.get('routingNumber').updateValueAndValidity();
      this.addClaimForm.controls.accountNumber.setValue('');
      this.addClaimForm.controls.confirmAccountNumber.setValue('');
      this.addClaimForm.controls.routingNumber.setValue('');
      this.loading = true;
    }
    this.submitted = true;
    if (this.addClaimForm.invalid) {
      return;
    }

    this._memberService.updateMemberClaimByMember(this.addClaimForm.value).subscribe(res => {
      this.loading = false;
      this.submitted = false;
      this.getMemberById();
      this._alertify.success("Claim updated successfully");
      const button = document.getElementById('closeC')!;
      button.click();
    }, error => {
      if (error.error instanceof Array) {
        error.error.map((err) => this._alertify.error(err.value));
      } else if (typeof error.error === "string") {
        this._alertify.error(error.error);
      } else {
        this._alertify.error(error.error);
      }
      this.loading = false;
    });
  }
}
