import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MustMatch } from 'src/app/helpers/must-match.validators';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
    resetPasswordForm: FormGroup;
    loading = false;
    submitted = false;
    errorMessage: string[] = [];

    private _token: string;
    private _userId: string;
    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
    ) {        
        this.route.queryParams.subscribe(
            params => {
                this._token = params['token'];
                this._userId = params['id'];
            });
    }

    ngOnInit() {
        this.resetPasswordForm = this.formBuilder.group({
            newPassword: ['', [Validators.required, Validators.minLength(6)]],
            confirmPassword: ['', Validators.required],
            token: this._token,
            userId: this._userId
        }, {
            validator: MustMatch('newPassword', 'confirmPassword')
        });
    }

    // convenience getter for easy access to form fields
    get f() { return this.resetPasswordForm.controls; }

    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.resetPasswordForm.invalid) {
            return;
        }

        this.errorMessage = [];
        this.loading = true;
        this.authenticationService.resetPassword(this.resetPasswordForm.value)
            .pipe(first())
            .subscribe(
                () => {
                    this.router.navigate(['/']);
                },
                error => {
                    const _this = this;
                    if (error.error instanceof Array) {
                        error.error.map(err => _this.errorMessage.push(err.value));
                    } else if (typeof error.error === 'string') {
                        this.errorMessage.push(error.error);
                    } else {
                        this.errorMessage.push(error);
                    }
                    this.loading = false;
                });
    }
}
