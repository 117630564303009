import { Component, OnInit, Input } from "@angular/core";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { first } from "rxjs/operators";
import { MemberService } from "src/app/services/member.service";
import { PlanService } from "src/app/services/plan.service";
import { Plan } from "src/app/models/plan";
import { ActivatedRoute } from "@angular/router";
import { FormatDate } from "../../../../../helpers/format";
@Component({
  selector: "add-edit-family-members",
  templateUrl: "./add-edit-family-members.component.html",
})
export class AddEditFamilyMembersComponent implements OnInit {
  @Input() id: string;
  memberForm: FormGroup;
  loading = false;
  submitted = false;
  errorMessage = "";
  successMessage = "";
  addUpdateError: string[] = [];
  plans: Plan[];
  memberId;
  constructor(
    private _formBuilder: FormBuilder,
    private _memberService: MemberService,
    private _date: FormatDate,
    private _planService: PlanService,
    private _ActivatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this._ActivatedRoute.paramMap.subscribe((params) => {
      this.memberId = params.get("memberId");
    });

    if (!!this.id) {
      this._memberService.getFamilyMemberInfo(this.id).subscribe((member) => {
        this.memberForm.patchValue(member);
        this.memberForm.controls["dateOfBirth"].patchValue(
          this._date.formatDate(member.dateOfBirth)
        );
      });
    }

    this.memberForm = this._formBuilder.group({
      memberId: [""],
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      relation: ["", Validators.required],
      dateOfBirth: ["", Validators.required],
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.memberForm.controls;
  }

  get isEdit() {
    return !!this.id && this.id.trim().length > 0 ? true : null;
  }

  onSubmit() {
    this.submitted = true;

    // reset alerts on submit
    this.successMessage = "";
    this.addUpdateError = [];
    this.memberForm.value.memberId = this.memberId;
    // stop here if form is invalid
    if (this.memberForm.invalid) {
      return;
    }

    this.loading = true;
    if (!!this.id) {
      this._memberService
        .updateFamilyMember(this.id, this.memberForm.value)
        .pipe(first())
        .subscribe(
          () => {
            // return to list
            this.successMessage = "Member updated successfully.";
            this.loading = false;
          },
          (error) => {
            if (error.error instanceof Array) {
              error.error.map((err) => this.addUpdateError.push(err.value));
            } else if (typeof error.error === "string") {
              this.addUpdateError.push(error.error);
            } else {
              this.addUpdateError.push(error);
            }
            this.loading = false;
          }
        );
    } else {
      // this.memberForm.value.planId = parseInt(this.memberForm.value.planId);
      this._memberService
        .addFamilyMember(this.memberForm.value)
        .pipe(first())
        .subscribe(
          (member) => {
            // return to list
            this.successMessage = "Member added successfully.";
            this.loading = false;
            // this.id = member.externalId;
          },
          (error) => {
            if (error.error instanceof Array) {
              error.error.map((err) => this.addUpdateError.push(err.value));
            } else if (typeof error.error === "string") {
              this.addUpdateError.push(error.error);
            } else {
              this.addUpdateError.push(error);
            }
            this.loading = false;
          }
        );
    }
  }
}
