import { Injectable } from '@angular/core';

@Injectable({
  providedIn: "root",
})
export class SharedService {
  public data;
  constructor() {
    const storedData = localStorage.getItem("data");
    if (storedData) {
      this.data = JSON.parse(storedData);
    }
  }

  setData(data) {
    this.data = data;
    localStorage.setItem("data", JSON.stringify(data));
  }

  getData() {
    return this.data;
  }
}
