import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { first } from 'rxjs/operators';
import { AlertifyService } from 'src/app/services/alertify.service';
import { EnrollmentService } from 'src/app/services/enrollment.service';

@Component({
  selector: 'app-lead-validate',
  templateUrl: './lead-validate.component.html',
  styleUrls: ['./lead-validate.component.css']
})
export class LeadValidateComponent implements OnInit {

  leadId: any;
  leadForm: FormGroup;
  loading = false;
  submitted = false;
  constructor(
    private enrollmentService: EnrollmentService,
    private _alertify: AlertifyService,

    private _fb: FormBuilder, public dialogRef: MatDialogRef<LeadValidateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.leadId = data;
  }

  ngOnInit() {
    this.leadForm = this._fb.group({
      leadId: this.leadId,
      dob: ['', Validators.required],
    });
  }
  submitOnEnter(event) {
    if (event.keyCode === 13) {
      this.validateLead(); //calling submit function on enter
    }
  }
  get getLeadForm() {
    return this.leadForm.controls;
  }

  validateLead() {
    this.submitted = true;
    if (this.leadForm.invalid) return;
    this.loading = true;

    const datePipe = new DatePipe('en-Us');
    this.leadForm.controls.dob.setValue(new Date(datePipe.transform(this.leadForm.value.dob, 'yyyy-MM-dd')));

    //validate captcha
    this.enrollmentService
      .ValidateEnrollmentFromLeadId(this.leadForm.value)
      .pipe(first())
      .subscribe((res1: any) => {
        this.loading = false;
        if (res1) {
          this.dialogRef.close(true);
        } else {
          this._alertify.error("No lead found");
        }
      }, error => {
        this.loading = false;
        if (error.error != null)
          this._alertify.error(error.error);
        else
          this._alertify.error(error.status + ' ' + error.statusText);
      });
  }
  handleOnClose(event: any) {
    this.dialogRef.close(false);
  }
}
