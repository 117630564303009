import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AlertService } from 'src/app/services/alertService.service';
import { PeriodService } from 'src/app/services/period.service';
import { AlertifyService } from '../../../../services/alertify.service';

@Component({
  selector: 'app-add-period',
  templateUrl: './add-period.component.html',
  styleUrls: ['./add-period.component.scss']
})
export class AddPeriodComponent implements OnInit {

  periodForm: FormGroup;
  loading = false;
  submitted = false;
  errorMessage: string[] = [];

  get f() { return this.periodForm.controls; }

  constructor(private _formBuilder: FormBuilder, private _periodService: PeriodService,
    private _router: Router, private _alertify: AlertifyService) { }

  ngOnInit() {
    this.periodForm = this._formBuilder.group({
      id: 0,
      name: ['', Validators.required],
    });

  }

  onSubmit() {
    this.submitted = true;
   
    // reset alerts on submit
    this.errorMessage = [];
   
    // stop here if form is invalid
    if (this.periodForm.invalid) {
      return;
    }

    this.loading = true;
    this._periodService.addPeriod(this.periodForm.value)
      .pipe(first())
      .subscribe(
        () => {
          this.loading = false;         
          this._alertify.success('Period added successfully.');
          this._router.navigate(['/admin/periods']);
        },
        error => {
          if (error.error instanceof Array) {
            error.error.map(err => this.errorMessage.push(err.value));
          } else if (typeof error.error === 'string') {
            this.errorMessage.push(error.error);
          } else {
            this.errorMessage.push(error);
          }
          this.loading = false;
        });
  }

}
