import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ColDef } from 'ag-grid-community';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { InvoiceStatus } from 'src/app/models/enums/invoiceStatus';
import { InvoiceType } from 'src/app/models/enums/invoiceType';
import { InvoiceService } from 'src/app/services/invoice.service';
import { BtnCellRendererComponent } from 'src/app/shared/controls/renderers/button-renderer/btn.renderer';
import { FormatDate } from '../../../../helpers/format';
import { GroupService } from '../../../../services/group.service';


@Component({
  selector: 'app-invoice-list',
  templateUrl: './invoice-list.component.html',
  styleUrls: ['./invoice-list.component.css']
})
export class InvoiceListComponent implements OnInit {
  invoices: any[];
  groups: any[];
  columnDefs: ColDef[] = [];
  filteredGroups: Observable<string[]>;
  statusEnumValues = [
    { value: InvoiceStatus.All, text: 'Select Status' },
    { value: InvoiceStatus.New, text: 'New' },
    { value: InvoiceStatus.ReadyToSend, text: 'Ready To Send' },
    { value: InvoiceStatus.Sent, text: 'Sent' },
    { value: InvoiceStatus.Paid, text: 'Paid' },
    { value: InvoiceStatus.Cancelled, text: 'Cancelled' }
  ];
  typeEnumValues = [
    { value: InvoiceType.All, text: 'Select Type' },
    { value: InvoiceType.PremiumFee, text: 'Premium' },
    { value: InvoiceType.ThinkWellnessNowFee, text: 'Think Wellness Now Fee' },
  ];
  filterInvoiceList: FormGroup;
  defaultColDef = {
    flex: 1,
    minWidth: 150,
    resizable: true,
  };
  
  private gridApi;
  constructor(private _invoiceService: InvoiceService, private _groupService: GroupService, private _date: FormatDate, private _router: Router, private _formBuilder: FormBuilder) {
    
    const _this = this;
    const date = new Date();
    this.columnDefs = [
      { headerName: 'Invoice Number', field: 'number', suppressMovable: true, sortable: true, filter: true },
      { headerName: 'Type', field: 'type', suppressMovable: true, sortable: true, filter: true },
      { headerName: 'Group Name', field: 'groupName', suppressMovable: true, sortable: true, filter: true },
      {
        headerName: 'Start Date', field: 'startDate', suppressMovable: true, sortable: true, filter: true,
        valueFormatter: function (params) {
          return !!params.value ? _date.formatDate(params.value) : '';
        }
      },
      {
        headerName: 'End Date', field: 'endDate', suppressMovable: true, sortable: true, filter: true,
        valueFormatter: function (params) {
          return !!params.value ? _date.formatDate(params.value) : '';
        }
      },
      {
        headerName: 'Amount', field: 'amount', suppressMovable: true, sortable: true, filter: true,
        valueFormatter: function (params) {
          return !!params.value ? '$' + params.value : '';
        }
      },
      { headerName: 'Status', field: 'status', suppressMovable: true, sortable: true, filter: true },
      {
        headerName: '', field: 'id', suppressMovable: true, cellRendererFramework: BtnCellRendererComponent,
        cellRendererParams: {
          showInvoiceBtn: true,
          showInvoiceFormBtn: true
        }
      },
     
    ];
   
    this.filterInvoiceList = this._formBuilder.group({
      endDate: [null],
      startDate: [null],
      type: InvoiceType.All,
      status: InvoiceStatus.All,
      group: ['']
    });
  }
  
  ngOnInit() {
    this.getAllInvoices();
    this.getAllGroups();   
  }

  getAllGroups() {
    this._groupService.getAllGroups().subscribe(res => {
      this.groups = res.map(obj => obj.name);
      this.filteredGroups = this.filterInvoiceList.controls["group"].valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value))
      );
    })
  }
  private _filter(value: string): string[] {
    
    const filterValue = this._normalizeValue(value);
    return this.groups.filter(g => this._normalizeValue(g).includes(filterValue));
  }

  private _normalizeValue(value: string): string {
    return value.toLowerCase().replace(/\s/g, '');
  }
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridApi.setDomLayout("autoHeight");
  }
  getAllInvoices() {
    
    this._invoiceService.getAllInvoices(this.filterInvoiceList.value).subscribe(res => {
 
      this.invoices = res;
    })
  }
  onFilterTextBoxChanged() {
    this.gridApi.setQuickFilter(
      (document.getElementById('filter-text-box') as HTMLInputElement).value
    );
  }

  onSubmit() {
    
    this.filterInvoiceList.controls["type"].setValue(Number(this.filterInvoiceList.controls["type"].value));
    this.filterInvoiceList.controls["status"].setValue(Number(this.filterInvoiceList.controls["status"].value));    
    this._invoiceService.getAllInvoices(this.filterInvoiceList.value).subscribe(res => {
      this.invoices = res;
    })
  }
}
