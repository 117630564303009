import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { first } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { WellnessProgressService } from 'src/app/services/wellnessProgressService.service';
import { WellnessProgressReviewStatus } from 'src/app/models/enums/wellnessProgressReviewStatus';
import { IndemnityBenefitService } from 'src/app/services/indemnityBenefit.service';
import { IndemnityBenefit } from 'src/app/models/indemnityBenefit';
import { saveAs as importedSaveAs } from "file-saver";
import { ColDef } from 'ag-grid-community';
import { FormatDate } from '../../../../helpers/format';

@Component({
    selector: 'app-update-medicalreceipt',
    templateUrl: './update-medicalreceipt.component.html'
})
export class UpdateMedicalReceiptComponent implements OnInit {
    @Input()
    medicalReceiptId: number;
    medicalReceiptForm: FormGroup;
    indemnityBenefits: IndemnityBenefit[];
    wellnessProgressReviewStatusKeys: any;
    friendlyFileName: string;
    loading = false;
    submitted = false;
    isReciptPending = true;
    errorMessage: string[] = [];
    successMessage = '';
    columnDefs: ColDef[] = [];
    defaultColDef = {
      flex: 1,
      minWidth: 150,
      resizable: true,
    };


    private gridApi;
    private WellnessProgressReviewStatus = WellnessProgressReviewStatus;

    constructor(private _route: ActivatedRoute,
      private _router: Router,private _date: FormatDate,
      private _formBuilder: FormBuilder,
      private _wellnessProgressService: WellnessProgressService,
      private _indemnityBenefitService: IndemnityBenefitService)
    {
      this._route.params.subscribe(params => { this.medicalReceiptId = params['medicalReceiptId']; });

      this.columnDefs = [
        { hide: true, field: 'id' },
        { headerName: 'Name', field: 'name', checkboxSelection: true, suppressMovable:true, sortable: true, filter: true, autoHeight: true },
        //{
        //  headerName: 'Name', field: 'name', sortable: true, filter: true
        //},
        { headerName: 'Amount', field: 'amount', suppressMovable: true, sortable: true, filter: true, autoHeight: true, valueFormatter: params => "$" + params.data.amount },
      ];
    }

  ngOnInit() {
    this.wellnessProgressReviewStatusKeys = Object.keys(WellnessProgressReviewStatus).filter(Number);
   // this.getAllIndemnityBenefits();
    this.medicalReceiptForm = this._formBuilder.group({
      type:[1],
      serviceDate: ['', [Validators.required]],
      providerName: ['', [Validators.required]],
      reviewStatus:[],
      comments: [''],
      indemnityBenefitId: [0],
      patientName: ['', [Validators.required]],
      patientBirthDate: ['', [Validators.required]]
    });

     if (!!this.medicalReceiptId) {
         this._wellnessProgressService.getWellnessProgress(this.medicalReceiptId).subscribe(medicalReceipt => {
           this.medicalReceiptForm.patchValue(medicalReceipt);
           this.medicalReceiptForm.controls['serviceDate'].patchValue(this._date.formatDate(medicalReceipt.serviceDate));
           this.medicalReceiptForm.controls['reviewStatus'].patchValue(WellnessProgressReviewStatus[medicalReceipt.reviewStatus]);
           this.friendlyFileName = medicalReceipt.friendlyFileName;
           if (medicalReceipt.reviewStatus) {
             this.isReciptPending = false;
           }

           if (medicalReceipt.patientBirthDate) {
             this.medicalReceiptForm.controls['patientBirthDate'].patchValue(this._date.formatDate(medicalReceipt.patientBirthDate));
           }
         });
    }
  }

  getAllIndemnityBenefits() {
    this._indemnityBenefitService.getAllIndemnityBenefits().subscribe(indemnityBenefits => {
      this.indemnityBenefits = indemnityBenefits;
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.medicalReceiptForm.controls; }

  
  onSubmit() {
    this.submitted = true;

    // reset alerts on submit
    this.successMessage = '';
    this.errorMessage = [];

    // stop here if form is invalid
    if (this.medicalReceiptForm.invalid) {
      return;
    }

    this.loading = true;
    const medicalReceiptId = this.medicalReceiptId;
    // delete this.groupForm.value['broker'];
    if (!!medicalReceiptId) {
      this._wellnessProgressService.updateMedicalReceipt(medicalReceiptId, this.medicalReceiptForm.value)
        .pipe(first())
        .subscribe(
          () => {
            if (this.medicalReceiptForm.value.reviewStatus) {
              this.isReciptPending = false;
            }
            // return to list
            this.successMessage = 'Medical Receipt updated successfully.';
            this.loading = false;
            this.errorMessage = [];
          },
          error => {
            if (error.error instanceof Array) {
              error.error.map(err => this.errorMessage.push(err.value));
            } else if (typeof error.error === 'string') {
              this.errorMessage.push(error.error);
            } else {
              this.errorMessage.push(error);
            }
            this.loading = false;
          });
    } 
  }

   showAllMedicalReceipts() {
      this._router.navigate(['/admin/medicalreceipts']);
  }
  
  downloadReceipt() {
    this._wellnessProgressService.downloadMedicalReceipt(this.medicalReceiptId).subscribe(blob => {
      let file = this.makeid(5);
      if (this.friendlyFileName != null && this.friendlyFileName.length > 0) {
        file = this.friendlyFileName;
      }

      importedSaveAs(blob, file);
    });
  }

  makeid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() *
        charactersLength));
    }
    return result;
  }

  goToViewRecipt() {
    this._router.navigate(['admin/medicalreceipts']);
  }


  onGridReady(params) {
    this.gridApi = params.api;
    var index = 0;
    this.getAllIndemnityBenefits();
    setTimeout(() => {
        // this.gridApi.setDomLayout("autoHeight");
        params.api.forEachNode(node => {
          if (node.data.id == this.medicalReceiptForm.value.indemnityBenefitId) {
            node.setSelected(true, true);
            index = node.id;
          }
        });
      this.gridApi.ensureIndexVisible(Number(index));
    }, 2000);
  }

  onRowSelectedEvent(params) {
    if (params.node.isSelected()) {
      this.medicalReceiptForm.controls['indemnityBenefitId'].patchValue(params.data.id);
    }

  }
}
