import { Component } from '@angular/core';
import { GroupService } from 'src/app/services/group.service';
import { ColDef, ValueGetterParams } from 'ag-grid-community';
import { ActivatedRoute, Router } from '@angular/router';
import { Member } from '../../../../models/member';
import { MemberService } from '../../../../services/member.service';
import { MemberStatus } from '../../../../models/enums/memberStatus';
import { MemberNameRendererComponent } from './member-name-renderer/member-name-renderer';

@Component({
  selector: 'app-members',
  templateUrl: './member-list.component.html'
})
export class MemberListComponent {

  members: Member[];
  groupExternalId: string;  
  columnDefs: ColDef[] = [];
  defaultColDef = {
    flex: 1,
    resizable: true,
  };
  private gridApi;
    
  enumValues = [
    { value: MemberStatus.All, text: 'Select Status' },
    { value: MemberStatus.Pending, text: 'Pending' },
    { value: MemberStatus.Active, text: 'Active' },
    { value: MemberStatus.Terminated, text: 'Terminated' }
  ];

  selectedEnumValue: MemberStatus = MemberStatus.All; // Set the default selected value
  constructor(private _memberService: MemberService, private _route: ActivatedRoute,
    private _router: Router, private groupService: GroupService) {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const currentDate = new Date();
    const _this = this;
   
   
    this.columnDefs = [
      { hide: true, field: 'referenceId' },
      { hide: true, field: 'lastName' },
      {
        headerName: 'Id', field: 'externalId',  suppressMovable: true, maxWidth: 120, sortable: true, filter: true
      },
      {
        headerName: 'Name', field: 'firstName',  suppressMovable: true, maxWidth: 150, sortable: true, filter: true,         
        valueFormatter: (params) => {
          if (params.data && params.data.firstName && params.data.lastName) {
            return params.data.firstName + " " + params.data.lastName;
          }
          return ""; // or handle appropriately for your case
        },
      },
      { headerName: 'Group Name', field: 'groupName', sortable: true, maxWidth: 300, filter: true },
      { headerName: 'Plan', field: 'planName',  sortable: true, maxWidth: 100, filter: true },
      {
        headerName: 'Ques. Status', field: "isHraCompleted",
        suppressMovable: true, maxWidth: 150,
        cellRendererFramework: MemberNameRendererComponent,
        cellRendererParams: {
          btnText: "View",
          NoShowText: "Add",
          isNoShowTextClickable: true,
          clicked: function (params: any) {
           
            if (!!params.data.isHraCompleted) {            
                _this._router.navigate([
                  `admin/groups/${params.data.groupReferenceId}/members/${params.data.externalId}/view-hra`,
                ]);
            } else {
              _this._router.navigate([
                `/hra-form/${params.data.groupExternalId}/${params.data.externalId}`,
              ]);
            }
          },
          show: function (params: any) {
            return params.value;
          }
        }
      },
      {
        headerName: `${monthNames[currentDate.getMonth()]} Claim Status`,
        field: "isClaimCompletedForCurrentMonth",
        suppressMovable: true, maxWidth: 200,
        cellRendererFramework: MemberNameRendererComponent,
        cellRendererParams: {
          btnText: "Add Claim",
          NoShowText: "Completed",
          clicked: function (params: any) {
            _this._router.navigate([
              `admin/groups/${params.data.groupReferenceId}/members/${params.data.externalId}/add-claim`,
            ]);
          },
          show: function (params: any) {
            return !params.value;
          },
        },
      },

      { headerName: 'Member Status', field: 'memberStatus', maxWidth: 150, sortable: true, filter: true },
      {
        headerName: '',
        field: "", width: 200,
        suppressMovable: true,
        cellRendererFramework: MemberNameRendererComponent,
        cellRendererParams: {
          showViewBtn: true,
          showEnrollViewBtn: true,
          clicked: function (params: any) {
            _this._router.navigate([`/admin/members/${params.data.externalId}`]);
          }
         
          // clicked: function (params: any) {
          //   _this._router.navigate([]).then(result => {  window.open(`/admin/enroll/member/${params.data.externalId}`, '_blank'); });
          // },
          // show: function (params: any) {
          //   return !params.value;
          // },
        },
      },

    ];
    this.loadData();
  }
  
  

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridApi.setDomLayout("autoHeight");    
  }

  abValueGetter(params: ValueGetterParams) {
    return params.data.firstName + " " + params.data.lastName;
  }

  onChange(value: any) {
    
    this.selectedEnumValue = value.target.value;

    this.loadData();
  }

  loadData() {
    this._memberService.getAllSearchMembers(this.selectedEnumValue)
      .subscribe((members:any) => {
          this.members = members;
        });    
  }  

  onFilterTextBoxChanged() {
    this.gridApi.setQuickFilter(
      (document.getElementById('filter-text-box') as HTMLInputElement).value
    );
  }
  

}
