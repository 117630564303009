import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getApiUrl } from '../helpers/get-url';
import { Categories } from '../models/categories';

@Injectable({ providedIn: 'root' })
export class CategoriesService {
    constructor(private http: HttpClient) { }

    getAllCategories() {
        return this.http.get<Categories[]>(getApiUrl('categories'));
    }
}
