import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ColDef } from 'ag-grid-community';
import { Invoice } from '../../../../../models/invoice';
import { AlertifyService } from '../../../../../services/alertify.service';
import { GroupNameRendererComponent } from '../../../groups/group-list/group-name-renderer/group-name-renderer';
import { ClaimLineItem, ClaimPayment } from '../../../../../models/claimPayment';
import { Claim } from 'src/app/models/claim';
import { DatePipe,Location } from '@angular/common';
import { ClaimPaymentService } from '../../../../../services/claimPayment.service';

@Component({
  selector: 'app-claim-LineItem',
  templateUrl: './claim-view.component.html',
  styleUrls: ['./claim-view.component.scss']
})

export class ClaimLineItemViewComponent implements OnInit {
  @ViewChild('myModalClose', {static: false}) modalClose
  columnDefs: ColDef[] = [];
  defaultColDef = {
    flex: 1,
    minWidth: 150,
    resizable: true,
  };
  ShowNachaButton: boolean;
  ShowRequestedButton: boolean;

  private gridApi;
  nachaForm: FormGroup;
  claimForm: FormGroup;
  claimDetails: Claim = new Claim();
  claimLineitem: ClaimLineItem;
  claimlineItems: any[];
  invoice: Invoice = new Invoice();
  invoiceitem: any[];
  claimLineItemForm: FormGroup;
  claimpayment: ClaimPayment = new ClaimPayment();
  groupReferenceId: string;
  loading: boolean;
  groupId: number;
  submitted: boolean;
  claimPaymentId: number;
  claimLineItemId: number;
  errorMessage: string[] = [];
  
  constructor(private _claimService: ClaimPaymentService, private _datepipe: DatePipe, private location: Location,
    private _fb: FormBuilder, private _alertify: AlertifyService,
    private _route: ActivatedRoute, private _router: Router) {
    this._route.params.subscribe(params => { this.claimPaymentId = params['id']; });
    const _this = this;

    this.columnDefs = [
      { headerName: 'Serial No', valueGetter: "node.rowIndex + 1", suppressMovable: true, sortable: true, filter: true },
      {
        headerName: 'Member Id', field: 'memberId', suppressMovable: true, sortable: true, filter: true, cellRendererFramework: GroupNameRendererComponent,
        cellRendererParams: {
          clicked: function (params: any) {            
            _this._router.navigate([
              `/admin/members/${params.data.memberId}`,
            ]);
          },
        },
      },
      { headerName: 'Member Name', field: 'memberName', suppressMovable: true, sortable: true, filter: true },
      { headerName: 'Plan Name', field: 'planName', suppressMovable: true, sortable: true, filter: true },
      {
        headerName: 'Payment Amount', field: 'paymentAmount', suppressMovable: true, sortable: true, filter: true,
        valueFormatter: function (params) {
          return !!params.value ? '$' + params.value : '';
        }
      },
      { headerName: 'Notes', field: 'notes', suppressMovable: true, sortable: true, filter: true },
      { headerName: 'Status', field: 'status', suppressMovable: true, sortable: true, filter: true },
      {
        headerName: "",
        field: "",
        suppressMovable: true,
        cellRenderer: (params) => {
          if (params.data.status == 'New') {
            return `<button  class="EnrollBtn popup mr-2" data-toggle="modal"
        data-target="#lineItemDetails">Edit </button>
     <button *ngIf='params.showCancelLineItemBtn' class="EnrollBtn popup mr-2" data-toggle="modal"
        data-target="#cancelModel">Cancel </button>`
          }
        }
      },
    ];
  }
  
  ngOnInit() {

    this.getClaimLineItemDetail(this.claimPaymentId);
    this.getClaimLineItemForm();
    this.getNachaForm();
   
  }
  getClaimLineItemForm() {
    this.claimLineItemForm = this._fb.group({
      id: [""],
      paymentAmount: ["", [Validators.required]],
      notes: ["", [Validators.required]],
    });
  }
  getNachaForm() {
    this.nachaForm = this._fb.group({
      id: [""],
      paymentDate: ["", [Validators.required]]
    });
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridApi.setDomLayout("autoHeight");
  }
  getAllClaimLineItem() {

    this._claimService.getClaimLineItem(this.claimPaymentId).subscribe(res => {      
      const isNewStatus: boolean = res.some(obj => obj.status === 'New');
      const isRequestedStatus: boolean = res.some(obj => obj.status === 'Requested');

      if (isNewStatus) {
        this.ShowNachaButton = true;
        this.ShowRequestedButton = false;

      } else if(isRequestedStatus) {
        this.ShowNachaButton = false;
        this.ShowRequestedButton = true;
      }
      else{
        this.ShowNachaButton = false;
        this.ShowRequestedButton = false;
      }
      this.claimlineItems = res;
    })
  }


  

  getClaimLineItemDetail(claimPaymentId: number) {
    this._claimService.getClaimPaymentById(claimPaymentId).subscribe(data => {
      this.claimpayment = data;
      this.getAllClaimLineItem();
      this.groupReferenceId = data.groupReferenceId;
      this.nachaForm.controls["paymentDate"].patchValue(
        this._datepipe.transform(data.paymentDate, "yyyy-MM-dd")
      );
    });
  }
  
 
  onCellClicked($event) {
    
    this.claimLineItemId = $event.data.id;
    this.claimLineitem = $event.data;

    this._claimService.getClaimLineItemById(this.claimLineItemId).subscribe(data => {

      this.claimLineItemForm.patchValue(data);
     
    });
  }
  onClaimAmountSubmit() {
    this.submitted = true;

    if (this.claimLineItemForm.invalid) {
      return;
    }
    this.loading = true;
    this.claimLineItemForm.value.paymentAmount = parseFloat(this.claimLineItemForm.value.paymentAmount);
    this._claimService.updateClaimLineItem(this.claimLineItemId, this.claimLineItemForm.value).subscribe(
      (response) => {
        this.loading = false;
        this.submitted = false;
        const button = document.getElementById('closeLineItem')!;
        button.click();
        this.getAllClaimLineItem();
        this.getClaimLineItemDetail(this.claimPaymentId);
        this._alertify.success('Claim line item updated successfully.');
      },
      error => {
        if (error.error instanceof Array) {
          error.error.map(err => this._alertify.error(err.value)); //this.addUpdateError.push
        } else if (typeof error.error === 'string') {
          //this.addUpdateError.push(error.error);
          this._alertify.error(error.error)
        } else {
          //this.addUpdateError.push(error);
          this._alertify.error(error);
        }
        this.loading = false;
      });


  }
  onNachaSubmit() {
    this.submitted = true;
    if (this.nachaForm.invalid) {
      return;
    }
    this.loading=true;
      this.modalClose.nativeElement.click();
      this.claimlineItems.forEach(x => x.status = 'Requested');
      this.gridApi.updateRowData('rowData', this.claimlineItems);
      this.gridApi.redrawRows();

      this.ShowNachaButton=false;
      this.ShowRequestedButton=true;
    this._claimService.sendToNacha(Number(this.claimPaymentId), this.nachaForm.value.paymentDate)
    .subscribe(
      (response) => {
        this._alertify.success('File successfully sent to FTP server');
        this.getClaimLineItemDetail(this.claimPaymentId);
      },
      error => {
        
        if (error.error instanceof Array) {
          error.error.map(err => this._alertify.error(err.value));
        } else if (typeof error.error === 'string') {
          this._alertify.error(error.error)
        } else {
          this._alertify.error(error);
        }
        this.getClaimLineItemDetail(this.claimPaymentId);
        this.loading = false;
      });
  }

  onCancel() {
    
    this.submitted = true;
    this.loading = true;
    this._claimService.CancelLineItemById(this.claimLineItemId)
      .subscribe(
        (response) => {
          this.getClaimLineItemDetail(this.claimPaymentId);
          this.loading = false;
          this.submitted = false;
          const button = document.getElementById('closeP')!;
          button.click();
          this._alertify.success('Line item cancelled successfully');
        },
        (error) => {
          if (error.error instanceof Array) {
            this._alertify.error(error.error);
            error.error.map((err) => this._alertify.error(err.value));
          } else if (typeof error.error === "string") {
            this._alertify.error(error.error);
          } else {
            this._alertify.error(error.error);
          }
          this.loading = false;
        }
      );
  }

  OnSubmit() {
    this.submitted = true;
    this.loading = true;
    this._claimService.updateClaimLineItem(this.claimPaymentId, this.claimLineitem)
      .subscribe(
        (response) => {
          this.getClaimLineItemDetail(this.claimPaymentId);
          this.loading = false;
          this.submitted = false;
          this._alertify.success('Claim line item updated successfully.');
          const button = document.getElementById('closeP')!;
          button.click();

        },
        (error) => {
          if (error.error instanceof Array) {
            this._alertify.error(error.error);
            error.error.map((err) => this._alertify.error(err.value));
          } else if (typeof error.error === "string") {
            this._alertify.error(error.error);
          } else {
            this._alertify.error(error.error);
          }
          this.loading = false;
        }
      );
  }
  back() {
    this._router.navigate([`/admin/group-view/${this.groupReferenceId}`]);
  }
  get f() {
    return this.claimLineItemForm.controls;
  }
  get n() {
    return this.nachaForm.controls;
  }
 
}

