import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { ColDef } from 'ag-grid-community';
import { GroupService } from 'src/app/services/group.service';
import { UploadLeadComponent } from '../upload-lead/upload-lead.component';
import { BtnCellRendererComponent } from 'src/app/shared/controls/renderers/button-renderer/btn.renderer';
import { AlertifyService } from 'src/app/services/alertify.service';
import moment from 'moment';

@Component({
  selector: 'app-lead-list',
  templateUrl: './lead-list.component.html',
  styleUrls: ['./lead-list.component.css']
})
export class LeadListComponent {

  selectedGroupId: string;
  groupName: string;
  leads: any[] = [];
  columnDefs: ColDef[] = [];
  defaultColDef = {
    flex: 1,
    minWidth: 150,
    resizable: true,
  };

  private gridApi;

  constructor(private _groupService: GroupService, private _route: ActivatedRoute, private alertify :AlertifyService,
    private dialog: MatDialog, private _router: Router) {
    this._route.params.subscribe(params => {
      
      this.selectedGroupId = params['groupId'];
    });
    //const _this = this;
   

    this.columnDefs = [
      { hide: true, field: 'leadId' },
      { headerName: 'First Name', field: 'firstName', suppressMovable: true, sortable: true, filter: true },
      { headerName: 'Last Name', field: 'lastName', suppressMovable: true, sortable: true, filter: true },
      { headerName: 'Email', field: 'email', suppressMovable: true, sortable: true, filter: true },
      { headerName: 'Mobile', field: 'mobile', suppressMovable: true, sortable: true, filter: true },
      {
        headerName: 'Date of Birth', field: 'dob', sortable: true, filter: true, cellRenderer: (data) => {
          return moment(data.value).format('MM/DD/YYYY')
        }
      },
      { headerName: 'Plan', field: 'planName', suppressMovable: true, sortable: true, filter: true },
      {
          headerName: '',
          field: 'referenceId',
          suppressMovable: true,
          cellRendererFramework: BtnCellRendererComponent,
          cellRendererParams: {
            showLeadEnrollmentBtn: true,              
          },
      }
    ];
    this.getAllLeads();
    this.getGroupName();
  }
  
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridApi.setDomLayout("autoHeight");
  }

  getAllLeads() {    
    this._groupService.getGroupLeads(Number(this.selectedGroupId)).subscribe(leads => {
      this.leads = leads;      
    }, error =>{
      console.log('lead list error :'+error.error);
      
    });
  }

  getGroupName() {
    
    this._groupService.getGroupName(this.selectedGroupId).subscribe(leads => {
      this.groupName = leads.value;
    }, error => {
      console.log('lead list error :' + error.error);

    });
  }
  openUploadLead(event: any) {
    event.preventDefault();
    const dailogRef = this.dialog.open(UploadLeadComponent, {
      width: '500px',
      height: 'auto',
      data: this.selectedGroupId,
      disableClose: true,
      hasBackdrop: false
    });
    dailogRef.afterClosed().subscribe(result => {
      if (result) {    
        this.getAllLeads();          
      }
    });
  }
}
