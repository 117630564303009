export class Group {
  referenceId: string;
  name: string;
  email: string;
  phoneNumber: string;
  companyId: string;
  contactPerson: string;
  externalId: string;
  webSiteUrl: string | null;
  createdDate: Date;
  modifiedDate: Date;
  payPeriod: number;
  id: number | null;
  addressLine1: string | null;
  addressLine2: string | null;
  city: string | null;
  state: string | null;
  zipCode: string | null;
  status: number | null;
  startDate: Date;
  periodId: number | null;
  isActive: boolean | null;
}
