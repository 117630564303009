
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { User } from 'src/app/models/User';

export interface UserState extends EntityState<User, string> {
    isUserDetailLoaded: boolean;
}

export function createInitialState(): UserState {
    return {
        isUserDetailLoaded: false
    };
}

@Injectable({
    providedIn: 'root'
})
@StoreConfig({ name: 'user', resettable: true })
export class UserStore extends EntityStore<UserState> {
    constructor() {
        super(createInitialState());
    }

    loadUserDetail(user: User, isUserDetailLoaded: boolean) {
        this.set([user]);
        this.update(state => ({
            ...state,
            isUserDetailLoaded
        }));
    }
}
