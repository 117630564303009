import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { first } from 'rxjs/operators';
import { PlanService } from 'src/app/services/plan.service';
import { ValidatorsService } from '../../../../helpers/validators.service';

@Component({
  selector: 'app-add-plan',
  templateUrl: './add-edit.component.html'
})
export class AddEditPlanComponent {

  @Input()
  planId: number;

  planForm: FormGroup;
  loading = false;
  submitted = false;
  successMessage = '';
  errorMessage: string[] = [];

  constructor(
    private formBuilder: FormBuilder, private validatorsService: ValidatorsService,
    private planService: PlanService) { }

  ngOnInit() {
    // referenceId: string;
    this.planForm = this.formBuilder.group({
      name: ['', Validators.required],
      monthlyPremiumAmount: [0, [Validators.required, this.validatorsService.nonNegativeValidator()]],
      monthlyClaimAmount: [0, [Validators.required, this.validatorsService.nonNegativeValidator()]],
      description: [''],
      isActive: [false],
      isEligibleMedicalReceipts: [false]
    });

    if (!!this.planId) {
      this.planService.getPlanById(this.planId).subscribe(video => {
        this.planForm.patchValue(video);
      });
    }
  }

  // convenience getter for easy access to form fields
  get f() { return this.planForm.controls; }
  
  onSubmit() {
    this.submitted = true;

    // reset alerts on submit
    this.errorMessage = [];
    this.successMessage = '';

    // stop here if form is invalid
    if (this.planForm.invalid) {
      return;
    }

    this.loading = true;
    const planId = this.planId;
    if (!!planId) {
      this.planService.updatePlan(planId, this.planForm.value)
        .pipe(first())
        .subscribe(
          () => {
            // return to list
            this.successMessage = 'Plan updated successfully.';
            this.loading = false;
          },
          error => {
            if (error.error instanceof Array) {
              error.error.map(err => this.errorMessage.push(err.value));
            } else if (typeof error.error === 'string') {
              this.errorMessage.push(error.error);
            } else {
              this.errorMessage.push(error);
            }
            this.loading = false;
          });
    } else {
      this.planService.addPlan(this.planForm.value)
        .pipe(first())
        .subscribe(
          (plan) => {
            // return to list
            this.successMessage = 'Plan added successfully.';
            this.loading = false;
            this.planId = plan.id;
          },
          error => {
            if (error.error instanceof Array) {
              error.error.map(err => this.errorMessage.push(err.value));
            } else if (typeof error.error === 'string') {
              this.errorMessage.push(error.error);
            } else {
              this.errorMessage.push(error);
            }
            this.loading = false;
          });
    }
  }
}


