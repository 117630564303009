import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from '../models/User';
import { getApiUrl } from '../helpers/get-url';
import { tap } from 'rxjs/internal/operators/tap';
import { UserStore } from '../controls/StateManagement/User/user.store';

@Injectable({ providedIn: 'root' })
export class UserService {
  constructor(private http: HttpClient, private store: UserStore) { }

  getUserDetails(): Observable<User> {
    return this.http.get<User>(getApiUrl('user')).pipe(
      tap(user => {
        this.store.loadUserDetail(user, true);
      })
    );
  }

  changePassword(value: any) {
    return this.http.post(getApiUrl('user/change-password'), value);
  }

  forgotPassword(value: any) {
    return this.http.post(getApiUrl('user/forgot-password'), value);
  }
}
