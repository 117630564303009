import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { first } from 'rxjs/internal/operators/first';
import { ClaimService } from 'src/app/services/claim.service';
import { ValidatorsService } from '../../../../../helpers/validators.service';
import { AlertifyService } from '../../../../../services/alertify.service';

@Component({
  selector: 'app-add-claim',
  templateUrl: './add-claim.component.html',
  styleUrls: ['./add-claim.component.scss']
})
export class AddClaimComponent {
  claimForm: FormGroup;
  loading = false;
  submitted = false;
  errorMessage: string[] = [];
  minDate;
  maxDate;
  get f() { return this.claimForm.controls; }

  private _groupId;
  private _externalMemberId;

  constructor(private _route: ActivatedRoute, private _formBuilder: FormBuilder,
    private _claimService: ClaimService, private _router: Router,
    private validatorsService: ValidatorsService ,private _alertify: AlertifyService) {
    this._route.params.subscribe(params => {
      this._groupId = params['groupId'];
      this._externalMemberId = params['memberId'];
    });
    const currentTime = new Date();
    this.minDate = moment(new Date(currentTime.getFullYear(), currentTime.getMonth(), 1)).format('YYYY-MM-DD');
    this.maxDate = moment(currentTime).format('YYYY-MM-DD');

    this.claimForm = this._formBuilder.group({
      claimType: [1],
      description: ['', Validators.required],
      durationInMinutes: ['', [Validators.required, this.validatorsService.nonNegativeValidator()]],
      code: ['', Validators.required],
      claimDate: [this.maxDate, Validators.required]
    });
  }
  
  onSubmit() {
    this.submitted = true;

    // reset alerts on submit
    this.errorMessage = [];

    // stop here if form is invalid
    if (this.claimForm.invalid) {
      return;
    }

    this.loading = true;
    this._claimService.addCustomClaim(this._externalMemberId, this.claimForm.value)
      .pipe(first())
      .subscribe(
        () => {
          this.loading = false;
          this._alertify.success('Claim added successfully.');
          this._router.navigate([`admin/group-view/${this._groupId}`]);
        },
        error => {
          if (error.error instanceof Array) {
            error.error.map(err => this.errorMessage.push(err.value));
          } else if (typeof error.error === 'string') {
            this.errorMessage.push(error.error);
          } else {
            this.errorMessage.push(error);
          }
          this.loading = false;
        });
  }
}
