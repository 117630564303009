import { Component } from '@angular/core';
@Component({
    // tslint:disable-next-line: component-selector
    selector: 'hra-copyright',
    templateUrl: './copyright.ctrl.html',
    styleUrls: ['./copyright.ctrl.scss']
})
// tslint:disable-next-line: component-class-suffix
export class CopyrightControl {
    /**
     * TODO: This is going to be changed to a string that gets passed from the build machine.
     */

    constructor() { }

    getCopyrightYear() {
        return new Date().getFullYear();
    }
}
