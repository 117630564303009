import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { AlertifyService } from 'src/app/services/alertify.service';
import { environment } from '../../../../../environments/environment';
import { GroupService } from 'src/app/services/group.service';

@Component({
  selector: 'app-upload-lead',
  templateUrl: './upload-lead.component.html',
  styleUrls: ['./upload-lead.component.css']
})
export class UploadLeadComponent implements OnInit {
  leadFile: any;
  loading: boolean = false;
  downloadLink: string = environment.downloadLink;
  selectedGroupId: any;
  constructor(
    private alertify: AlertifyService, 
    private _groupService : GroupService,
    public dialogRef: MatDialogRef<UploadLeadComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.selectedGroupId = data;
  }

  ngOnInit() {
  }
  onFileUpload(event: any) {
    if ((event.target as HTMLInputElement).files.length < 1) {
      return;
    }
    var allowedExtention = ['csv'];
    const file = (event.target as HTMLInputElement).files[0];
    let splitedValue = file.name.toLowerCase().split('.');
    if ((splitedValue.length < 2 || allowedExtention.indexOf(splitedValue[splitedValue.length - 1]) == -1)) {
      return;
    }
    else {
      this.leadFile = file;
    }
  }

  handleOnSave(event: any) {
    if (this.leadFile) {
      this.loading = true;
      this._groupService.uploadLeadByAdmin(this.selectedGroupId, this.leadFile).subscribe(result => {
        this.alertify.success("File uploaded successfully");
        this.loading = false;        
        this.dialogRef.close(true);
      },error => {
        this.alertify.error(error.error);
      });
    }
    else
      this.alertify.error("Please upload a file and submit")
  }


  handleOnClose(event: any) {
    this.dialogRef.close(false);
  }

}
