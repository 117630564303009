export class VideoWithUserDetails {
    referenceId: string;
    url: string;
    title: string;
    description: string;
    thumbnail: string;
    code: string;
    videoCategories: string;
    durationInSeconds: string;
    completedOn: Date;
    status: number;
    createdDate: Date;
    modifiedDate: Date;
}
