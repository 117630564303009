export class Member {
  firstName: string;
  lastName: string;
  email: string;
  dob: Date;
  externalId: string;
  wellnessBenefitAmount: number;
  startDate: Date;
  terminationDate: Date;
  isLyrics: boolean = true;
  monthlyAmount: number;
  yearlyAmount: number;
  premiumAmount: number;
  planId: number;
  isEligibleMedicalReceipts: boolean;
  planStartDate: Date;
  payPeriod: number = 0;
  salaryPerPayPeriod: number;
  //New added fields
  id: number;
  middleName: string | null;
  addressLine1: string | null;
  addressLine2: string | null;
  city: string | null;
  state: string | null;
  zipCode: string | null;
  homeNumber: string | null;
  mobile: string | null;
  secondaryMobile: string | null;
  workNumber: string | null;
  emailAddress: string | null;
  gender: string | null;
  maritalStatus: string | null;
  signaturePath: string | null;
  signatureName: string | null;
  signatureDateTime: string | null;
  ssn: string | null;
  healthInsuranceCompanyName: string | null;
  insurancePolicyNo: string;
  insuranceEffectiveDate: string | null;
  insuranceEndDate: string | null;
  insuranceAddress: string | null;
  preferredPaymentMethod: string | null;
  bankName: string | null;
  bankAddress: string | null;
  accountNumber: string | null;
  routingNumber: string | null;
  isHealthInsurance: boolean = true;
  dependents: any[] | null;
  browserName: string | null;
  browserVersion: string | null;
  groupId: number;
  age: number | null;
  memberStatus: string | null;
  groupReferenceId: string | null;
  groupName: string | null;
  planName: string | null;
  createdDate: Date | null;
}

export class MemberWithHraStatus extends Member {
  isHraCompleted: boolean = true;
  isClaimCompletedForCurrentMonth: boolean;
}
