import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DynamicControlService } from 'src/app/data-driven/dynamic-control.Service';
import { QuestionService } from 'src/app/data-driven/question.service';
import { HRAService } from 'src/app/services/hra.service';

@Component({
  selector: 'app-view-member-hra',
  templateUrl: './view-member-hra.component.html',
  styleUrls: ['./view-member-hra.component.scss']
})
export class ViewMemberHraComponent implements OnInit {

  form: FormGroup;
  questions;

  private _groupId: string;
  private _externalId: string;

  constructor(
    private _hraService: HRAService,
    private _route: ActivatedRoute,
    private questionService: QuestionService,
    private qcs: DynamicControlService
  ) {

    this._route.params.subscribe(params => {
      this._groupId = params['groupId'];
      this._externalId = params['memberId'];
    });

    this.getQuestions();
  }

  ngOnInit() {
    this._hraService.getHRA(this._groupId, this._externalId).subscribe(x => {
      if (!!x) {
        var value = JSON.parse(x.savedForm);
        if (typeof value === 'string') {
          value = JSON.parse(value);
        }
        this.form.patchValue(value);
      }
    });
  }

  private getQuestions() {
    this.questionService.getQuestions(this._externalId).subscribe(question => {
      this.questions = question;
      this.form = this.qcs.toFormGroup(this.questions, true);
    });
  }

}
