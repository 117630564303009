import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GroupService } from 'src/app/services/group.service';

@Component({
    selector: 'app-update-member',
    templateUrl: './update-member.component.html'
})
export class UpdateMemberComponent {
    selectedGroupId: string;
    selectedGroupReferenceId: string;
    selectedMemberId: string;
    header: string;

    constructor(private _route: ActivatedRoute, private _router: Router, private _groupService: GroupService) {
        this._route.params.subscribe(params => { this.selectedMemberId = params['memberId']; });
        this._route.params.subscribe(params => {
            this.selectedGroupReferenceId = params['groupId'];
            this._groupService.getGroup(params['groupId']).subscribe(group => {
                if (!!group) {
                    this.header = `Member of ${group.name} Group`;
                    this.selectedGroupId = group.externalId;
                }
            });
        });
    }

    showAllMembers() {
        this._router.navigate([`/admin/groups/${this.selectedGroupReferenceId}/members`]);
    }
}
