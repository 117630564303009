import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-update-schedule-plan',
  templateUrl: './update-period-schedule-plan.component.html'
})
export class UpdatePeriodSchedulePlanComponent {
   selectedId: string;
  constructor(private _route: ActivatedRoute, private _router: Router) {
    this._route.params.subscribe(params => { this.selectedId = params['id']; });
    }
}
