import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { getApiUrl } from "../helpers/get-url";
import { Group } from "../models/group";
import { Observable } from "rxjs/internal/Observable";
import { StringResponse } from "../models/stringResponse";
import { User } from "../models/User";
import { Member, MemberWithHraStatus } from "../models/member";
import { FamilyMembers } from "../models/family-member";
import { Lead } from "../models/Lead";
// import { Company } from '../models/company';

@Injectable({ providedIn: "root" })
export class GroupService {
  constructor(private http: HttpClient) {}

  getAllGroups(): Observable<Group[]> {
    return this.http.get<Group[]>(getApiUrl("groups/GetAllGroups"));
  }

  getGroupMembers(groupReferenceId: string): Observable<MemberWithHraStatus[]> {
    return this.http.get<MemberWithHraStatus[]>(
      getApiUrl(`groups/${groupReferenceId}/members`)
    );
  }
  exportMembers(groupReferenceId: string): Observable<any> {
    let url = getApiUrl(`groups/${groupReferenceId}/exportMembers`);

    return this.http.get(url, { responseType: "blob" as "json" });
  
  }
  getGroupLeads(groupId:any): Observable<Lead[]> {
    return this.http.get<Lead[]>(getApiUrl("leads/GetLeadsByGroup/"+groupId));
  }
  
  getGroupMembersFamily(memberId: string): Observable<FamilyMembers[]> {
    return this.http.get<FamilyMembers[]>(
      getApiUrl(`MemberFamily?memberid=${memberId}`)
    );
  }

  getAllBrokers(groupReferenceId: string): Observable<User[]> {
    return this.http.get<User[]>(
      getApiUrl(`groups/${groupReferenceId}/brokers`)
    );
  }

  getGroup(groupReferenceId: string): Observable<Group> {
    return this.http.get<Group>(getApiUrl(`groups/${groupReferenceId}`));
  }

  getGroupName(groupReferenceId: string): Observable<StringResponse> {
    return this.http.get<StringResponse>(
      getApiUrl(`groups/external/${groupReferenceId}/name`)
    );
  }

  addGroup(group: Group): Observable<Group> {    
    return this.http.post<Group>(getApiUrl("groups"), group);
  }

  updateGroup(groupReferenceId: string, group: Group): Observable<Group> {    
    return this.http.put<Group>(getApiUrl(`groups/${groupReferenceId}`),group );
  }

  deleteGroup(groupReferenceId: string) {
    return this.http.delete(getApiUrl(`groups/${groupReferenceId}`));
  }

  validateGroupAndMember(
    externalGroupId: string,
    externalMemberId: string
  ): Observable<Member> {
    return this.http.get<Member>(
      getApiUrl(`groups/validate/${externalGroupId}/${externalMemberId}`)
    );
  }
  validateGroupExternalId(
    externalId: string,
    groupId: number
  ): Observable<Group> {
    return this.http.get<Group>(
      getApiUrl(`groups/validateExternalId/${externalId}/${groupId}`)
    );
  }

  getGroupDetailsByID(groupId: string) : Observable<Group>
  {
    return this.http.get<Group>(getApiUrl("groups/GetGroupDetails/" + groupId))
  }
  getGroupByGroupId(groupId: Number) : Observable<Group>
  {
    return this.http.get<Group>(getApiUrl("groups/GetGroupById/" + groupId))
  }

  getGroupNameByExternalID(groupExternalId: string): Observable<string> {
    return this.http.get<string>(getApiUrl(`groups/external/${groupExternalId}/name`))
  }
  uploadLeadByAdmin(groupExternalId: string, leadFile:any){
    var formData: any = new FormData();
    formData.append('groupExternalId', groupExternalId);    
    formData.append('leadFile', leadFile);    
    return this.http.post<any>(getApiUrl('leads/upload-lead-csv'), formData);
  }

  getGroupNameById(groupId: Number): Observable<Group> {
    return this.http.get<Group>(getApiUrl("groups/GetGroupById/" + groupId))
  }
}
