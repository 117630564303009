import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Employee } from "../models/employee";
import { Observable } from "rxjs";
import { getApiUrl } from "../helpers/get-url";
import { TermsAndConditions } from "../models/termsAndConditions";
import { AddressValidateModel } from "../models/addressValidateModel";
import { ResponseAddressBool } from "../models/responseAddressBool";

@Injectable({
  providedIn: "root",
})
export class EmployeesService {
  constructor(private http: HttpClient) {}

  AddEmployee(Employee: Employee): Observable<Employee> {
    return this.http.post<Employee>(getApiUrl("Employee/AddEmployeesDetails"),Employee);
  }

  getTermsAndConditions() : Observable<any>
  {
    return this.http.get<any>(
      getApiUrl("Employee/GetAllTermsAndConditions")
    );
  }

  getAllPlans(): Observable<any>
  {
     return this.http.get<any>(getApiUrl("plans/GetAllPlans"));
  }

  getAllStates() : Observable<any>
  {
    return this.http.get<any>("https://hmmemberdev.azurewebsites.net/api/States");

  }

  ValidateAddress(addressValidateModel: AddressValidateModel) : Observable<any>
  {
    return this.http.post<any>("https://hmmemberdev.azurewebsites.net/api/Address/validate-address",addressValidateModel);
  }
}
