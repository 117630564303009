import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { BtnCellRendererComponent } from 'src/app/shared/controls/renderers/button-renderer/btn.renderer';
import { AgGridModule } from 'ag-grid-angular';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserModule } from '@angular/platform-browser';
import { EnrollmentComponent } from '../enrollment/enrollment.component';
import { AddDependentComponent } from '../enrollment/add-dependent/add-dependent.component';
import { SignaturePadComponent } from 'src/app/shared/controls/signature/signature-pad/signature-pad.component';
import { SignaturePadModule } from 'angular2-signaturepad';
import { AlertifyService } from 'src/app/services/alertify.service';
import { LeadValidateComponent } from './lead-validate/lead-validate.component';

@NgModule({
  entryComponents: [AddDependentComponent,LeadValidateComponent],
  declarations: [
    EnrollmentComponent,
    AddDependentComponent,
    SignaturePadComponent,
    LeadValidateComponent
  ],
  imports: [
    AgGridModule.withComponents([
      BtnCellRendererComponent
    ]),
    CommonModule,
    BrowserModule,
    FormsModule,
    NgbModule,
    ReactiveFormsModule,
    SharedModule,
    SignaturePadModule
  ],
  exports: [
    AddDependentComponent,LeadValidateComponent
  ],
  providers: [    
    AlertifyService
  ]
})
export class EnrollmentModule { }
