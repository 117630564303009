import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';

import { ColDef } from 'ag-grid-community';
import { DatePipe } from '@angular/common';
import { InvoiceStatus } from '../../../../models/enums/invoiceStatus';
import { InvoiceService } from 'src/app/services/invoice.service';
import { PeriodService } from 'src/app/services/period.service';
import { AlertifyService } from 'src/app/services/alertify.service';
import { GroupService } from 'src/app/services/group.service';
import { Invoice } from 'src/app/models/invoice';

@Component({
  selector: 'app-invoice-detail',
  templateUrl: './invoice-detail.component.html',
  styleUrls: ['./invoice-detail.component.css']
})

export class InvoiceDetailComponent implements OnInit {
  // period: Period[];
  // groupList: Group[];
  invoice: Invoice = new Invoice();
  invoiceitem: any[];
  invoiceForm: FormGroup;
  loading: boolean;
  invoiceCreateForm: FormGroup;
  submitted: boolean;
  invoiceId: string;
  groupId: string;
  groupDetail: any;
  selectedEnumStatus: InvoiceStatus = InvoiceStatus.All;
  
  constructor(private _invoiceService: InvoiceService, private _periodService: PeriodService,
    private _groupService: GroupService,
    private _fb: FormBuilder, private _alertify: AlertifyService,
    private _route: ActivatedRoute, private _router: Router) {

    this._route.params.subscribe(params => { this.invoiceId = params['id']; });
    const _this = this;

  }

  setPrinterFriendly(api) {
    var eGridDiv = document.querySelector(".ag-grid-500");
    api.setDomLayout("print");
  }
  setNormal(api) {
    var eGridDiv = document.querySelector(".ag-grid-500");
    //eGridDiv.style.width = "600px";
    //eGridDiv.style.height = "200px";
    api.setDomLayout(null);
  }
  ngOnInit() {  
    
  
    this.getInvoiceById(this.invoiceId);
    this.getAllInvoices();

  }
  getAllInvoices() {

    this._invoiceService.getAllItemByInvoiceId(this.invoiceId).subscribe(res => {
      this.invoiceitem = res;
    })
  }
  

  
  getInvoiceById(invoiceId: string) {
    this._invoiceService.getInvoiceById(invoiceId).subscribe(data => {
      
      this.invoice = data;
      this.groupId = data.groupId;
      this._groupService.getGroup(this.invoice.groupId).subscribe(data => {
        
        this.groupDetail = data;
      });
    });
    
  }

}

