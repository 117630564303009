import { Location } from "@angular/common";
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ColDef } from 'ag-grid-community';
import { Plan } from 'src/app/models/plan';
import { PlanService } from 'src/app/services/plan.service';
import { BtnCellRendererComponent } from 'src/app/shared/controls/renderers/button-renderer/btn.renderer';
import { GroupService } from '../../../services/group.service';
import { AlertifyService } from 'src/app/services/alertify.service';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-select-plans',
  templateUrl: './select-plans.component.html',
  styleUrls: ['./select-plans.component.css']
})
export class SelectPlansComponent {
  currentUser:any;
  columnDefs: ColDef[];
  private gridApi;
  plans: Plan[];
  defaultColDef = {
    flex: 1,
    minWidth: 150,
    resizable: true,
  };
  groupId: string;
  groupName: string='';
  
  constructor(private _planService: PlanService,
    private _groupService:GroupService,
    private _route: ActivatedRoute,
    private _router: Router,
    private location: Location,
    private authenticationService: AuthenticationService,
    private alertify : AlertifyService
    ) { }

  ngOnInit() {
    this.currentUser = this.getCurrentUserFromLocalStorage();
    this._route.queryParamMap.subscribe((param) => {
      this.groupId = param.get('groupid');
      if (this.groupId) {
        this.getGroupNameByExID(this.groupId);       
      }
    }); 
    this.columnDefs = [
      { headerName: "Product ID", field: "name", suppressMovable: true, sortable: true, filter: true },
      {
        headerName: "Premium Amount",
        field: "monthlyPremiumAmount",
        suppressMovable: true,
        sortable: true,
        filter: true,
        valueFormatter: (params) => "$" + params.data.monthlyPremiumAmount,
      },
      {
        headerName: "Benefit Amount",
        field: "monthlyClaimAmount",
        suppressMovable: true,
        sortable: true,
        filter: true,
        valueFormatter: (params) => "$" + params.data.monthlyClaimAmount,
      },
    ];
    this.columnDefs.push({
      headerName: "Enroll",
      field: "name",
      suppressMovable: true,
      cellRendererFramework: BtnCellRendererComponent,
      cellRendererParams: {
        showDeleteBtn: false,
        showEditBtn: false,
        showEnrollmentBtn:true,
        clicked: function (isDelete: boolean, id: any) {
          // this.router.navigate(['enrollment/AddLead'],{
          //   queryParams: {
          //     PlanName: id
          //   }
          // })
        },
      },
    });
  }

  getGroupNameByExID(groupExternalId:string) {
    this._groupService.getGroupNameByExternalID(groupExternalId).subscribe((res: any) => {
      this.groupName = res.value;
      if (this.groupName != '')
        this.getAllPlans();
    }, error => {      
      console.log(error.error);
      //this.alertify.error(error.error);
      this.authenticationService.currentUser.subscribe(x => {
        this.currentUser = x;
        if (!!this.currentUser) {
          if(this.authenticationService.isAdmin){
            this._router.navigate(['admin/groups']);
          }
          else {
            this._router.navigate(['enrollment/error'], {
              queryParams: { error: error.error },
            });
          }
        } 
        else {
          this._router.navigate(['enrollment/error'], {
            queryParams: { error: error.error },
          });
        }
      }); 
    }   
    );
  }

  getCurrentUserFromLocalStorage() {
    // Get the currentUser from localStorage (this logic may vary based on your implementation)
    const currentUser = localStorage.getItem('currentUser');
    return currentUser ? JSON.parse(currentUser) : null;
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridApi.setDomLayout("autoHeight");
  }

  getAllPlans() {
    this._planService.getAllPlans().subscribe((plans) => {
      this.plans = plans;
      // console.log('this.plans: ', this.plans);
    });
  }
  back() {
    this.location.back();
  }
}
