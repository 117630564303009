import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { catchError, debounceTime, distinctUntilChanged, first, map, switchMap, tap } from 'rxjs/operators';
import { GroupService } from 'src/app/services/group.service';
import { ColDef } from 'ag-grid-community';
import { BtnCellRendererComponent } from 'src/app/shared/controls/renderers/button-renderer/btn.renderer';
//import { CompanyService } from 'src/app/services/company.service';
import { AlertifyService } from 'src/app/services/alertify.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { PeriodService } from '../../../../services/period.service';
import { Period } from '../../../../models/period';
import { Group } from '../../../../models/group';
import { PeriodScheduleService } from '../../../../services/periodSchedule.service';
import { InvoiceService } from '../../../../services/invoice.service';
import { GridApi } from 'ag-grid-community';

function actionCellRenderer(params) {
  let eGui = document.createElement("div");

  let editingCells = params.api.getEditingCells();
  // checks if the rowIndex matches in at least one of the editing cells
  let isCurrentRowEditing = editingCells.some((cell) => {
    return cell.rowIndex === params.node.rowIndex;
  });

  if (isCurrentRowEditing) {
    eGui.innerHTML = `
<button  class="btn btn-success btn-sm action-button update"  data-action="update"> Update  </button>
<button  class="btn btn-secondary btn-sm action-button cancel"  data-action="cancel" > Cancel </button>
`;
  } else {
    eGui.innerHTML = `
    <button class="btn btn-success btn-sm action-button edit"  data-action="edit" > Edit  </button>
<button class="btn btn-danger btn-sm action-button delete" data-action="delete"> Delete </button>
`;
  }
  return eGui;
}

@Component({
  selector: 'app-view-invoicepayment',
  templateUrl: './add-invoice.component.html',
  styleUrls: ['./add-invoice.component.scss'],
})
export class AddInvoiceComponent implements OnInit {
  @Input()
  groupRefrenceId: string;
  Id: number;

  periodId: number;
  claimForm: FormGroup;
  periodValue: string;
  state: string;
  loading = false;
  loadingBroker = false;
  groupData: Group = new Group();
  members: any;
  submitted = false;
  groupId: number;
  ShowGrid = false;
  periodScheduleList: any;
  period: Period[];
  periodScheduleId: string;

  errorMessage: string[] = [];
  successMessage = '';
  addUpdateError: string[] = [];
  columnDefs: ColDef[] = [];

  defaultColDef = {
    flex: 1,
    minWidth: 150,
    resizable: true,
    cellDataType: 'text',
    editable: true
  };
  claimFormData: any;
  claimPaymentId: number;
  private gridApi;
 
  LineItemForm: FormGroup;
  step = 1;
  constructor(
    private _groupService: GroupService, private _datepipe: DatePipe,
    private _formBuilder: FormBuilder,
    private _invoiceService: InvoiceService,
    private _fb: FormBuilder, private _periodScheduleService: PeriodScheduleService,
    private _alertify: AlertifyService,
    private _router: Router, private _route: ActivatedRoute
  ) {

    this._route.params.subscribe(params => {
      this.groupRefrenceId = params['id'];
    });
   
    const _this = this;
    this.columnDefs = [

      { hide: true, field: 'memberId' },
      { hide: true, field: 'periodId' },
      { hide: true, field: 'groupId' },
      {
        headerName: 'MemberId', field: 'externalMemberId', suppressMovable: true, maxWidth: 120, sortable: true, filter: true,editable: false,
      },
      {
        headerName: 'Name', field: 'memberName', suppressMovable: true, maxWidth: 150, sortable: true, filter: true, editable: false,
      },
      {
        headerName: 'Amount', field: 'premiumAmount', sortable: true, maxWidth: 300, filter: true, editable: true,
        valueFormatter: function (params) {
          return !!params.value ? '$' + params.value : '';
        }
      },
      {
        headerName: 'Plan Name', field: 'planName', suppressMovable: true, maxWidth: 150, sortable: true, filter: true, editable: false
      },
      {
        headerName: 'Notes', field: 'notes', suppressMovable: true, maxWidth: 150, sortable: true, filter: true, editable: true
      },
      {
        headerName: "",
        minWidth: 150,
        cellRenderer: actionCellRenderer,
        editable: false,
        colId: "action"
      }
    ];
   
  }
  ngOnInit() { this.getGroupById(); this.getLineItemForm(); }
  onCellClicked(params) {
    
    // Handle click event for action cells
    if (params.column.colId === "action" && params.event.target.dataset.action) {
      let action = params.event.target.dataset.action;

      if (action === "edit") {
        params.api.startEditingCell({
          rowIndex: params.node.rowIndex,
          // gets the first columnKey
          colKey: params.columnApi.getDisplayedCenterColumns()[0].colId
        });
      }

      if (action === "delete") {
        params.api.applyTransaction({
          remove: [params.node.data]
        });
        this.members.splice(params.node.rowIndex, 1);
      }

      if (action === "update") {
        params.api.stopEditing(false);
      }

      if (action === "cancel") {
        params.api.stopEditing(true);
      }
    }
  }

  onRowEditingStarted(params) {
    params.api.refreshCells({
      columns: ["action"],
      rowNodes: [params.node],
      force: true
    });
  }
  onRowEditingStopped(params) {
    params.api.refreshCells({
      columns: ["action"],
      rowNodes: [params.node],
      force: true
    });
  }
  
  getLineItemForm() {
    this.LineItemForm = this._fb.group({
      id: [""],
      paymentAmount: ["", [Validators.required]]

    });
  }
  getGroupById() {
    if (!!this.groupRefrenceId) {
      this._groupService.getGroup(this.groupRefrenceId).subscribe(group => {
        this.groupData = group;
        this.periodId = group.periodId;
        this.groupId = group.id;
        // this.getPeriods();
        this._getAllSchedule(this.periodId);
      });

    }
  }
  private params: any;

  agInit(params: any): void {
    this.params = params;
  }

  btnClickedHandler() {
   
    let selectedNode = this.params.node;
    let selectedData = selectedNode.data;
    this.params.api.updateRowData({ remove: [selectedData] });
  }
  _getAllSchedule(periodid) {
    this._periodScheduleService.getPeriodScheduleById(periodid).subscribe(data => {
      this.periodScheduleList = data;
    });
  }
 
  changePeriod(event) {
    
    this.ShowGrid = true;
    this.periodScheduleId = event.target.value;
    
    this.getAllMembers();


  }
  getAllMembers() {

    this._invoiceService.getMemberByPeriod(this.groupRefrenceId, Number(this.periodScheduleId))
      .subscribe((members) => {
      this.members = members;
    });
  }


  submit() {
    this.submitted = true;
    this.loading = true;
    if (this.members.length > 0) {
      let convertedArray = this.members.map(item => ({
        ...item, premiumAmount: Number(item.premiumAmount),
        periodScheduleId: Number(this.periodScheduleId)
      }));
      const memberInvoice = {
        "groupId": convertedArray[0].groupId,
        "periodId": convertedArray[0].periodId,
        "periodScheduleId": Number(this.periodScheduleId),
        "lineItems": convertedArray
      };
      this._invoiceService.addInvoiceMemberDetail(memberInvoice).subscribe(
        (res) => {
           this.loading = false;
            this._alertify.success('Invoice added successfully.');
            this._router.navigate(['/admin/invoice-detail/' + res.id]);
          },
          error => {
            
            if (error.error instanceof Array) {
              error.error.map(err => this.errorMessage.push(err.value));
            } else if (typeof error.error === 'string') {
              this.errorMessage.push(error.error);
            } else {
              this.errorMessage.push(error);
            }
            this.loading = false;
          });
    }
  }





  // convenience getter for easy access to form fields
  get f() {
    return this.LineItemForm.controls;
  }


  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridApi.setDomLayout("autoHeight");
  }


}

