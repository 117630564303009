import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { getApiUrl } from '../helpers/get-url';
import { Observable } from 'rxjs/internal/Observable';
import { IndemnityBenefit } from '../models/indemnityBenefit';

@Injectable({ providedIn: 'root' })
export class IndemnityBenefitService {
  constructor(private http: HttpClient) { }

  getAllIndemnityBenefits(): Observable<IndemnityBenefit[]> {
    return this.http.get<IndemnityBenefit[]>(getApiUrl('indemnity-benefits'));
  }

  getActiveIndemnityBenefits(): Observable<IndemnityBenefit[]> {
    return this.http.get<IndemnityBenefit[]>(getApiUrl('indemnity-benefits/active'));
  }

  getIndemnityBenefit(indemnityBenefitId: number): Observable<IndemnityBenefit> {
    return this.http.get<IndemnityBenefit>(getApiUrl(`indemnity-benefits/${indemnityBenefitId}`));
  }

  addIndemnityBenefit(indemnityBenefit: IndemnityBenefit): Observable<IndemnityBenefit> {
    return this.http.post<IndemnityBenefit>(getApiUrl('indemnity-benefits'), indemnityBenefit);
  }

  updateIndemnityBenefit(indemnityBenefitId: number, indemnityBenefit: IndemnityBenefit): Observable<IndemnityBenefit> {
    return this.http.put<IndemnityBenefit>(getApiUrl(`indemnity-benefits/${indemnityBenefitId}`), indemnityBenefit);
  }

  deleteIndemnityBenefit(indemnityBenefitId: number) {
    return this.http.delete(getApiUrl(`indemnity-benefits/${indemnityBenefitId}`));
  }
}
