import { Component } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ColDef } from "ag-grid-community";
import { Claim } from "src/app/models/claim";
import { ClaimService } from "src/app/services/claim.service";
import { GroupNameRendererComponent } from "../../groups/group-list/group-name-renderer/group-name-renderer";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import { FormatDate } from "../../../../helpers/format";

@Component({
  selector: "app-claims-list",
  templateUrl: "./claims-list.component.html",
  styleUrls: ["./claims-list.component.scss"],
})
export class ClaimsListComponent {
  claims: Claim[];
  yearOptions = [];
  monthOptions = [
    { key: "Jan", value: 1 },
    { key: "Feb", value: 2 },
    { key: "Mar", value: 3 },
    { key: "Apr", value: 4 },
    { key: "May", value: 5 },
    { key: "Jun", value: 6 },
    { key: "Jul", value: 7 },
    { key: "Aug", value: 8 },
    { key: "Sep", value: 9 },
    { key: "Oct", value: 10 },
    { key: "Nov", value: 11 },
    { key: "Dec", value: 12 },
  ];

  filterParams = {
    comparator: function (filterLocalDateAtMidnight, cellValue) {
      if (cellValue == null) {
        return -1;
      }
      const dateAsString = cellValue.substr(0, 10);
      const dateParts = dateAsString.split("-");
      const cellDate = new Date(
        Number(dateParts[0]),
        Number(dateParts[1]) - 1,
        Number(dateParts[2])
      );
      if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
        return 0;
      }
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      }
      if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
    },
    browserDatePicker: true,
  };

  defaultColDef = {
    flex: 1,
    minWidth: 150,
    resizable: true,
  };
  columnDefs: ColDef[] = [];
 

  filterClaimForm: FormGroup;
  submitted = false;
  loading = false;
  private _gridApi;

  constructor(
    private _formBuilder: FormBuilder,
    private _claimService: ClaimService, private _date: FormatDate,
    private _router: Router
  ) {
    const _this = this;
    const date = new Date();
    this.columnDefs = [
      { hide: true, field: "referenceId" },
      {
        headerName: "Claim Date",
        field: "claimDate",
        suppressMovable:true,
        sortable: true,
        filter: "agDateColumnFilter",
        filterParams: this.filterParams,
        valueFormatter: function (params) {
          return !!params.value ? _date.formatDate(params.value.substr(0, 10)):'';
        },
      },
      {
        headerName: "Group Id",
        field: "externalGroupId",
        suppressMovable: true,
        sortable: true,
        filter: true,
        cellRendererFramework: GroupNameRendererComponent,
        cellRendererParams: {
          clicked: function (params: any) {
            _this._router.navigate([
              `/admin/group-view/${params.data.groupReferenceId}`,
            ]);
          },
        },
      },
      {
        headerName: "Member Id",
        field: "externalMemberId",
        suppressMovable: true,
        sortable: true,
        filter: true,
        cellRendererFramework: GroupNameRendererComponent,
        cellRendererParams: {
          clicked: function (params: any) {
            _this._router.navigate([
              `/admin/members/${params.data.externalMemberId}`,
            ]);
          },
        },
      },
      {
        headerName: "First Name",
        field: "firstName",
        suppressMovable: true,
        sortable: true,
        filter: true,        
      },
      {
        headerName: "Last Name",
        field: "lastName",
        suppressMovable: true,
        sortable: true,
        filter: true,
      },
      {
        headerName: "Date of Birth",
        field: "dob",
        suppressMovable: true,
        sortable: true,
        filter: "agDateColumnFilter",
        filterParams: this.filterParams,
        valueFormatter: function (params) {
          return !!params.value ? _date.formatDate(params.value.substr(0, 10)) : '';
        }
      },
      { headerName: "CPT Code", field: "code", sortable: true, filter: true },
      {
        headerName: "Termination Date",
        field: "terminationDate",
        suppressMovable: true,
        sortable: true,
        filter: "agDateColumnFilter",
        filterParams: this.filterParams,
        valueFormatter: function (params) {
          return !!params.value ? _date.formatDate(params.value.substr(0, 10)) : "";
        },        
      },  
      { headerName: "Plan Name", field: "planName", sortable: true, filter: true },    
    ];
    for (let i = 2020; i <= date.getFullYear(); i++) {
      this.yearOptions.push(i.toString());
    }

    this.filterClaimForm = this._formBuilder.group({
      claimMonth: [date.getMonth() + 1],
      claimYear: [date.getFullYear()],
    });

    this._getClaims();
  }

  onGridReady(params) {
    this._gridApi = params.api;
  }
  
  DownloadClaims() {
    
    const claimMonth = this.filterClaimForm.controls["claimMonth"].value;
    const claimYear = this.filterClaimForm.controls["claimYear"].value;

    this._claimService
      .exportToCSV(claimMonth, claimYear)
      .subscribe((response) => {
        let blob: any = new Blob([response], {
          type: "text/csv; charset=utf-8",
        });
        var filename = `Claims-${claimMonth}-${claimYear}.csv`;
        this.downloadContent(blob, filename);
      });
  }

  private downloadContent(body: Blob, fileName: string): void {
    let url = window.URL.createObjectURL(body);
    let a = document.createElement("a");
    document.body.appendChild(a);
    a.setAttribute("style", "display: none");
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.filterClaimForm.invalid) {
      return;
    }

    this.loading = true;
    this._getClaims();
  }

  private _getClaims() {
    const claimMonth = this.filterClaimForm.controls["claimMonth"].value;
    const claimYear = this.filterClaimForm.controls["claimYear"].value;
    this._claimService
      .getAllClaims(claimMonth, claimYear)
      .subscribe((claims) => {
        this.claims = claims;
        console.log(claims);
        this.loading = false;
        if (this._gridApi) {
          this._gridApi.hideOverlay();
          if (this.claims.length == 0)
            this._gridApi.showNoRowsOverlay();
        }
      });
  }

  public overlayNoRowsTemplate =
    '<span style="padding: 10px; border: 1px solid #ddd; background: lightgoldenrodyellow;">No rows found</span>';
}
