import { DatePipe, Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { Member } from 'src/app/models/member';
import { AlertifyService } from 'src/app/services/alertify.service';
import { MemberService } from 'src/app/services/member.service';
import { PlanService } from 'src/app/services/plan.service';

@Component({
  selector: 'member-view',
  templateUrl: './member-view.component.html',
  styleUrls: ['./member-view.component.css']
})
export class MemberViewComponent implements OnInit {
  plans: any;
  externalProvider: any[];
  showssn: boolean = false;
  memberSSN: string;
  memberId: string;
  memberForm: FormGroup;
  externalProviderForm: FormGroup;
  addDependentForm: FormGroup;
  memberData: Member = new Member();
  ipAddress: string;
  loading: boolean;
  IsNewRecord: boolean;
  submitted: boolean;
  showStartDate: boolean = false;
  startDate: Date;
  minStartDate: string;
  isStartDateValid: boolean = true;
  btnText: string = "Add";
  // for personal details
  minPrimaryAge: string;
  maxPrimaryAge: string;
  isMinPrimaryAgeValid: boolean = true;
  isMaxPrimaryAgeValid: boolean = true;
  city: string = '';
  state: string = '';
  enumStatuses = [
    { value: 'Pending', text: 'Pending' },
    { value: 'Active', text: 'Active' },
    { value: 'Terminated', text: 'Terminated' }
  ];

  enumType = [
    { value: 0, text: 'WHVC' }

  ];
  // for dependent
  dependentText: string = '';
  dependentId: number = 0;
  providerId: number = 0;
  minDependentAge: string;
  maxDependentAge: string;
  minChildAge: string;
  isMinDependentAgeValid: boolean = true;
  isMaxDependentAgeValid: boolean = true;
  noDependent = 'No Dependent Found';
  noProvider = 'No Provider Found';

  // for insurance
  addInsuranceForm: FormGroup;
  constructor(private _route: ActivatedRoute, private _router: Router,
    private _memberService: MemberService, private location: Location,
    private _planService: PlanService,
    private _fb: FormBuilder,
    private _alertify: AlertifyService) {
    this._route.params.subscribe(params => {
      this.memberId = params['memberid'];
    });
    // status activate start
    var minSD = new Date();
    minSD.setMonth(minSD.getMonth() - 1);
    var yr = minSD.getFullYear();
    var mn = (minSD.getMonth() + 1) > 9 ? (minSD.getMonth() + 1) : '0' + (minSD.getMonth() + 1);
    var dt = minSD.getDate();
    this.minStartDate = yr + '-' + mn + '-' + (dt < 10 ? '0' + dt : dt);
    //for personal
    var minPA = new Date();
    minPA.setFullYear(minPA.getFullYear() - 100);
    yr = minPA.getFullYear();
    mn = (minPA.getMonth() + 1) > 9 ? (minPA.getMonth() + 1) : '0' + (minPA.getMonth() + 1);
    dt = minPA.getDate();
    this.minPrimaryAge = yr + '-' + mn + '-' + (dt < 10 ? '0' + dt : dt);
    var maxPA = new Date();
    maxPA.setFullYear(maxPA.getFullYear() - 21);
    yr = maxPA.getFullYear();
    mn = (maxPA.getMonth() + 1) > 9 ? (maxPA.getMonth() + 1) : '0' + (maxPA.getMonth() + 1);
    dt = maxPA.getDate();
    this.maxPrimaryAge = yr + '-' + mn + '-' + (dt < 10 ? '0' + dt : dt);
    this.maxDependentAge = yr + '-' + mn + '-' + (dt < 10 ? '0' + dt : dt);  // for dependent

    var minDA = new Date();
    yr = minDA.getFullYear();
    mn = (minDA.getMonth() + 1) > 9 ? (minDA.getMonth() + 1) : '0' + (minDA.getMonth() + 1);
    dt = minDA.getDate();
    this.minChildAge = yr + '-' + mn + '-' + (dt < 10 ? '0' + dt : dt);
    minDA.setFullYear(minDA.getFullYear() - 100);
    yr = minDA.getFullYear();
    mn = (minDA.getMonth() + 1) > 9 ? (minDA.getMonth() + 1) : '0' + (minDA.getMonth() + 1);
    dt = minDA.getDate();
    this.minDependentAge = yr + '-' + mn + '-' + (dt < 10 ? '0' + dt : dt);

    this.getPlanList();
    this.getMemberForm();
    this.getDependentForm();
    this.getInsuranceForm();
    this.getExternalProviderForm();

  }
  openEnrollForm() {
    this._router.navigate([`/admin/enroll/member/` + this.memberId]);
  }
  ngOnInit() {
    this.getMemberById();//for member details
    this.getMemberAddress(); // for address   
    this.getMemberSignature(); // signature details
    this.getExternalProviderByMemberId();
  }
  getMemberById() {
    this._memberService.getMemberDetails(this.memberId).subscribe(member => {      
      this.memberData = member;
      this.getMemberDependents(); // for dependents
    });
  }
  getMemberDependents() {
    this._memberService.getMemberDependents(this.memberId).subscribe((dependents: any) => {
      console.log('dependents: ' + dependents);
      this.memberData.dependents = dependents;
    }, error => {
      this.loading = false;
      this.memberData.dependents = [];
    });
  }
  getMemberAddress() {
    this._memberService.getMemberAddress(this.memberId).subscribe((address: any) => {
      this.memberData.addressLine1 = address.mailingAddress;
      this.memberData.addressLine2 = address.apartmentNumber;
      this.memberData.city = address.city;
      this.memberData.zipCode = address.zipCode;
      this.memberData.state = address.state;
    });
  }


  getMemberSignature() {
    this._memberService.getMemberSignature(this.memberId).subscribe((signature: any) => {
      if (signature) {
        this.memberData.browserName = signature.browserName;
        this.memberData.browserVersion = signature.browserVersion;
        this.ipAddress = signature.ipAddress;
        this.memberData.signatureDateTime = signature.signatureDateTime;
        this.memberData.signaturePath = "data:image/png;base64," + signature.signaturePath;
      }
    });
  }
  updateStatus(status: any) {   
    this.submitted = true;
    if (status == 1) {
      this.showStartDate = true;
      if (!this.startDate) {
        return;
      }
      this.loading = true;
      const updateMember: any = {
        externalId: this.memberId,
        startDate: this.startDate,
        memberStatus: status
      }
      this._memberService.updateMemberStatus(updateMember).subscribe(res => {
        const btn = document.getElementById('closeStatus')!;
        btn.click();
        this.memberData.memberStatus = res.memberStatus;
        this.loading = false;
        this.submitted = false;
        this.showStartDate = false;
        this.startDate = null;
        this._alertify.success("Status updated successfully");
        

      }, error => {

        this.loading = false;
        this.submitted = false;
        this._alertify.error(error.error);
      });
    }
    else {
      this.loading = true;
      const updateMember: any = {
        externalId: this.memberId,
        startDate: this.startDate,
        memberStatus: status
      }
      this._memberService.updateMemberStatus(updateMember).subscribe(res => {
        this.memberData.memberStatus = res.memberStatus;
        this.loading = false;
        this.submitted = false;
        this.showStartDate = false;
        this.startDate = null;
        this._alertify.success("Status updated successfully");

      }, error => {

        this.loading = false;
        this.submitted = false;
        this._alertify.error(error.error);
      });
    }

  }
  submitActivation() {
    debugger;
    this.submitted = true;
    if (new Date(this.startDate) > new Date(this.minStartDate)) {
      this.isStartDateValid = true;
      this.updateStatus(1);
    }
    else {
      this.isStartDateValid = false;
    };
  }
  back() {
    this.location.back();
  }

  onSubmit() {
    this.submitted = true;
    if (!this.isMaxPrimaryAgeValid || !this.isMinPrimaryAgeValid) {
      return;
    }

    this.memberForm.controls.city.setValue(this.city);
    this.memberForm.controls.state.setValue(this.state);
    if (!this.memberForm.valid) {
      return;
    }
   
    this.memberForm.controls.planId.setValue(this.memberForm.value.planId == null || this.memberForm.value.planId == "" ? 0 : Number(this.memberForm.value.planId));
    this.loading = true;
    this._memberService.updateMemberPersonalDetails(this.memberId, this.memberForm.value)
      .pipe(first())
      .subscribe(
        (response) => {

          this.memberData = response;
          this.memberId = response.externalId;
          this.getMemberAddress();
          this.loading = false;
          this.submitted = false;
          this._alertify.success("Personal detail updated successfully");
          const button = document.getElementById('closeP')!;
          button.click();
        },
        (error) => {
          if (error.error instanceof Array) {
            this._alertify.error(error.error);
            error.error.map((err) => this._alertify.error(err.value));
          } else if (typeof error.error === "string") {
            this._alertify.error(error.error);
          } else {
            this._alertify.error(error.error);
          }
          this.loading = false;
        }
      );
  }

  showAllMembers() {
    this._router.navigate([`/admin/members`]);
  }
  showAllGroupMembers() {
    this._router.navigate([`/admin/group-view/` + this.memberData.groupReferenceId]);
  }
  getMemberForm() {
    this.memberForm = this._fb.group({
      id: [""],
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      addressLine1: ["", Validators.required],
      addressLine2: [""],
      middleName: [""],
      zipCode: ["", Validators.required],
      mobile: ["", Validators.required],
      secondaryMobile: [""],
      email: ["", [Validators.required, Validators.email]],
      dob: ["", Validators.required],
      gender: ["", Validators.required],
      maritalStatus: ["", Validators.required],
      city: [""],
      state: [""],
      planId: [""],
      memberStatus: [0],
      startDate: [""]
    });
  }
  get f() {
    return this.memberForm.controls;
  }
  dobValidatePrimary() {
    const minDate = new Date();
    minDate.setFullYear(minDate.getFullYear() - 100);
    const maxDate = new Date();
    maxDate.setFullYear(maxDate.getFullYear() - 21);
    const dob = new Date(this.memberForm.controls.dob.value);
    if (dob < minDate) {
      this.isMaxPrimaryAgeValid = false;
    }
    else {
      this.isMaxPrimaryAgeValid = true;
    }

    if (dob > maxDate) {
      this.isMinPrimaryAgeValid = false;
    }
    else {
      this.isMinPrimaryAgeValid = true;
    }
  }
  setMemberForm() {
    this.f.id.setValue(this.memberData.id);
    this.f.firstName.setValue(this.memberData.firstName);
    this.f.lastName.setValue(this.memberData.lastName);
    this.f.addressLine1.setValue(this.memberData.addressLine1);
    this.f.addressLine2.setValue(this.memberData.addressLine2);
    this.f.middleName.setValue(this.memberData.middleName);
    this.f.zipCode.setValue(this.memberData.zipCode);
    this.f.mobile.setValue(this.memberData.mobile);
    this.f.secondaryMobile.setValue(this.memberData.secondaryMobile);
    this.f.email.setValue(this.memberData.email);
    this.f.dob.setValue(this.memberData.dob == null ? '' : this.memberData.dob.toString().substr(0, 10));
    this.dobValidatePrimary();
    this.f.gender.setValue(this.memberData.gender);
    this.f.maritalStatus.setValue(this.memberData.maritalStatus);
    this.f.planId.setValue(this.memberData.planId);
    this.f.startDate.setValue(this.memberData.startDate == null ? null : this.memberData.startDate.toString().substr(0, 10));
    this.f.memberStatus.setValue(this.memberData.memberStatus);
    this.f.city.setValue(this.memberData.city);
    this.f.state.setValue(this.memberData.state);
  }

  handleOnChange(event: any) {
    if (event.target.value.length >= 5) {
      let xml: string = '<CityStateLookupRequest USERID="8H6THINK42633"><ZipCode ID="0"><Zip5>' + event.target.value + '</Zip5></ZipCode></CityStateLookupRequest>';
      this._memberService.getCityStateByZip(xml).subscribe((res) => {
        if (res) {
          this.city = res.zipCode.city;
          this.state = res.zipCode.state;
          this.f.city.setValue(this.city);
          this.f.state.setValue(this.state);
        }
      });
    }
  }
  getDependentForm() {
    this.addDependentForm = this._fb.group({
      type: ["", Validators.required],
      firstName: ["", Validators.required],
      middleName: [""],
      lastName: ["", Validators.required],
      dob: ["", Validators.required],
      gender: ["", Validators.required],
    });
  }

  get d() {
    return this.addDependentForm.controls;
  }

  dobValidate() {
    const minDate = new Date();
    const minAge = this.addDependentForm.controls.type.value == 'Child' ? 21 : 100
    minDate.setFullYear(minDate.getFullYear() - minAge);
    const maxDate = new Date();
    const maxAge = this.addDependentForm.controls.type.value == 'Child' ? 0 : 21
    maxDate.setFullYear(maxDate.getFullYear() - maxAge);
    const dob = new Date(this.addDependentForm.controls.dob.value);
    if (dob < minDate) {
      this.isMaxDependentAgeValid = false;
    }
    else {
      this.isMaxDependentAgeValid = true;
    }

    if (dob > maxDate) {
      this.isMinDependentAgeValid = false;
    }
    else {
      this.isMinDependentAgeValid = true;
    }
  }

  updateAgeValidation() {
    if (this.addDependentForm.controls.type.value == 'Spouse') {
      var minPA = new Date();
      minPA.setFullYear(minPA.getFullYear() - 100);
      var yr = minPA.getFullYear();
      var mn = (minPA.getMonth() + 1) > 9 ? (minPA.getMonth() + 1) : '0' + (minPA.getMonth() + 1);
      var dt = minPA.getDate();
      this.minDependentAge = yr + '-' + mn + '-' + (dt < 10 ? '0' + dt : dt);
      var maxPA = new Date();
      maxPA.setFullYear(maxPA.getFullYear() - 21);
      yr = maxPA.getFullYear();
      mn = (maxPA.getMonth() + 1) > 9 ? (maxPA.getMonth() + 1) : '0' + (maxPA.getMonth() + 1);
      dt = maxPA.getDate();
      this.maxDependentAge = yr + '-' + mn + '-' + (dt < 10 ? '0' + dt : dt);
    }
    else if (this.addDependentForm.controls.type.value == 'Child') {
      var maxPA = new Date();
      maxPA.setFullYear(maxPA.getFullYear() - 21);
      yr = maxPA.getFullYear();
      mn = (maxPA.getMonth() + 1) > 9 ? (maxPA.getMonth() + 1) : '0' + (maxPA.getMonth() + 1);
      dt = maxPA.getDate();
      this.minDependentAge = yr + '-' + mn + '-' + (dt < 10 ? '0' + dt : dt);
      this.maxDependentAge = this.minChildAge;
    }
  }
  editDependent(dependent: any) {
    if (dependent == null) {
      this.dependentId = 0;
      this.dependentText = "Add";
      this.getDependentForm();
    }
    else {
      this.dependentText = "Update";
      this.setDependentForm(dependent);
    }
  }
  removeDependent(dependent: any, index: number) {
    this._memberService.deleteDependent(dependent.dependentId).subscribe(res => {
      this.loading = false;
      this.submitted = false;
      this.getMemberDependents();
      this._alertify.success("Dependent removed successfully");
    }, error => {
      if (error.error instanceof Array) {
        error.error.map((err) => this._alertify.error(err.value));
      } else if (typeof error.error === "string") {
        this._alertify.error(error.error);
      } else {
        this._alertify.error(error.error);
      }
      this.loading = false;
    })
  }
  setDependentForm(dependent: any) {
    this.dependentId = dependent.dependentId;
    this.d.firstName.setValue(dependent.firstName);
    this.d.lastName.setValue(dependent.lastName);
    this.d.type.setValue(dependent.type);
    this.d.middleName.setValue(dependent.middleName);
    this.d.dob.setValue(dependent.dob.toString().substr(0, 10));
    this.dobValidate();
    this.d.gender.setValue(dependent.gender);
  }



  onDependentSubmit() {
    this.submitted = true;
    if (!this.isMaxDependentAgeValid || !this.isMinDependentAgeValid) {
      return;
    }
    if (this.addDependentForm.valid) {
      if (this.dependentId == 0) {
        if (this.addDependentForm.controls.type.value == 'Spouse' && this.memberData.dependents != undefined && this.memberData.dependents.filter(x => x.type.toLowerCase() == 'spouse').length > 0) {
          this._alertify.error("You have already added Spouse");
        }
        else {
          const member: any = {
            externalId: this.memberId,
            dependents: []
          };
          this._memberService.addEditDependent(this.dependentId, member, this.addDependentForm.value).subscribe(res => {
            this.loading = false;
            this.submitted = false;
            this._alertify.success("Dependent added successfully");
            this.getMemberDependents();
            this.getDependentForm();
            const button = document.getElementById('closeD')!;
            button.click();
          }, error => {
            if (error.error instanceof Array) {
              error.error.map((err) => this._alertify.error(err.value));
            } else if (typeof error.error === "string") {
              this._alertify.error(error.error);
            } else {
              this._alertify.error(error.error);
            }
            this.loading = false;
          })
        }
      }
      else {
        const spouse = this.memberData.dependents.filter(x => x.type.toLowerCase() == 'spouse');
        if (spouse.length > 0) {
          if (this.addDependentForm.controls.type.value == 'Spouse' && spouse[0].dependentId != this.dependentId) {
            this._alertify.error("You have already added Spouse");
          }
          else {
            const member: any = {
              externalId: this.memberId,
              dependents: []
            };
            this._memberService.addEditDependent(this.dependentId, member, this.addDependentForm.value).subscribe(res => {
              this.loading = false;
              this.submitted = false;
              this.getMemberDependents();
              this.getDependentForm();
              this._alertify.success("Dependent updated successfully");
              const button = document.getElementById('closeD')!;
              button.click();
            }, error => {
              if (error.error instanceof Array) {
                error.error.map((err) => this._alertify.error(err.value));
              } else if (typeof error.error === "string") {
                this._alertify.error(error.error);
              } else {
                this._alertify.error(error.error);
              }
              this.loading = false;
            })
          }
        }
        else {
          const member: any = {
            ssn: this.memberId,
            dependents: []
          };
          this._memberService.addEditDependent(this.dependentId, member, this.addDependentForm.value).subscribe(res => {
            this.loading = false;
            this.submitted = false;
            this.getMemberDependents();
            this._alertify.success("Dependent updated successfully");
            const button = document.getElementById('closeD')!;
            button.click();
          }, error => {
            if (error.error instanceof Array) {
              error.error.map((err) => this._alertify.error(err.value));
            } else if (typeof error.error === "string") {
              this._alertify.error(error.error);
            } else {
              this._alertify.error(error.error);
            }
            this.loading = false;
          })
        }
      }

    }
  }

  getInsuranceForm() {
    this.addInsuranceForm = this._fb.group({
      hasInsurance: ["1", Validators.required],
      healthInsuranceCompanyName: ["", Validators.required],
      insurancePolicyNo: ["", Validators.required],
      insuranceEffectiveDate: ["", Validators.required],
      insuranceEndDate: ["", Validators.required],
      insuranceAddress: ["", Validators.required]
    });
  }

  get i() {
    return this.addInsuranceForm.controls;
  }

  setInsuranceForm() {
    this.i.hasInsurance.setValue(this.memberData.insurancePolicyNo == null || this.memberData.insurancePolicyNo == '' ? "2" : "1");
    this.i.healthInsuranceCompanyName.setValue(this.memberData.healthInsuranceCompanyName);
    this.i.insurancePolicyNo.setValue(this.memberData.insurancePolicyNo);
    this.i.insuranceEffectiveDate.setValue(this.memberData.insuranceEffectiveDate == null || this.memberData.insuranceEffectiveDate == '' ? '' : this.memberData.insuranceEffectiveDate.substr(0, 10));
    this.i.insuranceEndDate.setValue(this.memberData.insuranceEndDate == null || this.memberData.insuranceEndDate == '' ? '' : this.memberData.insuranceEndDate.substr(0, 10));
    this.i.insuranceAddress.setValue(this.memberData.insuranceAddress);
  }
  setExternalProviderForm(provider: any) {
    this.providerId = provider.id;
    this.t.id.setValue(provider.id);
    this.t.type.setValue(provider.type);
    this.t.effectiveDate.setValue(provider.effectiveDate == null ? null : provider.effectiveDate.toString().substr(0, 10));
    this.t.terminationDate.setValue(provider.terminationDate == null ? null : provider.terminationDate.toString().substr(0, 10));

  }

  getExternalProviderForm() {
    this.externalProviderForm = this._fb.group({
      terminationDate: [null, Validators.required],
      type: [0, Validators.required],
      effectiveDate: ["", Validators.required],
      id: [0]
    });
  }
  get t() {
    return this.externalProviderForm.controls;
  }
  editProvider(provider: any) {

    if (provider == null) {
      this.providerId = 0;
      this.btnText = "Add";
      this.IsNewRecord = true;
      this.getExternalProviderForm();
    }
    else {
      this.btnText = "Update";
      this.IsNewRecord = false;
      this.setExternalProviderForm(provider);
    }
  }
  getExternalProviderByMemberId() {

    this._memberService.getMemberProvider(this.memberId).subscribe((provider: any) => {

      if (provider) {

        this.btnText = "Edit";
        this.IsNewRecord = false;
        this.externalProvider = provider;
      }
      else if (provider == null) {
        this.btnText = "Add";
        this.IsNewRecord = true;

      }
    });
  }
  onExternalProviderSubmit() {
    if (this.IsNewRecord) {
      this.externalProviderForm.get('terminationDate').clearValidators();
      this.externalProviderForm.get('terminationDate').updateValueAndValidity();
    }
    else {
      this.externalProviderForm.get('terminationDate').setValidators([Validators.required]);
      this.externalProviderForm.get('terminationDate').updateValueAndValidity();
    }
    this.submitted = true;
    if (!this.externalProviderForm.valid) {
      return;
    }
 
    if (!this.IsNewRecord && this.externalProviderForm.controls.terminationDate.value==null) {
      const button = document.getElementById('closeT')!;
      button.click();
      return;
    }
    this.loading = true;
    this._memberService.addUpdateMemberExternalProvider(this.memberId, this.externalProviderForm.value)
      .pipe(first()).subscribe((response) => {
        this.loading = false;
        this.submitted = false;
        if (!this.IsNewRecord) {
          this._alertify.success("Termination date updated successfully");
        }
        else {
          this._alertify.success("External Provider detail added successfully");
        }
        const button = document.getElementById('closeT')!;
        button.click();

        this.getExternalProviderByMemberId();

      },
        (error) => {
          if (error.error instanceof Array) {
            this._alertify.error(error.error);
            error.error.map((err) => this._alertify.error(err.value));
          } else if (typeof error.error === "string") {
            this._alertify.error(error.error);
          } else {
            this._alertify.error(error.error);
          }
          this.loading = false;
        }
      );
  }

  onInsuranceSubmit() {
    if (this.addInsuranceForm.controls.hasInsurance.value == 1) {
      this.addInsuranceForm.get('healthInsuranceCompanyName').setValidators([Validators.required]);
      this.addInsuranceForm.get('healthInsuranceCompanyName').updateValueAndValidity();
      this.addInsuranceForm.get('insurancePolicyNo').setValidators([Validators.required]);
      this.addInsuranceForm.get('insurancePolicyNo').updateValueAndValidity();
      this.addInsuranceForm.get('insuranceAddress').setValidators([Validators.required]);
      this.addInsuranceForm.get('insuranceAddress').updateValueAndValidity();
      this.addInsuranceForm.get('insuranceEffectiveDate').setValidators([Validators.required]);
      this.addInsuranceForm.get('insuranceEffectiveDate').updateValueAndValidity();
      this.addInsuranceForm.get('insuranceEndDate').setValidators([Validators.required]);
      this.addInsuranceForm.get('insuranceEndDate').updateValueAndValidity();
    } else {
      this.addInsuranceForm.get('healthInsuranceCompanyName').clearValidators();
      this.addInsuranceForm.get('healthInsuranceCompanyName').updateValueAndValidity();
      this.addInsuranceForm.get('insurancePolicyNo').clearValidators();
      this.addInsuranceForm.get('insurancePolicyNo').updateValueAndValidity();
      this.addInsuranceForm.get('insuranceAddress').clearValidators();
      this.addInsuranceForm.get('insuranceAddress').updateValueAndValidity();
      this.addInsuranceForm.get('insuranceEffectiveDate').clearValidators();
      this.addInsuranceForm.get('insuranceEffectiveDate').updateValueAndValidity();
      this.addInsuranceForm.get('insuranceEndDate').clearValidators();
      this.addInsuranceForm.get('insuranceEndDate').updateValueAndValidity();
      this.addInsuranceForm.controls.healthInsuranceCompanyName.setValue('');
      this.addInsuranceForm.controls.insurancePolicyNo.setValue('');
      this.addInsuranceForm.controls.insuranceAddress.setValue('');
      this.addInsuranceForm.controls.insuranceEffectiveDate.setValue(null);
      this.addInsuranceForm.controls.insuranceEndDate.setValue(null);
    }

    this.submitted = true;
    if (this.addInsuranceForm.invalid) {
      return;
    }
    this._memberService.updateMemberInsurance(this.memberId, this.addInsuranceForm.value).subscribe(res => {
      this.loading = false;
      this.submitted = false;
      this.getMemberById();
      this._alertify.success("Insurance updated successfully");
      const button = document.getElementById('closeI')!;
      button.click();
    }, error => {
      if (error.error instanceof Array) {
        error.error.map((err) => this._alertify.error(err.value));
      } else if (typeof error.error === "string") {
        this._alertify.error(error.error);
      } else {
        this._alertify.error(error.error);
      }
      this.loading = false;
    })
  }
  showSSN() {
    this.showssn = !this.showssn;
    if (this.showssn) {
      this._memberService.getSSNByMemberId(this.memberId).subscribe(res => {
        this.memberSSN = res;
      })
    }
  }
  getPlanList() {
    this._planService.getAllPlans().subscribe(res => {
      this.plans = res;
    })
  }
  sendCertificate() {
    this._memberService.sendCertificateByAdmin(this.memberId).subscribe((res: any) => {
      this._alertify.success(res.message);
    }, error => {
      this._alertify.error(error.error);
    });
  }
}
