import { Injectable } from '@angular/core';
import { CrudService } from './crudservice';
import { StripePayment } from '../models/StripePayment';
import { ApiEndPoints } from './ApiEndPoints';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { getApiUrl } from '../helpers/get-url';

@Injectable({
  providedIn: 'root',
})
export class EnrollmentService {
  constructor(private crudService: CrudService, private http: HttpClient) { }

  AddLead(lead: any) {
    return this.crudService.post(ApiEndPoints.addLead, lead);
  }

  UpdateLead(lead: any, familyId: string, index: number, program: string) {
    return this.crudService.post(ApiEndPoints.updateLead + '/' + familyId + '/' + index + '/' + program, lead);
  }

  UpdateLeadFromAdmin(lead: any, familyId: string) {
    return this.crudService.post(ApiEndPoints.updateLeadFromAdmin + '/' + familyId, lead);
  }

  SetupLeadPayment(program: string, leadNo: string) {
    return this.crudService.get(ApiEndPoints.setupLeadPayment + '/' + program + '/' + leadNo);
  }

  GetMainLead(program: string, leadNo: string) {
    return this.crudService.get(ApiEndPoints.getMainLead + '/' + program + '/' + leadNo);
  }

  ValidateLeadOnPaymentCallback(program: string, leadNo: string) {
    return this.crudService.get(ApiEndPoints.validateLeadPaymentCallback + '/' + program + '/' + leadNo);
  }

  UpdateLeadPayment(updateStripePayment: StripePayment) {
    return this.crudService.post(ApiEndPoints.updateLeadPayment, updateStripePayment);
  }

  CreateLeadUser(leadUser: any) {
    return this.crudService.post(ApiEndPoints.createLeadUser, leadUser);
  }

  GetCityStateFromZipCode(zipcode: string) {
    return this.crudService.get(ApiEndPoints.getCityStateFromZipCode + '/' + zipcode);
  }

  ValidateAddress(leadUser: any) {
    return this.crudService.post(ApiEndPoints.validateAddress, leadUser);
  }

  UpdateCompletedSteps(stepsDetails: any) {
    return this.crudService.post(ApiEndPoints.updateCompletedSteps, stepsDetails);
  }

  GetPlanAndProducerDetails(producerCode: string, planName: string, programType: string) {
    return this.crudService.get(
      ApiEndPoints.validateEnrollment + '/' + programType + '?planName=' + planName + '&producerCode=' + producerCode
    );
  }

  ValidateCaptcha(captchaResponse: any) {
    return this.crudService.post(ApiEndPoints.validateCaptcha, captchaResponse);
  }

  GetCaptchaKeys() {
    return this.crudService.get(ApiEndPoints.getRecaptchaPublicKey);
  }

  ValidateEnrollmentFromFamilyId(enrollmentDetails: any) {
    return this.crudService.post(ApiEndPoints.validateEnrollmentFromFamilyId, enrollmentDetails);
  }

  ValidateEnrollmentFromLeadId(enrollmentDetails: any) {
    return this.crudService.post(ApiEndPoints.validateEnrollmentFromLeadId, enrollmentDetails);
  }

  GetLeadById(leadId: number) {
    return this.crudService.get(ApiEndPoints.getLeadById + '?id=' + leadId);
  }
  GetLeadFromStep(program: string, familyId: string, step: number) {
    return this.crudService.get(ApiEndPoints.getMainLead + '/' + program + '/' + familyId + '/' + step);
  }

  GetLeadsFromFamilyIdForAdmin(familyId: string) {
    return this.crudService.get(ApiEndPoints.getLeadsFromFamilyIdForAdmin + '/' + familyId);
  }

  GetLeadsForAdmin(params: any) {
    const req = { params: params };
    return this.crudService.get(ApiEndPoints.getAdminLeads, req);
  }

  GetLeadsForExport(params: any) {
    const req = { params: params };
    return this.crudService.get(ApiEndPoints.getLeadsForExport + params, { responseType: 'blob' as 'json' });
  }

  DeleteLeadFromAdmin(leadId: string) {
    return this.crudService.delete(ApiEndPoints.deleteLeadFromAdmin + '/' + leadId);
  }
  GetLeadByIdFromAdmin(leadId: string) {
    return this.crudService.get(ApiEndPoints.getLeadByIdForAdmin + '/' + leadId);
  }
  validateRoutingNumber(routingNumber: string) {
    return this.crudService.get(ApiEndPoints.validateRoutingNumber + '/' + routingNumber);
  }
}
