import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-update-plan',
    templateUrl: './update-plan.component.html'
})
export class UpdatePlanComponent {
    selectedPlanId: string;
  constructor(private _route: ActivatedRoute, private _router: Router) {
      this._route.params.subscribe(params => { this.selectedPlanId = params['id']; });
    }

    showAllPlans() {
        this._router.navigate(['/admin/plans']);
    }
}
