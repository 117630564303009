import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { SignaturePad } from 'angular2-signaturepad';

import { SignatureService } from 'src/app/services/signature.service';

@Component({
  selector: "app-signature-pad",
  templateUrl: './signature-pad.component.html',
  styleUrls: ['./signature-pad.component.css']
})
export class SignaturePadComponent {
  @ViewChild(SignaturePad, { static: true }) signaturePad: SignaturePad;


  private signaturePadOptions: Object = { // passed through to szimek/signature_pad constructor
   
  };
  constructor(private signatureService: SignatureService) {}
  ngAfterViewInit() {
    this.signaturePad.clear(); // invoke functions from szimek/signature_pad API
  }
  
  clear() {
    this.signaturePad.clear();
  }
  
  drawComplete() {
    this.signatureService.setSignatureData(this.signaturePad.toDataURL());
  }
}
