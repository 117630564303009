// edit-button-renderer.component.ts
import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PeriodScheduleComponent } from '../period-schedule/period-schedule.component';

@Component({
  selector: 'app-edit-button-renderer',
  template: `<button type="button" class="btn btn-primary btn-sm" (click)="openScheduleModal()">View</button>`,
})
export class EditButtonRendererComponent implements ICellRendererAngularComp {
  private params: any;

  constructor(private modalService: NgbModal) { }

  agInit(params: any): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }
  
  openScheduleModal(): void {
  
    const periodId = this.params.value;
    // Call the modal service to open the modal
    this.modalService.open(PeriodScheduleComponent, { size: 'xl' }).componentInstance.periodId = periodId;
  }
}
