import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { distinctUntilChanged } from 'rxjs/operators';
import { User } from 'src/app/models/User';
import { VideoService } from 'src/app/services/video.service';
import { MemberService } from 'src/app/services/member.service';
import { UserQuery } from '../../StateManagement/User/user.query';

@Component({
  templateUrl: 'welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {
  hraFilled = false;
  claimCompleted = false;
  claimStatusMessage: string;
  private _userDetails: User;
  defaultVideoId: string;
  isEligibleMedicalReceipts: boolean;

  canvasWidth: number = 500;
  needleValue: number = 0;
  centralLabel: string = '';
  name: string = 'TARGET MONTHLY CLAIM GOAL';
  bottomLabel: string = '0';
  options: any = {
    hasNeedle: true,
    needleColor: '#FFD700',
    needleUpdateSpeed: 500,
    rangeLabel: ['0', '100'],
    arcColors: ["green", "lightgray"],
    needleStartValue: 0,
    rangeLabelFontSize: 18,
    arcDelimiters: [0.001],
  }

  yearlyNeedleValue: number = 0;
  yearlyCentralLabel: string = '';
  yearlyName: string = 'TARGET ANNUAL CLAIM GOAL';
  yearlyBottomLabel: string = '0';
  yearlyOptions: any = {
    hasNeedle: true,
    needleColor: '#FFD700',
    needleUpdateSpeed: 500,
    rangeLabel: ['0', '100'],
    arcColors: ["green", "lightgray"],
    needleStartValue: 0,
    rangeLabelFontSize: 18,
    arcDelimiters: [0.001],
  }

  isLyrics: boolean = false;
  constructor(
    private _router: Router,
    private _videoService: VideoService,
    private _memberService: MemberService,
    private _userQuery: UserQuery) { }

  ngOnInit(): void {
    this._userQuery.getUserDetail().pipe(distinctUntilChanged()).subscribe(user => {
      if (!!user) {
        this._userDetails = user;
        this.hraFilled = user.isHraCompleted;
        this.claimCompleted = user.isClaimCompleted;
        this.claimStatusMessage = user.claimStatusMessage;
        this.isLyrics = user.isLyrics;
      }
    });

    this._videoService.getDefaultVideo().subscribe(video => this.defaultVideoId = video.referenceId);

    this._memberService.checkIsEligibleForMedicalReceipts().subscribe(data => {
      this.isEligibleMedicalReceipts = data.isEligibleMedicalReceipts;

      if (this.isEligibleMedicalReceipts) {
        this._memberService.getGraphDataForMember().subscribe(data => {
          this.name = 'TARGET MONTHLY CLAIM GOAL FOR ' + data.month + ' ' + data.year;
          this.yearlyName = 'TARGET ANNUAL CLAIM GOAL FOR ' + data.year;
          if (data.monthlyAmount > 0) {
            this.options.rangeLabel = ["$" + data.monthlyClaimAmount.toString(), "$" + data.monthlyAmount.toString()];
            this.bottomLabel = "";

            if (data.monthlyClaimAmount > 0) {

              var finalPercentageValue = ((data.monthlyClaimAmount * 100) / data.monthlyAmount);
              if (finalPercentageValue >= 100)
                finalPercentageValue = 99;

              this.needleValue = finalPercentageValue;
              this.options.arcDelimiters = [finalPercentageValue];
            }
          }

          if (data.yearlyAmount > 0) {
            this.yearlyOptions.rangeLabel = ["$" + data.yearlyClaimAmount.toString(), "$" + data.yearlyAmount.toString()];
            this.yearlyBottomLabel = "";
            if (data.yearlyClaimAmount > 0) {
              var finalPercentageValue = ((data.yearlyClaimAmount * 100) / data.yearlyAmount);

              if (finalPercentageValue >= 100)
                finalPercentageValue = 99;

              this.yearlyNeedleValue = finalPercentageValue;
              this.yearlyOptions.arcDelimiters = [finalPercentageValue];
            }
          }
        });
      }
    });
  }

  gotoQuestionnaire() {
    const route = `/hra-form/${this._userDetails.groupExternalId}/${this._userDetails.externalId}`;
    this._router.navigate([route]);
  }

  goToDefaultVideo() {
    this._router.navigate([`/videos/${this.defaultVideoId}`]);
  }

  goToCategories() {
    this._router.navigate(['/categories']);
  }

  goToRecipt() {
    this._router.navigate(['/medicalreceipt']);
  }

  goToViewRecipt() {
    this._router.navigate(['/medicalreceipt/view']);
  }
}
