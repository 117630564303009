import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ColDef } from 'ag-grid-community';
import { BrokerService } from 'src/app/services/broker.service';
import { GroupService } from 'src/app/services/group.service';
import { BtnCellRendererComponent } from 'src/app/shared/controls/renderers/button-renderer/btn.renderer';

@Component({
  selector: 'app-broker-group-members',
  templateUrl: './broker-group-members.component.html',
  styleUrls: ['./broker-group-members.component.css']
})
export class BrokerGroupMembersComponent {
  members;
  columnDefs: ColDef[] = [
    { hide: true, field: 'externalId' },
    { headerName: 'First Name', field: 'firstName', suppressMovable: true, sortable: true, filter: true },
    { headerName: 'Last Name', field: 'lastName', suppressMovable: true, sortable: true, filter: true },
  ];

  defaultColDef = {
    flex: 1,
    minWidth: 150,
    resizable: true,
  };

  private _groupExternalId: string;

  constructor(private _brokerservice: BrokerService, private _groupService: GroupService, private _route: ActivatedRoute, private _router: Router) {
    const _this = this;
    this._route.params.subscribe(params => {
      _this._groupExternalId = params['groupId'];
      this._brokerservice.getAllGroupMembers(_this._groupExternalId).subscribe(x => {
        this.members = x;
      });
    });

    this.columnDefs.push({
      headerName: '',
      field: 'externalId',
      cellRendererFramework: BtnCellRendererComponent,
      cellRendererParams: {
        showClickableBtn: true,
        btnText: 'Complete Engagement Questionnaire',
        clicked: function (boolean, referenceId: any) {
          _this._router.navigate([`/hra-form/${_this._groupExternalId}/${referenceId}`]);
        }
      },
    });
  }
}
