import { Component } from '@angular/core';
import { IndemnityBenefitService } from 'src/app/services/indemnityBenefit.service';
import { IndemnityBenefit } from 'src/app/models/indemnityBenefit';
import { ColDef } from 'ag-grid-community';
import { ActivatedRoute, Router } from '@angular/router';
import { BtnCellRendererComponent } from 'src/app/shared/controls/renderers/button-renderer/btn.renderer';

@Component({
    selector: 'app-indemnitybenefits',
    templateUrl: './indemnitybenefit-list.component.html'
})
export class IndemnityBenefitListComponent {
    selectedIndemnityBenefitId: number;
    addingIndemnityBenefit = false;
    indemnityBenefits: IndemnityBenefit[];
    columnDefs: ColDef[] = [];
    defaultColDef = {
        flex: 1,
        minWidth: 150,
        resizable: true,
    };

    private gridApi;

    constructor(private _indemnityBenefitService: IndemnityBenefitService, private _route: ActivatedRoute, private _router: Router) {
      this._route.params.subscribe(params => { this.selectedIndemnityBenefitId = params['indemnityBenefitId']; });
        if (this.selectedIndemnityBenefitId) {
            this.addingIndemnityBenefit = true;
        }
        const _this = this;
        this.getAllIndemnityBenefits();

        this.columnDefs = [
            { hide: true, field: 'id' },
            {
                headerName: 'Name', field: 'name', suppressMovable: true, sortable: true, filter: true
            },
            { headerName: 'Amount', field: 'amount', suppressMovable: true, sortable: true, filter: true, valueFormatter: params => "$" + params.data.amount },
            { headerName: 'Monthly Limit', field: 'monthlyLimit', suppressMovable: true, sortable: true, filter: true },
            { headerName: 'Active?', field: 'isActive', suppressMovable: true, sortable: true, filter: true },
            { headerName: 'Code', field: 'code', suppressMovable: true, sortable: true, filter: true },
            {
                headerName: '',
                field: 'id',
                suppressMovable: true,
                cellRendererFramework: BtnCellRendererComponent,
                cellRendererParams: {
                     //showDeleteBtn: true,
                    showEditBtn: true,
                    clicked: function (isDelete: boolean, id: any) {
                        if (isDelete) {
                          _this.deleteIndemnityBenefit(id);
                        } else {
                          _this._router.navigate([`/admin/indemnitybenefits/${id}`]);
                        }

                    }
                },
            }];
    }

    onGridReady(params) {
        this.gridApi = params.api;
        this.gridApi.setDomLayout("autoHeight");
    }

    getAllIndemnityBenefits() {
        this._indemnityBenefitService.getAllIndemnityBenefits().subscribe(indemnityBenefits => {
          this.indemnityBenefits = indemnityBenefits;
        });
    }

    deleteIndemnityBenefit(id: number) {
        this._indemnityBenefitService.deleteIndemnityBenefit(id).subscribe(x => {
            this.indemnityBenefits = this.indemnityBenefits.filter(g => g.id !== id);
            this.gridApi.setRowData(this.indemnityBenefits);
        });
    }

    addIndemnityBenefit() {
        this.addingIndemnityBenefit = true;
    }

    showAllIndemnityBenefits() {
        this.getAllIndemnityBenefits();
        this.addingIndemnityBenefit = false;
    }
}
