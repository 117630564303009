import { NgModule } from '@angular/core';

import { BrokerRoutingModule } from './broker-routing.module';
import { BrokerGroupMembersComponent } from './broker-group-members/broker-group-members.component';
import { BrokerGroupsComponent } from './broker-groups/broker-groups.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { BtnCellRendererComponent } from 'src/app/shared/controls/renderers/button-renderer/btn.renderer';
import { AgGridModule } from 'ag-grid-angular';

@NgModule({
    declarations: [
        BrokerGroupsComponent,
        BrokerGroupMembersComponent
    ],
    imports: [
        SharedModule,
        // BrokerRoutingModule,
        AgGridModule.withComponents([BtnCellRendererComponent])
    ],
})
export class BrokerModule { }
