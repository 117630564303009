import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ColDef } from 'ag-grid-community';
import { MemberService } from 'src/app/services/member.service';
import { Member } from '../../../../models/member';
import { GroupNameRendererComponent } from '../../groups/group-list/group-name-renderer/group-name-renderer';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { FormatDate } from '../../../../helpers/format';

@Component({
  selector: 'app-members-without-claims-list',
  templateUrl: './members-without-claims-list.component.html',
  styleUrls: ['./members-without-claims-list.component.scss']
})
export class MembersWithOutClaimsListComponent {

  members: Member[];
  yearOptions = [];
  monthOptions = [
    { key: 'Jan', value: 1 },
    { key: 'Feb', value: 2 },
    { key: 'Mar', value: 3 },
    { key: 'Apr', value: 4 },
    { key: 'May', value: 5 },
    { key: 'Jun', value: 6 },
    { key: 'Jul', value: 7 },
    { key: 'Aug', value: 8 },
    { key: 'Sep', value: 9 },
    { key: 'Oct', value: 10 },
    { key: 'Nov', value: 11 },
    { key: 'Dec', value: 12 }]

  filterParams = {
    comparator: function (filterLocalDateAtMidnight, cellValue) {
      if (cellValue == null) { return -1; }
      const dateAsString = cellValue.substr(0, 10);
      const dateParts = dateAsString.split('-');
      const cellDate = new Date(
        Number(dateParts[0]),
        Number(dateParts[1]) - 1,
        Number(dateParts[2])
      );
      if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
        return 0;
      }
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      }
      if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
    },
    browserDatePicker: true,
  };

  defaultColDef = {
    flex: 1,
    minWidth: 150,
    resizable: true,
  };
  columnDefs: ColDef[] = [];
  

  filterClaimForm: FormGroup;
  submitted = false;
  loading = false;
  private _gridApi;

  constructor(private _formBuilder: FormBuilder, private _memberService: MemberService, private _date: FormatDate, private _router: Router) {
    const date = new Date();
    const _this = this;
    for (let i = 2020; i <= date.getFullYear(); i++) {
      this.yearOptions.push(i.toString());
    }

    this.filterClaimForm = this._formBuilder.group({
      claimMonth: [date.getMonth() + 1],
      claimYear: [date.getFullYear()]
    });
    this.columnDefs = [
      { hide: true, field: 'referenceId' },
      {
        headerName: "Group Id",
        field: "groupExternalId",
        suppressMovable: true,
        sortable: true,
        filter: true,
        cellRendererFramework: GroupNameRendererComponent,
        cellRendererParams: {
          clicked: function (params: any) {
            _this._router.navigate([
              `/admin/group-view/${params.data.groupReferenceId}`,
            ]);
          },
        },
      },
      {
        headerName: 'Member Id', field: 'externalId', suppressMovable: true, sortable: true, filter: true,
        cellRendererFramework: GroupNameRendererComponent,
        cellRendererParams: {
          clicked: function (params: any) {
            _this._router.navigate([
              `/admin/members/${params.data.externalId}`,
            ]);
          },
        },
      },
      { headerName: 'First Name', field: 'firstName', suppressMovable: true, sortable: true, filter: true },
      { headerName: 'Last Name', field: 'lastName', suppressMovable: true, sortable: true, filter: true },
      {
        headerName: 'Date of Birth', field: 'dob', suppressMovable: true, sortable: true, filter: 'agDateColumnFilter',
        filterParams: this.filterParams, valueFormatter: function (params) {
          return !!params.value ? _date.formatDate(params.value.substr(0, 10)) : "";
        },
      },
      {
        headerName: 'Start Date', field: 'startDate', suppressMovable: true, sortable: true, filter: 'agDateColumnFilter',
        filterParams: this.filterParams, valueFormatter: function (params) {
          return !!params.value ? _date.formatDate(params.value.substr(0, 10)) : "";
        },
      },
      { headerName: "Plan Name", field: "planName", sortable: true, filter: true },
    ];
    this._getMembersWithoutClaims();
  }

  onGridReady(params) {
    this._gridApi = params.api;
  }

  DownloadMembers() {
    this._gridApi.exportDataAsCsv();
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.filterClaimForm.invalid) {
      return;
    }

    this.loading = true;
    this._getMembersWithoutClaims();
  }

  private _getMembersWithoutClaims() {
    const claimMonth = this.filterClaimForm.controls['claimMonth'].value;
    const claimYear = this.filterClaimForm.controls['claimYear'].value;
    this._memberService.getAllMembersWithoutClaim(claimMonth, claimYear).subscribe(members => {
      this.members = members;
      console.log(members);
      this.loading = false;
      if (this._gridApi) {
        this._gridApi.hideOverlay();
        if (this.members.length == 0)
          this._gridApi.showNoRowsOverlay();
      }
    });
  }
 
  public overlayNoRowsTemplate =
    '<span style="padding: 10px; border: 1px solid #ddd; background: lightgoldenrodyellow;">No rows found</span>';
}


