import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { IAfterGuiAttachedParams } from 'ag-grid-community';
import { ActivatedRoute, Router, UrlSerializer } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { PopupComponent } from 'src/app/controls/pop-up/popup/popup.component';

@Component({
  selector: 'app-btn-cell-renderer',
  templateUrl: './btn.renderer.html',
  styleUrls: ["./btn.renderer.scss"],


})



export class BtnCellRendererComponent implements ICellRendererAngularComp {

  constructor(private _ActivatedRoute: ActivatedRoute,private router: Router, private urlSerializer: UrlSerializer, private dialog: MatDialog) { }
  showModal = false;
  params: any;
  baseUrl: string = '';
  successMessage = '';
  refresh(params: any): boolean {
    return true;
  }

  afterGuiAttached?(params?: IAfterGuiAttachedParams,): void {

  }

  agInit(params: any): void {
    this.params = params;
  }

  btnClickedHandler(isDelete: boolean, data: any, isDownload: boolean) {
    this.params.clicked(isDelete, this.params.value, isDownload);
  }
  OnGroupView() {
    let groupid = this.params.data.referenceId;
    this.router.navigate(['/admin/group-view/' + groupid]);
    
  }

  // copy data on button click
  copyData() {
    this.successMessage = '';
    this.baseUrl = this.getBaseUrl();
    const routeUrlTree = this.router.createUrlTree(['/plans/selection'], {
      queryParams: { groupid: this.params.data.externalId },
    });
    const urlString = this.urlSerializer.serialize(routeUrlTree);
    this.copyTextToClipboard(this.baseUrl + urlString);
    this.successMessage = 'Link copied successfully.';
    // alert(this.successMessage)
    this.dialog.open(PopupComponent, {
      data: this.successMessage,
      panelClass: 'custom-dialog-container'
    });
  }

  // copy data 
  private copyTextToClipboard(text: string) {
    const dummyInput = document.createElement('input');
    dummyInput.style.position = 'fixed';
    dummyInput.style.opacity = '0';
    dummyInput.value = text;
    document.body.appendChild(dummyInput);

    dummyInput.select();
    document.execCommand('copy');
    document.body.removeChild(dummyInput);
  }

  // get dynamic url
  private getBaseUrl(): string {
    const protocol = window.location.protocol;
    const hostname = window.location.hostname;
    const port = window.location.port;
    // Use the detected protocol, hostname, and port to form the base URL
    return `${protocol}//${hostname}${port ? ':' + port : ''}`;
  }

  openPopup(): void {
    this.showModal = true;
  }

  closePopup(): void {
    this.showModal = false;
  }

  OnRedirectToPlan() {
    let groupId = "";
    this._ActivatedRoute.queryParamMap.subscribe((param) => {
      groupId = param.get('groupid');
    });
    this.router.navigate(['enrollment/enroll'], {
      queryParams: {
        groupid: groupId,
        planid: this.params.data.id        
      }
    })
  }
  RedirectToClaim() {
    let groupId = this.params.data.id;
    this.router.navigate(['admin/claim-payment-view/' + groupId])
  }
  RedirectToClaimItem() {
    let groupId = this.params.data.referenceId;
    this.router.navigate(['admin/claim-payment-list/' + groupId])
  }
  OnRedirectToPeriod() {
    let periodid = this.params.data.id;
    this.router.navigate(['admin/period/'+ periodid +'/period-schedule' ])
  }
  OnRedirectToPeriodSchedule() {
    let periodScheduleId = this.params.data.id;
    this.router.navigate(['admin/periodSchedule-plan/' + periodScheduleId])
  }
  RedirectToEditSchedulePlan() {
    let SchedulePlanId = this.params.data.id;
    this.router.navigate(['admin/period-schedule-plan/' + SchedulePlanId])
  }
  OnRedirectToMember() {
    let referenceId = this.params.data.referenceId;   
    this.router.navigate(['admin/groups/' + referenceId + '/members'])
  }
  OnRedirectToInvoice() {
    let invoiceId = this.params.data.id;
    this.router.navigate(['admin/invoice-view/' + invoiceId])
  }
  OnRedirectToMemberEnroll() {
    let memberId = 0;
    this._ActivatedRoute.queryParamMap.subscribe((param) => {
      memberId = Number(param.get('memberid'));
    });
    this.router.navigate(['admin/enroll/member'], {
      queryParams: {
        memberid: memberId
      }
    })
  }

  OnRedirectToLeadList() {
    let groupid = this.params.data.externalId;  
    this.router.navigate(['admin/groups/'+groupid+'/lead-list'])
  }
  OnRedirectToEnroll(){

    this.successMessage = '';
    this.baseUrl = this.getBaseUrl();
    const routeUrlTree = this.router.createUrlTree(['/enrollment/enroll'], {
      queryParams: { leadid: this.params.data.leadId },
    });
    const urlString = this.urlSerializer.serialize(routeUrlTree);
    this.copyTextToClipboard(this.baseUrl + urlString);
    this.successMessage = 'Link copied successfully.';
    // alert(this.successMessage)
    this.dialog.open(PopupComponent, {
      data: this.successMessage,
      panelClass: 'custom-dialog-container'
    });
    // this.router.navigate(['enrollment/enroll'], {
    //   queryParams: {
    //     leadid: this.params.data.leadId
    //   }
    // });
  }

  OnRedirectToInvoiceForm(){
    let invoiceId = this.params.data.id;
    this.router.navigate([]).then(result => {  window.open(`/admin/invoice-detail/${invoiceId}`, '_blank'); });
  }

}
