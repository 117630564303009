import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HMALeads } from '../models/HMALeads';
import { Observable } from 'rxjs';
import { getApiUrl } from '../helpers/get-url';

@Injectable({
  providedIn: 'root'
})
export class HMALeadService {

  constructor(private http: HttpClient) { }

  addLead(group:HMALeads): Observable<HMALeads> {
    return this.http.post<HMALeads>(getApiUrl("addlead/AddLead"),group);
  }

  getLead(email:any): Observable<any> {
    return this.http.get<any>(getApiUrl('addlead/GetLead'+email));
  }

  updateLead(group:any): Observable<any> {
    return this.http.put<any>(getApiUrl('addlead/UpdateLead'),group);
  }
}
