import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { first } from 'rxjs/operators';
import { WellnessProgressService } from 'src/app/services/wellnessProgressService.service';
import { WellnessProgressReviewStatus } from 'src/app/models/enums/wellnessProgressReviewStatus';
import { IndemnityBenefitService } from 'src/app/services/indemnityBenefit.service';
import { IndemnityBenefit } from 'src/app/models/indemnityBenefit';
import { NgbAlertConfig } from '@ng-bootstrap/ng-bootstrap';
import { ColDef } from 'ag-grid-community';
import { FormatDate } from '../../../../helpers/format';

@Component({
  selector: 'add-medicalreceipt',
  templateUrl: './add-medicalreceipt.component.html',
  providers: [NgbAlertConfig]
})
export class AddMedicalReceiptComponent implements OnInit {
    @Input()
    medicalReceiptId: number;
    medicalReceiptForm: FormGroup;
    indemnityBenefits: IndemnityBenefit[];
    wellnessProgressReviewStatusKeys: any;
    filename: string;
    loading = false;
    submitted = false;
    errorMessage: string[] = [];
    successMessage = '';
    amount: number = 0;
    columnDefs: ColDef[] = [];
    defaultColDef = {
      flex: 1,
      minWidth: 150,
      resizable: true,
    };

    private gridApi;
    private WellnessProgressReviewStatus = WellnessProgressReviewStatus;

    constructor(private _route: ActivatedRoute,
      private _router: Router,
      private _formBuilder: FormBuilder,
      private _wellnessProgressService: WellnessProgressService,
      private _indemnityBenefitService: IndemnityBenefitService,
      private _date: FormatDate,
      private alertConfig: NgbAlertConfig
    )
    {
      this._route.params.subscribe(params => { this.medicalReceiptId = params['medicalReceiptId']; });
      alertConfig.type = 'success';

      this.columnDefs = [
        { hide: true, field: 'id' },
        { headerName: 'Name', field: 'name', checkboxSelection: true, suppressMovable: true, sortable: true, filter: true, autoHeight: true,flex:10 },
        //{
        //  headerName: 'Name', field: 'name', sortable: true, filter: true
        //},
        { headerName: 'Amount', field: 'amount', suppressMovable: true, sortable: true, filter: true, autoHeight: true,  valueFormatter: params => "$" + params.data.amount },
      ];
    }

  ngOnInit() {
    this.wellnessProgressReviewStatusKeys = Object.keys(WellnessProgressReviewStatus).filter(Number);
    //this.getActiveIndemnityBenefits();
    this.medicalReceiptForm = this._formBuilder.group({
      type:[1],
      serviceDate: ['', [Validators.required]],
      providerName: ['', [Validators.required]],
      reviewStatus:[],
      comments: [''],
      indemnityBenefitId: [null, Validators.required],
      receipt: [null],
      patientName: ['', [Validators.required]],
      patientBirthDate: ['', [Validators.required]]
    });

     if (!!this.medicalReceiptId) {
         this._wellnessProgressService.getWellnessProgress(this.medicalReceiptId).subscribe(medicalReceipt => {
           this.medicalReceiptForm.patchValue(medicalReceipt);
           this.medicalReceiptForm.controls['serviceDate'].patchValue(this._date.formatDate(medicalReceipt.serviceDate));
           this.medicalReceiptForm.controls['reviewStatus'].patchValue(WellnessProgressReviewStatus[medicalReceipt.reviewStatus]);
         });
    }
    
  }

  getActiveIndemnityBenefits() {
    this._indemnityBenefitService.getActiveIndemnityBenefits().subscribe(indemnityBenefits => {
      this.indemnityBenefits = indemnityBenefits;
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.medicalReceiptForm.controls; }


  onOptionsSelected(e) {
    var selectedIndex = parseInt(e.target.value);
    this.amount = this.indemnityBenefits[selectedIndex].amount;
  }

  //setFilename(files) {
  //  if (files[0]) {
  //    this.filename = files[0].name;
  //  }
  //}

  uploadFile(event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.medicalReceiptForm.patchValue({
      receipt: file
    });
    this.medicalReceiptForm.get('receipt').updateValueAndValidity();
  }


  onSubmit() {
    this.submitted = true;

    // reset alerts on submit
    this.successMessage = '';
    this.errorMessage = [];

    // stop here if form is invalid
    if (this.medicalReceiptForm.invalid) {
      return;
    }

    //if (files[0]) {
    //  this.medicalReceiptForm.append(files[0].name, files[0]);
    //}

    this.loading = true;
    const medicalReceiptId = this.medicalReceiptId;
    // delete this.groupForm.value['broker'];
      this._wellnessProgressService.addMedicalReceipt(this.medicalReceiptForm.value)
        .pipe(first())
        .subscribe(
          () => {
            var _this = this;
            // return to list
            this.successMessage = 'Medical Receipt added successfully.';
            this.loading = false;
            this.errorMessage = [];
            setTimeout(function () { _this._router.navigate(['/medicalreceipt/view']); }, 2000);
            
          },
          error => {
            if (error.error instanceof Array) {
              error.error.map(err => this.errorMessage.push(err.value));
            } else if (typeof error.error === 'string') {
              this.errorMessage.push(error.error);
            } else {
              this.errorMessage.push(error);
            }
            this.loading = false;
          }); 
  }

  goToViewRecipt() {
    this._router.navigate(['/medicalreceipt/view']);
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.getActiveIndemnityBenefits();
    //this.gridApi.setDomLayout("autoHeight");
    //this.gridApi.resetRowHeights();
  }

  onRowSelectedEvent(params) {
    if (params.node.isSelected()) {
      this.medicalReceiptForm.controls['indemnityBenefitId'].patchValue(params.data.id);
    }
    
  }
}
