import { Injectable } from '@angular/core';
import * as alertify from 'alertifyjs';

declare let alertify: any;
let errorWithTime: any;
@Injectable({
  providedIn: 'root',
})
export class AlertifyService {
  constructor() {
    alertify.set('notifier', 'position', 'top-right');
  }

  confirm(message: string, okCallback: () => any) {
    alertify
      .confirm(message, function (e: any) {
        if (e) {
          okCallback();
        } else {
        }
      })
      .setHeader('<em> Confirm </em> ');
  }

  customConfirm(message: string, okText: string, cancelText: string, okCallback: () => any) {
    alertify
      .confirm(message, function (e: any) {
        if (e) {
          okCallback();
        } else {
        }
      })
      .setHeader('<em> Confirm </em> ')
      .set('labels', { ok: okText, cancel: cancelText });
  }

  success(message: string) {
    alertify.success(message);
  }

  error(message: string) {
    alertify.error(message);
  }

  errorWithTime(message: string, time: number) {
    errorWithTime = alertify.error(message, time);
  }

  warning(message: string) {
    alertify.warning(message);
  }

  message(message: string) {
    alertify.message(message);
  }

  dismiss() {
    if (errorWithTime) {
      errorWithTime.dismiss();
    }
  }
}
