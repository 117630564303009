import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AlertService } from 'src/app/services/alertService.service';
import { FormatDate } from '../../../../../helpers/format';
import { ValidatorsService } from '../../../../../helpers/validators.service';
import { AlertifyService } from '../../../../../services/alertify.service';
import { PeriodScheduleService } from '../../../../../services/periodSchedule.service';


@Component({
  selector: 'app-edit-period',
  templateUrl: './edit-period-schedule.component.html',
  styleUrls: ['./edit-period-schedule.component.scss']
})
export class EditPeriodScheduleComponent implements OnInit {

  periodScheduleForm: FormGroup;
  loading = false;
  submitted = false;
  errorMessage: string[] = [];
  @Input() periodScheduleId: string;
  get f() { return this.periodScheduleForm.controls; }

  constructor(private _formBuilder: FormBuilder, private _route: ActivatedRoute, private _datepipe: DatePipe,
    private _periodScheduleService: PeriodScheduleService, private _router: Router,
    private _alertify: AlertifyService, private validatorsService: ValidatorsService) {
    this._route.params.subscribe(params => { this.periodScheduleId = params['id']; });
  }

  ngOnInit() {
    this.periodScheduleForm = this._formBuilder.group({
      id: 0,
      periodId: [''],
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      invoiceDate: ['', Validators.required],
      invoiceDueDate: ['', Validators.required],
      claimPaymentDate: ['', Validators.required],
      claimStartDate: ['', Validators.required],
      claimEndDate: ['', Validators.required],
      premiumPercent: [0, [Validators.required, this.validatorsService.nonNegativeValidator()]],
      claimPercent: [0, [Validators.required, this.validatorsService.nonNegativeValidator()]],
    });
    if (!!this.periodScheduleId) {
      
      this._periodScheduleService.getScheduleById(this.periodScheduleId).subscribe(schedule => {
       
        this.periodScheduleForm.patchValue(schedule);
        this.periodScheduleForm.controls["startDate"].patchValue(
          this._getDate(schedule.startDate)
        );
      this.periodScheduleForm.controls["endDate"].patchValue(
        this._getDate(schedule.endDate)
        );
        this.periodScheduleForm.controls["invoiceDate"].patchValue(
          this._getDate(schedule.invoiceDate)
        );
        this.periodScheduleForm.controls["invoiceDueDate"].patchValue(
          this._getDate(schedule.invoiceDueDate)
        );

        this.periodScheduleForm.controls["claimPaymentDate"].patchValue(
          this._getDate(schedule.claimPaymentDate)
        );
        this.periodScheduleForm.controls["claimStartDate"].patchValue(
          this._getDate(schedule.claimStartDate)
        );
        this.periodScheduleForm.controls["claimEndDate"].patchValue(
          this._getDate(schedule.claimEndDate)
        );
      });

    }

  }
  
  onSubmit() {
    this.submitted = true;
    
    // reset alerts on submit
    this.errorMessage = [];
    
    // stop here if form is invalid
    if (this.periodScheduleForm.invalid) {
      return;
    }

    this.loading = true;
    //this.periodScheduleForm.controls.periodId.setValue(+this.periodScheduleForm.value.periodId);
    this.periodScheduleForm.controls.premiumPercent.setValue(+this.periodScheduleForm.value.premiumPercent);
    this.periodScheduleForm.controls.claimPercent.setValue(+this.periodScheduleForm.value.claimPercent);
    if (!!this.periodScheduleId) {
      this._periodScheduleService.updatePeriodSchedule(this.periodScheduleId, this.periodScheduleForm.value)
        .pipe(first())
        .subscribe(
          () => {
            this.loading = false;
            this._alertify.success('Period Schedule updated successfully.');
            this._router.navigate(['/admin/period/' + this.periodScheduleForm.value.periodId +'/period-schedule']);
           
          },
          error => {
            if (error.error instanceof Array) {
              error.error.map(err => this.errorMessage.push(err.value));
            } else if (typeof error.error === 'string') {
              this.errorMessage.push(error.error);
            } else {
              this.errorMessage.push(error);
            }
            this.loading = false;
          });
    } else {
      
    }
  }
  private _getDate(date: Date): string {
    return this._datepipe.transform(date, "yyyy-MM-dd");
  }

}
