import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-popupcomponent',
  templateUrl: './popupcomponent.component.html',
  styleUrls: ['./popupcomponent.component.css']
})
export class PopupcomponentComponent implements OnInit {

  constructor(
    ) { }
  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }

  

 
}