import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ColDef } from 'ag-grid-community';
import { FormatDate } from '../../../../helpers/format';
import { Period } from '../../../../models/period';
import { PeriodSchedule } from '../../../../models/periodschedule';
import { PeriodService } from '../../../../services/period.service';
import { PeriodScheduleService } from '../../../../services/periodSchedule.service';
import { BtnCellRendererComponent } from '../../../../shared/controls/renderers/button-renderer/btn.renderer';


@Component({
  selector: 'app-period-schedule',
  templateUrl: './period-schedule.component.html',
  styleUrls: ['./period-schedule.component.scss']
})

export class PeriodScheduleComponent implements OnInit {
  periodSchedule: PeriodSchedule[];
  period: Period;
  periodId: string;
  columnDefs: ColDef[] = [];
  defaultColDef = {
    flex: 1,
    minWidth: 100,
    resizable: true,
  };
  constructor(private _periodScheduleService: PeriodScheduleService,
    private _date: FormatDate, private _periodService: PeriodService,
    private _route: ActivatedRoute, private _router: Router) {
    
    this._route.params.subscribe(params => { this.periodId = params['periodid']; });
  
   
    const _this = this;
    this.columnDefs = [
      { headerName: 'Id', field: 'id', suppressMovable: true, sortable: true, filter: true },
      {
        headerName: 'Start Date', field: 'startDate', suppressMovable: true, sortable: true, filter: true, valueFormatter: function (params) {
          return !!params.value ? _date.formatDate(params.value.substr(0, 10)) : '';
        }
      },
      {
        headerName: 'End Date', field: 'endDate', suppressMovable: true, sortable: true, filter: true, valueFormatter: function (params) {
          return !!params.value ? _date.formatDate(params.value.substr(0, 10)) : '';
        }
      },
      { headerName: 'Claim Percent', field: 'claimPercent', suppressMovable: true, sortable: true, filter: true },
      { headerName: 'Premium Percent', field: 'premiumPercent', suppressMovable: true, sortable: true, filter: true },];

    this.columnDefs.push({
      headerName: "",
      field: "id",
      suppressMovable: true,
      cellRendererFramework: BtnCellRendererComponent,
      cellRendererParams: {
        showPeriodSchedule:true,
        showEditBtn: true,
        clicked: function (isDelete: boolean, id: any) {
          if (isDelete) {
            // _this.deletePlan(id);
          } else {
            
            _this._router.navigate([`/admin/periodschedule/${id}`]);

          }
        },
      },
    });
  }
  private _gridApi;

  ngOnInit() {
    this._getAllSchedule(this.periodId);
    this.getPeriodById(this.periodId);
  }

  onGridReady(params) {
    this._gridApi = params.api;
  }

  showAllBrokers() {
    this._getAllSchedule(this.periodId);
    //this.addingPeriod = false;
  }
  getPeriodById(periodId:string) {
    this._periodService.getPeriodById(periodId).subscribe(data => {      
      this.period = data;
    });
  }
  private _getAllSchedule(periodid) {
    
    this._periodScheduleService.getPeriodScheduleById(periodid).subscribe(data => {      
      this.periodSchedule = data;
      if (this.periodSchedule.length == 0)
        this._gridApi.showNoRowsOverlay();
    });
  }

  public overlayNoRowsTemplate =
    '<span style="padding: 10px; border: 1px solid #ddd; background: lightgoldenrodyellow;">No rows found</span>';

}

