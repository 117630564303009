import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { UserToken } from '../models/UserToken';
import { getApiUrl } from '../helpers/get-url';
import { User } from '../models/User';
import { JWTTokenService } from './jwt-token.service';
import { UserStore } from '../controls/StateManagement/User/user.store';
import { UserService } from './user.service';


@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  public currentUser: Observable<UserToken>;

  private readonly currentUserSubject: BehaviorSubject<UserToken>;

  constructor(private http: HttpClient, private _userService: UserService, private store: UserStore, private _jwtService: JWTTokenService) {
    this.currentUserSubject = new BehaviorSubject<UserToken>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): UserToken {
    return this.currentUserSubject.value;
  }

  public get isAgent(): boolean {
    return this.getUserRoles().includes('Broker');
  }

  public get isAdmin(): boolean {
    const roles = this.getUserRoles();
    return roles.includes('Admin') || roles.includes('SuperAdmin');
  }

  public get isMember(): boolean {
    const roles = this.getUserRoles();
    return roles.includes('Member') || roles.length === 0;
  }

  getUserRoles(): string[] {
    if (this.currentUserValue) {
      this._jwtService.setToken(this.currentUserValue.token);
      return this._jwtService.getUserRoles();
    }
    return [];
  }

  login(username, password) {
    return this.http.post<any>(getApiUrl('user/login'), { username, password })
      .pipe(map(user => {
        this._setCurrentUser(user);
        return user;
      }));
  }

  register(user: User) {
    return this.http.post<UserToken>(getApiUrl('user/register'), user)
      .pipe(map(userWithToken => {
        this._setCurrentUser(userWithToken);
        return user;
      }));
  }

  resetPassword(value: any) {
    return this.http.post<UserToken>(getApiUrl('user/reset-password'), value).pipe(map(userWithToken => {
      this._setCurrentUser(userWithToken);
    }));
  }

  setPassword(value: any) {
    return this.http.post<UserToken>(getApiUrl('user/set-password'), value).pipe(map(userWithToken => {
      this._setCurrentUser(userWithToken);
    }));
  }

  logout() {
    // remove user from local storage and set current user to null
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
    this.store.reset();
  }

  private _setCurrentUser(userWithToken: UserToken) {
    // store user details and jwt token in local storage to keep user logged in between page refreshes
    const token = new UserToken(userWithToken.token);
    localStorage.setItem('currentUser', JSON.stringify(token));
    this.currentUserSubject.next(token);
    this._userService.getUserDetails().subscribe();
  }
}
