import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GroupService } from 'src/app/services/group.service';

@Component({
  selector: 'app-completed-link',
  templateUrl: './completed-link.component.html',
  styleUrls: ['./completed-link.component.css']
})
export class CompletedLinkComponent implements OnInit {

  constructor(private _ActivatedRoute: ActivatedRoute, private router: Router, private _groupService: GroupService) { }

  groupId: string;
  memberId: string;
  groupName: string;
  ngOnInit() {
    this._ActivatedRoute.queryParamMap.subscribe((param) => {
      this.groupId = param.get('groupid');
      this.memberId = param.get('memberid');
    }
    );
    this.getGroupInfo();
  }

  clickhere() {
    this.router.navigate([`/hra-form/${this.groupId}/${this.memberId}`]);
  }

  getGroupInfo() {
    this._groupService.getGroupNameByExternalID(this.groupId).subscribe((res:any)=>{
      this.groupName = res == null? '': res.value;
    });
  }



}
