import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AlertifyService } from 'src/app/services/alertify.service';
import { VideoService } from 'src/app/services/video.service';
import { ValidatorsService } from '../../../../helpers/validators.service';

@Component({
    selector: 'app-add-video',
    templateUrl: './add-edit.component.html'
})
export class AddEditVideoComponent implements OnInit {

    @Input()
    videoId: string;

    videoForm: FormGroup;
    loading = false;
    submitted = false;
    successMessage = '';
    errorMessage: string[] = [];

    constructor(
        private formBuilder: FormBuilder,
        private videoService: VideoService,
      private alertify: AlertifyService, private validatorsService: ValidatorsService,
        private router: Router) { }

    ngOnInit() {
        // referenceId: string;
        this.videoForm = this.formBuilder.group({
            url: ['', Validators.required],
            title: ['', Validators.required],
            thumbnail: ['', Validators.required],
          durationInSeconds: ['', [Validators.required, this.validatorsService.nonNegativeValidator()]],
            code: ['', Validators.required],
            description: ['', Validators.required],
            extraInfo: [''],
            videoCategories: ['']
        });

        if (!!this.videoId) {
            this.videoService.getVideoById(this.videoId).subscribe(video => {
                this.videoForm.patchValue(video);
            });
        }
    }
  
    // convenience getter for easy access to form fields
    get f() { return this.videoForm.controls; }

    onSubmit() {
        this.submitted = true;

        // reset alerts on submit
        this.errorMessage = [];
        this.successMessage = '';

        // stop here if form is invalid
        if (this.videoForm.invalid) {
            return;
        }

        this.loading = true;
        const videoRefrenceId = this.videoId;
        console.log(this.videoForm.value);
        if (!!videoRefrenceId) {
            this.videoService.updateVideo(videoRefrenceId, this.videoForm.value)
                .pipe(first())
                .subscribe(
                    () => {
                        // return to list
                        //this.successMessage = 'Video updated successfully.';
                        this.loading = false;
                        this.alertify.success('Video updated successfully.');
                        this.router.navigate(['/admin/videos']);
                    },
                    error => {
                        if (error.error instanceof Array) {
                            error.error.map(err => this.alertify.error(err.value)); //this.addUpdateError.push
                        } else if (typeof error.error === 'string') {
                            //this.addUpdateError.push(error.error);
                            this.alertify.error(error.error)
                        } else {
                            //this.addUpdateError.push(error);
                            this.alertify.error(error);
                        }
                        this.loading = false;
                    });
        } else {
            this.videoService.addVideo(this.videoForm.value)
                .pipe(first())
                .subscribe(
                    (video) => {
                        // return to list
                        //this.successMessage = 'Video added successfully.';
                        this.loading = false;
                        this.videoId = video.referenceId;
                        this.alertify.success('Video added successfully.');
                        window.location.reload();
                    },
                    error => {
                        if (error.error instanceof Array) {
                            error.error.map(err => this.alertify.error(err.value)); //this.addUpdateError.push
                        } else if (typeof error.error === 'string') {
                            //this.addUpdateError.push(error.error);
                            this.alertify.error(error.error)
                        } else {
                            //this.addUpdateError.push(error);
                            this.alertify.error(error);
                        }
                        this.loading = false;
                    });
        }
    }
}


