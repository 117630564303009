import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      
      if (err.status === 401) {
        // auto logout if 401 response returned from api
        this.authenticationService.logout();
        document.location.href = '/';
      }

      if(!!err && !!err.error && !!err.error.Message){
        return throwError(err.error.Message);
      }

      return throwError(err);
      
    }));
  }
}
