import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon'
import { BtnCellRendererComponent } from 'src/app/shared/controls/renderers/button-renderer/btn.renderer';
import { AgGridModule } from 'ag-grid-angular';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { RecaptchaModule } from 'ng-recaptcha';
import { SuccessMessageDialogComponent } from './controls/successAlert/success-message-dialog/success-message-dialog.component';
import { CaptchaComponent } from './controls/captcha/captcha.component';
import { PopupcomponentComponent } from './controls/popupcomponent/popupcomponent.component';

@NgModule({
  declarations: [
    BtnCellRendererComponent,
    SuccessMessageDialogComponent,
    CaptchaComponent, PopupcomponentComponent
  ],
  imports: [
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    BrowserModule,
    CommonModule,
    AgGridModule.withComponents([BtnCellRendererComponent]),
    RecaptchaModule.forRoot()
  ],
  exports: [
    RecaptchaModule
  ]
})
export class SharedModule { }
