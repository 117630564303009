import { Component } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  title = 'app';

  showHead: boolean = false;

  ngOnInit() {
  }

  constructor(private router: Router) {
    // on route change to '/login', set the variable showHead to false
    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {        
        if (event['url'].includes('/admin/enroll/member/') ||event['url'].includes('/admin/invoice-detail/')) {          
          this.showHead = false;
        } else {
          this.showHead = true;
        }
      }
    });
  }
}
