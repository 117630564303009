import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ColDef } from 'ag-grid-community';
import { PeriodSchedulePlan } from '../../../../models/periodschedule';
import { PeriodScheduleService } from '../../../../services/periodSchedule.service';
import { BtnCellRendererComponent } from '../../../../shared/controls/renderers/button-renderer/btn.renderer';


@Component({
  selector: 'app-period-schedulePlan',
  templateUrl: './period-schedule-plan.component.html',
  styleUrls: ['./period-schedule-plan.component.scss']
})

export class PeriodSchedulePlanComponent implements OnInit {
  periodSchedulePlan: PeriodSchedulePlan[];
 
  periodScheduleId: number;
  columnDefs: ColDef[] = [];
  defaultColDef = {
    flex: 1,
    minWidth: 100,
    resizable: true,
  };
  constructor(private _periodScheduleService: PeriodScheduleService,
    private _route: ActivatedRoute) {
    
    this._route.params.subscribe(params => { this.periodScheduleId = params['id']; });
  
   
    const _this = this;
    this.columnDefs = [
      { headerName: 'Id', field: 'id', suppressMovable: true, sortable: true, filter: true },      
      {
        headerName: 'Plan Name', field: 'planName', suppressMovable: true, sortable: true, filter: true
      },
      { headerName: 'Claim Amount', field: 'claimAmount', suppressMovable: true, sortable: true, filter: true },
      { headerName: 'Premium Amount', field: 'premiumAmount', suppressMovable: true, sortable: true, filter: true }, {
        headerName: '',
        field: "id",
        suppressMovable: true,
        cellRendererFramework: BtnCellRendererComponent,
        cellRendererParams: {
          showEditPeriodSchedule: true
        },
      }];

   
  }
  private _gridApi;

  ngOnInit() {
    this.getPeriodById(this.periodScheduleId);
  }

  onGridReady(params) {
    this._gridApi = params.api;
  }

  
  getPeriodById(periodScheduleId: number) {
    
    this._periodScheduleService.getAllPeriodSchedulePlanById(Number(periodScheduleId)).subscribe(data => {      
      this.periodSchedulePlan = data;
    });
  }
 

  public overlayNoRowsTemplate =
    '<span style="padding: 10px; border: 1px solid #ddd; background: lightgoldenrodyellow;">No rows found</span>';

}

