import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';

import { ColDef } from 'ag-grid-community';
import { DatePipe } from '@angular/common';
import { Invoice } from '../../../../../models/invoice';
import { InvoiceStatus } from '../../../../../models/enums/invoiceStatus';
import { InvoiceService } from '../../../../../services/invoice.service';
import { AlertifyService } from '../../../../../services/alertify.service';
import { GroupNameRendererComponent } from '../../../groups/group-list/group-name-renderer/group-name-renderer';
import { InvoiceType } from '../../../../../models/enums/invoiceType';
import { Period } from '../../../../../models/period';
import { Group } from '../../../../../models/group';
import { PeriodService } from '../../../../../services/period.service';
import { GroupService } from '../../../../../services/group.service';

@Component({
  selector: 'app-invoice-view',
  templateUrl: './invoice-view.component.html',
  styleUrls: ['./invoice-view.component.scss']
})

export class InvoiceViewComponent implements OnInit {
  columnDefs: ColDef[] = [];
  defaultColDef = {
    flex: 1,
    minWidth: 150,
    resizable: true,
  };
  private gridApi;
  period: Period[];
  groupList: Group[];
  invoice: Invoice = new Invoice();
  invoiceitem: any[];
  invoiceForm: FormGroup;
  loading: boolean;
  invoiceCreateForm: FormGroup;
  submitted: boolean;
  invoiceId: string;
  selectedEnumStatus: InvoiceStatus = InvoiceStatus.All;
  enumStatus = [
    { value: InvoiceStatus.All, text: 'Select Status' },
    { value: InvoiceStatus.New, text: 'New' },
    { value: InvoiceStatus.ReadyToSend, text: 'ReadyToSend' },
    { value: InvoiceStatus.Sent, text: 'Sent' },
    { value: InvoiceStatus.Paid, text: 'Paid' },
    { value: InvoiceStatus.Cancelled, text: 'Cancelled' }
  ];
  enumType = [
    { value: InvoiceType.All, text: 'Select Type' },
    { value: InvoiceType.PremiumFee, text: 'PremiumFee' },
    { value: InvoiceType.ThinkWellnessNowFee, text: 'ThinkWellnessNowFee' }
  ];
  constructor(private _invoiceService: InvoiceService, private _periodService: PeriodService,
    private _groupService: GroupService,
    private _fb: FormBuilder, private _alertify: AlertifyService,
    private _route: ActivatedRoute, private _router: Router) {

    this._route.params.subscribe(params => { this.invoiceId = params['id']; });
    const _this = this;

    this.columnDefs = [
      { headerName: 'Line Item Number', field: 'id', suppressMovable: true, sortable: true, filter: true },
      { headerName: 'Member Id', field: 'externalId', suppressMovable: true, sortable: true, filter: true },
      {
        headerName: 'Member Name', field: 'memberName', suppressMovable: true, sortable: true, filter: true
      },
      { headerName: 'Plan Name', field: 'planName', suppressMovable: true, sortable: true, filter: true },

      {
        headerName: 'Premium Amount', field: 'premiumAmount', suppressMovable: true, sortable: true, filter: true,
        valueFormatter: function (params) {
          return !!params.value ? '$' + params.value : '';
        }
      },
      {
        headerName: "",
        field: "",
        suppressMovable: true,
        cellRendererFramework:
          GroupNameRendererComponent, cellRendererParams: {
            showViewBtn: true,
            clicked: function (params: any) {
              _this._router.navigate([`/admin/members/${params.data.externalId}`]);
            }
          }
      },

    ];
  }

  onBtPrint() {
    var gridApi = this.gridApi;
    this.setPrinterFriendly(gridApi);
    setTimeout(function () {
      print();
      this.setNormal(gridApi);
    }, 2000);
  }
  setPrinterFriendly(api) {
    var eGridDiv = document.querySelector(".ag-grid-500");
    api.setDomLayout("print");
  }
  setNormal(api) {
    var eGridDiv = document.querySelector(".ag-grid-500");
    //eGridDiv.style.width = "600px";
    //eGridDiv.style.height = "200px";
    api.setDomLayout(null);
  }
  getInvoiceSubmitForm() {
    this.invoiceCreateForm = this._fb.group({
      startDate: ["", [Validators.required]],
      endDate: ["", [Validators.required]],
      number: ["", Validators.required],
      groupId: ["", Validators.required],
      periodId: ["", Validators.required],
      dueDate: ["", Validators.required],
      status: [InvoiceStatus.All, [Validators.required, this.validateStatus.bind(this)]],
      type: [InvoiceType.All, [Validators.required, this.validateType.bind(this)]],
    });

  }
  ngOnInit() {
    this.getInvoiceSubmitForm();
    this.getInvoiceForm();
    this.getAllInvoices();
    this.getPeriods();
    this.getGroups();
    this.getInvoiceById(this.invoiceId);

  }
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridApi.setDomLayout("autoHeight");
  }
  getAllInvoices() {

    this._invoiceService.getAllItemByInvoiceId(this.invoiceId).subscribe(res => {
      this.invoiceitem = res;
    })
  }
  onChange(value: any) {
    this.selectedEnumStatus = value.target.value;
  }

  getInvoiceForm() {
    this.invoiceForm = this._fb.group({
      id: [""],
      status: [InvoiceStatus.All, [Validators.required, this.validateStatus.bind(this)]]
    });
  }
  // Custom validator to ensure "All" is selected
  validateStatus(control: any) {
    return Number(control.value) === InvoiceStatus.All ? { required: true } : null;
  }
  validateType(control: any) {
    return Number(control.value) === InvoiceType.All ? { required: true } : null;
  }
  onSubmit() {
    this.submitted = true;
    this.invoiceForm.get('status').markAsTouched();
    if (this.invoiceForm.invalid) {
      return;
    }

    this.loading = true;
    this._invoiceService.updateInvoiceStatus(this.invoiceId, this.selectedEnumStatus.toString())
      .pipe(first())
      .subscribe(
        (response) => {

          this.getInvoiceById(this.invoiceId);
          this.loading = false;
          this.submitted = false;
          const button = document.getElementById('closeP')!;
          button.click();
          this._alertify.success('Invoice updated successfully.');
          this.getAllInvoices();
        },
        (error) => {
          if (error.error instanceof Array) {
            this._alertify.error(error.error);
            error.error.map((err) => this._alertify.error(err.value));
          } else if (typeof error.error === "string") {
            this._alertify.error(error.error);
          } else {
            this._alertify.error(error.error);
          }
          this.loading = false;
        }
      );
  }

  onInvoiceSubmit() {
    debugger;
    this.submitted = true;
    this.invoiceCreateForm.get('status').markAsTouched();
    if (this.invoiceCreateForm.invalid) {
      return;
    }
    this.loading = true;

    this.invoiceCreateForm.controls.groupId.setValue(+this.invoiceCreateForm.value.groupId);
    this._invoiceService.addInvoice(this.invoiceCreateForm.value)
      .pipe(first())
      .subscribe(
        (data: Invoice) => {
          debugger
          this.getInvoiceById(this.invoiceId);
          this.loading = false;
          this.submitted = false;
          const button = document.getElementById('closeInvoiceCreate')!;
          button.click();
           this._alertify.success('Invoice added successfully.');
          this.getAllInvoices();
        },
        (error) => {
          debugger;
          if (error.error instanceof Array) {
            this._alertify.error(error.error);
            error.error.map((err) => this._alertify.error(err.value));
          } else if (typeof error.error === "string") {
            this._alertify.error(error.error);
          } else {
            this._alertify.error(error.error);
          }
          this.loading = false;
        }
      );
  }
  get f() {
    return this.invoiceForm.controls;
  }
  get p() {
    return this.invoiceCreateForm.controls;
  }
  setInvoiceForm() {
    this.f.id.setValue(this.invoice.id);

    this.f.status.setValue(InvoiceStatus[this.invoice.status]);

  }

  getPeriods() {
    this._periodService
      .getAllPeriods()
      .subscribe((periods) => {
        this.period = periods;

      });
  }

  getGroups() {
    this._groupService.getAllGroups()
      .subscribe((group) => {
        this.groupList = group;

      });
  }
  getInvoiceById(invoiceId: string) {
    this._invoiceService.getInvoiceById(invoiceId).subscribe(data => {
      this.invoice = data;
    });
  }


}

