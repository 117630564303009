import { Injectable } from '@angular/core';
import { HttpClient,HttpParams } from '@angular/common/http';
import { getApiUrl } from '../helpers/get-url';
import { Observable } from 'rxjs/internal/Observable';
import { Invoice } from '../models/invoice';
import { InvoiceStatus } from '../models/enums/invoiceStatus';

@Injectable({ providedIn: 'root' })
export class InvoiceService {
  constructor(private http: HttpClient) { }
  
  getAllInvoices(search:any): Observable<any> {    
    return this.http.post<any>(getApiUrl("invoice/getAllInvoices"),search);
  } 

  getInvoiceById(invoiceId:string) {
    return this.http.get<any>(getApiUrl(`invoice/${invoiceId}`));
  }
  getAllItemByInvoiceId(invoiceId: string) {
    return this.http.get<any>(getApiUrl(`invoice/${invoiceId}/lineitems`));
  }
  updateInvoiceStatus(invoiceId: string, status: string): Observable<any> {
   
    return this.http.put<any>(getApiUrl(`invoice/${invoiceId}/UpdateInvoiceStatus/${status}`), status);
  }
  addInvoice(group: Invoice): Observable<Invoice> {
    return this.http.post<Invoice>(getApiUrl("invoice/addInvoice"), group);
  }
  getMemberByPeriod(groupId: string, periodScheduleId:number) {
    return this.http.get<any>(getApiUrl(`invoice/${groupId}/getMemberByPeriod/${periodScheduleId}`));
  } 
  addInvoiceMemberDetail(invoice: any): Observable<any> {
    return this.http.post<any>(getApiUrl("invoice/AddMemberInvoice"), invoice);
  }
}
