import { Component } from '@angular/core';
import { GroupService } from 'src/app/services/group.service';
import { Group } from 'src/app/models/group';
import { ColDef } from 'ag-grid-community';
import { ActivatedRoute, Router } from '@angular/router';
import { BtnCellRendererComponent } from 'src/app/shared/controls/renderers/button-renderer/btn.renderer';
import { GroupNameRendererComponent } from './group-name-renderer/group-name-renderer';
import { DatePipe } from '@angular/common';
import { FormatDate } from '../../../../helpers/format';
import { group } from '@angular/animations';

@Component({
  selector: 'app-groups',
  templateUrl: './group-list.component.html'
})
export class GroupListComponent {
  selectedGroupId: string;
  addingGroup = false;
  groups: Group[];
  columnDefs: ColDef[] = [];
  defaultColDef = {
    flex: 1,
    minWidth: 90,
    resizable: true,
  };

  private gridApi;

  constructor(private _groupService: GroupService, private _route: ActivatedRoute, private _date: FormatDate, private _router: Router) {
    this._route.params.subscribe(params => { this.selectedGroupId = params['groupId']; });
    if (this.selectedGroupId) {
      this.addingGroup = true;
    }
    const _this = this;
    this.getAllGroups();

    this.columnDefs = [
      { hide: true, field: 'referenceId' },
      { headerName: 'External Id', field: 'externalId', suppressMovable: true, sortable: true, filter: true, maxWidth: 120, suppressSizeToFit: true },
      { headerName: 'Name', field: 'name', suppressMovable: true, sortable: true, filter: true, maxWidth: 300 },
      { headerName: 'Email', field: 'email', suppressMovable: true, sortable: true, filter: true, flex: 1 },
      { headerName: 'Phone Number', field: 'phoneNumber', suppressMovable: true, sortable: true, filter: true, flex: 1 },
      {
        headerName: 'Status', field: 'isActive', suppressMovable: true, sortable: true, filter: true,
        cellRenderer: (params) => {
          if (params.value) {
            return 'Active';
          } else {
            return 'InActive';
          }
        }
      },
      {
        headerName: 'Date Effective', field: 'startDate', suppressMovable: true, sortable: true, filter: true, maxWidth: 200,
        valueFormatter: function (params) {
          return !!params.value ? _date.formatDate(params.value.substr(0, 10)) : '';
        }
      },
      {
        headerName: '',
        field: 'referenceId',
        suppressMovable: true,
        width: 200,
        cellRendererFramework: BtnCellRendererComponent,
        cellRendererParams: {
          //showMemberBtn: true,
          //showUserBtn: true,
          //showLeadListBtn: true,
          //showClaimPaymentBtn:true,
          // showEditBtn: true,
          showGroupViewBtn: true,

          //showDeleteBtn: true,
          clicked: function (isDelete: boolean, referenceId: any) {
            if (isDelete) {
              _this.deleteGroup(referenceId);
            } else {
              _this._router.navigate([`/admin/groups/${referenceId}`]);
            }

          }
        },
      }];
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridApi.setDomLayout("autoHeight");
  }

  getAllGroups() {
    this._groupService.getAllGroups().subscribe(groups => {
      this.groups = groups;
 
    });
  }

  deleteGroup(referenceId: string) {
    this._groupService.deleteGroup(referenceId).subscribe(x => {
      this.groups = this.groups.filter(g => g.referenceId !== referenceId);
      this.gridApi.setRowData(this.groups);
    });
  }

  addGroup() {
    this.addingGroup = true;
  }

  showAllGroups() {
    this.getAllGroups();
    this.addingGroup = false;
  }

}
