import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { getApiUrl } from '../helpers/get-url';
import { Observable } from 'rxjs/internal/Observable';
import { PeriodSchedule, PeriodSchedulePlan } from '../models/periodschedule';


@Injectable({ providedIn: 'root' })
export class PeriodScheduleService {  
  constructor(private http: HttpClient) { }
 
  getAllPeriodSchedule(): Observable<any> {
    return this.http.get<any>(getApiUrl("periodSchedule"));
  }
 
  getPeriodScheduleById(periodId: string): Observable<any>  {
    return this.http.get<PeriodSchedule[]>(getApiUrl(`periodSchedule/getScheduleByPeriodId/${periodId}`));
  }

  getPeriodSchedulePlanById(periodSchduleId: string): Observable<any> {
    return this.http.get<PeriodSchedule[]>(getApiUrl(`periodSchedule/getSchedulePlanByPeriodId/${periodSchduleId}`));
  }

  getScheduleById(periodId: string): Observable<any> {
    return this.http.get<PeriodSchedule[]>(getApiUrl(`periodSchedule/${periodId}`));
  }
  getSchedulePlanById(schedulePlanId: number): Observable<any> {
    return this.http.get<PeriodSchedulePlan[]>(getApiUrl(`periodSchedule/getSchedulePlanByScheduleId/${schedulePlanId}`));
  }

  updatePeriodSchedule(periodId: string, period: PeriodSchedule): Observable<PeriodSchedule> {
    return this.http.put<PeriodSchedule>(getApiUrl(`periodSchedule/${periodId}`), period);
  }
  getAllPeriodSchedulePlanById(periodSchduleId: number): Observable<any> {
    return this.http.get<PeriodSchedulePlan[]>(getApiUrl(`periodSchedule/getAllPeriodSchedulePlanById/${periodSchduleId}`));
  }
  updatePeriodSchedulePlan(Id: number, period: PeriodSchedulePlan): Observable<PeriodSchedulePlan> {
    return this.http.put<PeriodSchedulePlan>(getApiUrl(`periodSchedule/${Id}/UpdatePeriodSchedulePlan`), period);
  }
}
