import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { IAfterGuiAttachedParams } from 'ag-grid-community';

@Component({
    selector: 'group-name-renderer',
  templateUrl: './group-name-renderer.html',
  styleUrls: ["./group-name-renderer.scss"],
})
export class GroupNameRendererComponent implements ICellRendererAngularComp {

    params: any;
    constructor(private router: Router) {

    }

    refresh(params: any): boolean {
        return true;
    }

    afterGuiAttached?(params?: IAfterGuiAttachedParams): void {

    }

    agInit(params: any): void {
        this.params = params;
    }

    clickHandler() {
        this.params.clicked(this.params);
    }

    show(): boolean {

        return typeof this.params.show !== "undefined" ? this.params.show(this.params) : true;
    }
    OnRedirectToMemberView() {
        let memberId = this.params.data.externalId;
        this.router.navigate([`/admin/members/${memberId}`]);
    }
    OnRedirectToEnrollFormView() {
        let memberId = this.params.data.externalId;
        this.router.navigate([]).then(result => {  window.open(`/admin/enroll/member/${memberId}`, '_blank'); });
    }
}
