import { Component } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ColDef } from "ag-grid-community";
import { Claim } from "src/app/models/claim";
import { ClaimService } from "src/app/services/claim.service";
import { GroupNameRendererComponent } from "../../groups/group-list/group-name-renderer/group-name-renderer";
import { ActivatedRoute, Router } from "@angular/router";
import { ClaimPayment } from "../../../../models/claimPayment";
import { BtnCellRendererComponent } from "../../../../shared/controls/renderers/button-renderer/btn.renderer";
import { FormatDate } from "src/app/helpers/format";
import { ClaimPaymentService } from "../../../../services/claimPayment.service";

@Component({
  selector: "app-claim-payments-list",
  templateUrl: "./claim-payments-list.component.html",
  styleUrls: ["./claim-payments-list.component.css"],
})
export class ClaimPaymentsListComponent {
  claimpayment: ClaimPayment[];
  groupId: string;
  columnDefs: ColDef[] = [];
  defaultColDef = {
    flex: 1,
    minWidth: 150,
    resizable: true,
  };
  constructor(private _claimService: ClaimPaymentService, private _route: ActivatedRoute, private _router: Router, private _date: FormatDate,) {

    this._route.params.subscribe(params => {
      this.groupId = params['id'];
    });
    this.columnDefs = [
      { hide: true, field: "id" },
      { headerName: 'Claim Payment Id', field: 'claimPaymentId', suppressMovable: true, sortable: true, filter: true },
     // { headerName: 'Name', field: 'name', suppressMovable: true, sortable: true, filter: true},
      {
        headerName: 'Period Start Date', field: 'periodStartDate', suppressMovable: true, sortable: true, filter: true,
        valueFormatter: function (params) {
          return !!params.value ? _date.formatDate(params.value) : '';
        }
      },
      {
        headerName: 'Period End Date', field: 'periodEndDate', suppressMovable: true, sortable: true, filter: true,
        valueFormatter: function (params) {
          return !!params.value ? _date.formatDate(params.value) : '';
        }
      },
      {
        headerName: 'Claim Start Date', field: 'claimStartDate', suppressMovable: true, sortable: true, filter: true,
        valueFormatter: function (params) {
          return !!params.value ? _date.formatDate(params.value) : '';
        }
      },
      {
        headerName: 'Claim End Date', field: 'claimEndDate', suppressMovable: true, sortable: true, filter: true,
        valueFormatter: function (params) {
          return !!params.value ? _date.formatDate(params.value) : '';
        }
      },
      {
        headerName: 'Payment Date', field: 'paymentDate', suppressMovable: true, sortable: true, filter: true,
        valueFormatter: function (params) {
          return !!params.value ? _date.formatDate(params.value) : '';
        }
      },
      {
        headerName: 'Amount', field: 'totalAmount', suppressMovable: true, sortable: true, filter: true,
        valueFormatter: function (params) {
          return !!params.value ? '$' + params.value : '';
        }
      },
      {
        headerName: 'Status', field: 'claimPaymentStatus', suppressMovable: true, sortable: true, filter: true
      },
      {
        headerName: '',
        field: "id",
        suppressMovable: true,
        cellRendererFramework: BtnCellRendererComponent,
        cellRendererParams: {
          showClaimBtn: true
        },
      },
    ];
    
  }

  private _gridApi;

  ngOnInit() {
    this._getAllClaimPayments(this.groupId);
  }

  onGridReady(params) {
    this._gridApi = params.api;
  }

  private _getAllClaimPayments(groupId) {

    this._claimService.getAllClaimPayments(groupId).subscribe(claimpayment => {
      this.claimpayment = claimpayment;
      console.log(claimpayment);
      // this._gridApi.hideOverlay();
      if (this.claimpayment.length == 0)
        this._gridApi.showNoRowsOverlay();
    });
  }

  public overlayNoRowsTemplate =
    '<span style="padding: 10px; border: 1px solid #ddd; background: lightgoldenrodyellow;">No rows found</span>';

}
