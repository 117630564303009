import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { catchError, debounceTime, distinctUntilChanged, first, map, switchMap, tap } from 'rxjs/operators';
import { GroupService } from 'src/app/services/group.service';
import { User } from 'src/app/models/User';
import { Observable, of } from 'rxjs';
import { BrokerService } from 'src/app/services/broker.service';
import { ColDef } from 'ag-grid-community';
import { BtnCellRendererComponent } from 'src/app/shared/controls/renderers/button-renderer/btn.renderer';
//import { CompanyService } from 'src/app/services/company.service';
import { MemberService } from 'src/app/services/member.service';
import { AlertifyService } from 'src/app/services/alertify.service';
import { Route, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { PeriodService } from '../../../../services/period.service';
import { Period } from '../../../../models/period';
import { FormatDate } from '../../../../helpers/format';

@Component({
  selector: 'app-add-group',
  templateUrl: './add-edit.component.html'
})
export class AddEditGroupComponent implements OnInit {
  @Input()
  groupId: string;
  Id: number;
  groupForm: FormGroup;
  city: string;
  state: string;
  loading = false;
  loadingBroker = false;
  submitted = false;
  brokers: Observable<User[]>;
  searching = false;
  searchFailed = false;
  broker: User;
  groupBrokers: User[] = [];
  period: Period[];
  getRowNodeId;
  errorMessage = '';
  successMessage = '';
  addUpdateError: string[] = [];
  isExternalIdExist: boolean = false;
  defaultColDef = {
    flex: 1,
    minWidth: 150,
    resizable: true,
  };

  columnDefs: ColDef[] = [
    { hide: true, field: 'id' },
    { headerName: 'First Name', field: 'firstName', suppressMovable: true, sortable: true, filter: true },
    { headerName: 'Last Name', field: 'lastName', suppressMovable: true, sortable: true, filter: true },
    { headerName: 'Email', field: 'userName', suppressMovable: true, sortable: true, filter: true },
  ];

  private gridApi;

  constructor(
    private _formBuilder: FormBuilder,
    private _groupService: GroupService,
    private _periodService: PeriodService,
    private _brokerService: BrokerService,
    private _memberService: MemberService,
    private _alertify: AlertifyService,
    private _router: Router,
    private _datepipe: DatePipe
  ) {
    const _this = this;
    this.columnDefs.push({
      headerName: '',
      field: 'id',
      cellRendererFramework: BtnCellRendererComponent,
      cellRendererParams: {
        showDeleteBtn: true,
        clicked: function (isDelete: boolean, referenceId: any) {
          if (isDelete) {
            _this._brokerService.removeBrokerFromGroup(_this.groupId, referenceId).subscribe(() => {
              const itemToRemove = _this.groupBrokers.findIndex(x => x.id === referenceId);
              _this.groupBrokers.splice(itemToRemove, 1);
              _this.gridApi.setRowData(_this.groupBrokers);
            });
          }
        }
      },
    });

    this.getRowNodeId = function (data) {
      return data.id;
    };
  }

  ngOnInit() {
    // referenceId: string;
    this.groupForm = this._formBuilder.group({
      id: 0,
      name: ['', Validators.required],
      externalId: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phoneNumber: ['', [Validators.required]],
      contactPerson: ['', Validators.required],
      addressLine1: ["", Validators.required],
      addressLine2: [""],
      zipCode: ["", Validators.required],
      city: [""],
      state: [""],
      webSiteUrl: [''],
      broker: [''],
      periodId: ['', Validators.required],
      startDate: ['', Validators.required],
      isActive: ['', Validators.required]
    });
    
    this.groupForm.controls.isActive.setValue(false);

    if (!!this.groupId) {
      this._groupService.getGroup(this.groupId).subscribe(group => {
        var payPeriodText = group.payPeriod ? group.payPeriod.toString() : "";
        if (payPeriodText == "SemiMonthly") {
          group.payPeriod = 1;
        }
        else if (payPeriodText == "Monthly") {
          group.payPeriod = 0;
        }
        else if (payPeriodText == "Weekly") {
          group.payPeriod = 2;
        }
        else if (payPeriodText == "BiWeekly") {
          group.payPeriod = 3;
        }
       
        this.groupForm.patchValue(group);
        this.Id = group.id;
        this.groupForm.controls["startDate"].patchValue(
          this._datepipe.transform(group.startDate, "yyyy-MM-dd")        
        );
        this.city = group.city;
        this.state = group.state;
      });
      this._groupService.getAllBrokers(this.groupId).subscribe(brokers => {
        this.groupBrokers = brokers;
      });
    }
    this.getPeriods();

  }

  // convenience getter for easy access to form fields
  get f() {
    return this.groupForm.controls;
  }

  formatter = (x: User) => `${x.firstName} ${x.lastName} (${x.userName})`;

  inputFormatter(broker: User) {
    return `${broker.firstName} ${broker.lastName} (${broker.userName})`;
  }

  onGridReady(params) {
    this.gridApi = params.api;
  }

  AssignBroker() {
    this.errorMessage = '';
    if (this.loadingBroker || this.loading || !this.broker || !this.groupId) {
      return;
    }

    if (this.groupBrokers.findIndex(x => x.id === this.broker.id) >= 0) {
      this.searchFailed = true;
      this.errorMessage = 'Agent already assigned to this group.';
      return;
    }


    this.loadingBroker = true;
    this._brokerService.addBrokerToGroup(this.groupId, this.broker.id).subscribe(
      () => {
        this.groupBrokers = [];
        this.groupBrokers.push(this.broker);
        this.loadingBroker = false;
        this.broker = null;
        this.gridApi.setRowData(this.groupBrokers);
      },
      () => {
        this.loadingBroker = false;
      });
  }

  searchBroker(searchString: string): Observable<User[]> {
    if (!searchString || searchString.length < 2) {
      return of([]);
    }
    // this.searching = true;
    return this._brokerService.getAvailableBrokers(searchString).pipe(     
      map(response => response),    
      tap(() => {      
        this.searchFailed = false
      }),
      catchError(() => {
        this.searchFailed = true;
        this.errorMessage = 'No Agents found.';
        return of([]);
      }));
  }

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term => this.searchBroker(term)),
      tap(() => this.searching = false)
    )



  onSubmit() {
    this.submitted = true;
    // reset alerts on submit
    this.successMessage = '';
    this.addUpdateError = [];

    // stop here if form is invalid
    if (this.groupForm.invalid) {
      return;
    }
    if (this.isExternalIdExist) {
      return;
    }

    this.loading = true;
    const groupRefrenceId = this.groupId;
    this.groupForm.controls.city.setValue(this.city);
    this.groupForm.controls.state.setValue(this.state);
    this.groupForm.controls.isActive.setValue((/true/i).test(this.groupForm.value.isActive)); //returns true
    this.groupForm.controls.periodId.setValue(+this.groupForm.value.periodId);
    delete this.groupForm.value['broker'];
    if (!!groupRefrenceId) {
      this._groupService.updateGroup(groupRefrenceId, this.groupForm.value)
        .pipe(first())
        .subscribe(
          () => {
            // return to list
            //this.successMessage = 'Group updated successfully.';
            this.loading = false;
            this._alertify.success('Group updated successfully.');
            this._router.navigate(['/admin/group-view/' + this.groupId]);
          },
          error => {
            if (error.error instanceof Array) {
              error.error.map(err => this._alertify.error(err.value)); //this.addUpdateError.push
            } else if (typeof error.error === 'string') {
              //this.addUpdateError.push(error.error);
              this._alertify.error(error.error)
            } else {
              //this.addUpdateError.push(error);
              this._alertify.error(error);
            }
            this.loading = false;
          });
    } else {
      this._groupService.addGroup(this.groupForm.value)
        .pipe(first())
        .subscribe(
          (group) => {
            // return to list
            //this.successMessage = 'Group added successfully.';
            this.loading = false;
            this.groupId = group.referenceId;
            this._alertify.success('Group added successfully.');
            window.location.reload();
          },
          error => {
            if (error.error instanceof Array) {
              error.error.map(err => this._alertify.error(err.value)); //this.addUpdateError.push
            } else if (typeof error.error === 'string') {
              //this.addUpdateError.push(error.error);
              this._alertify.error(error.error)
            } else {
              //this.addUpdateError.push(error);
              this._alertify.error(error);
            }
            this.loading = false;
          });
    }
  }
  handleOnChange(event: any) {
    if (event.target.value.length >= 5) {
      let xml: string = '<CityStateLookupRequest USERID="8H6THINK42633"><ZipCode ID="0"><Zip5>' + event.target.value + '</Zip5></ZipCode></CityStateLookupRequest>';
      this._memberService.getCityStateByZip(xml).subscribe((res) => {
        if (res) {
          this.city = res.zipCode.city;
          this.state = res.zipCode.state;
        }
      });
    }
  }
 
  getPeriods() {   
    this._periodService
      .getAllPeriods()
      .subscribe((periods) => {       
        this.period = periods;
       
      });
  }
  exernalIdValidation() {
    if (!!this.groupForm.value.externalId) {
      this.loading = true;
      const groupReferenceId = this.Id;
      if (!!groupReferenceId) {
        this._groupService.validateGroupExternalId(this.groupForm.value.externalId, Number(groupReferenceId))
          .pipe(first())
          .subscribe(
            () => {
              // return to list            
              this.isExternalIdExist = false;
              this.loading = false;
            },
            error => {
              this.isExternalIdExist = true;
              this.loading = false;
            });
      } else {
        this._groupService.validateGroupExternalId(this.groupForm.value.externalId, 0)
          .pipe(first())
          .subscribe(
            () => {
              // return to list                        
              this.isExternalIdExist = false;
              this.loading = false;
            },
            error => {
              this.isExternalIdExist = true;
              this.loading = false;
            });
      }
    }
    else {
      this.isExternalIdExist = false;
    }
  }
  //displayFn(company: CompanyName) {
  //  return company && company.name ? company.name : '';
  //}
  //setCompany(event) {
  //  var _this = this;
  //  var company = _this.groupForm.controls.companyName.value;
  //  _this.groupForm.controls.companyId.setValue(company && company.id ? company.id.toString() : '0');
  //}
 
}


