import { Injectable } from '@angular/core';

@Injectable({
  providedIn: "root",
})
export class SignatureService {
  private signatureData: string;
  
  getSignatureData(): string {
    return this.signatureData;
  }

  setSignatureData(data: string): void {
    this.signatureData = data;
  }
}
