import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { getApiUrl } from '../helpers/get-url';
import { Observable } from 'rxjs/internal/Observable';
import { WellnessProgress } from '../models/wellnessProgress';

@Injectable({ providedIn: 'root' })
export class WellnessProgressService {
  constructor(private http: HttpClient) { }

  getAllWellnessProgressForMedicalReceiptType(): Observable<WellnessProgress[]> {
    return this.http.get<WellnessProgress[]>(getApiUrl('wellness-progress/medical-receipt-type'));
  }

  getWellnessProgress(wellnessProgressId: number): Observable<WellnessProgress> {
    return this.http.get<WellnessProgress>(getApiUrl(`wellness-progress/${wellnessProgressId}`));
  }

  updateMedicalReceipt(wellnessProgressId: number, wellnessProgress: WellnessProgress): Observable<WellnessProgress> {
    return this.http.put<WellnessProgress>(getApiUrl(`wellness-progress/medical-receipt-type/${wellnessProgressId}`), wellnessProgress);
  }

  updateReviewForMedicalReceiptType(wellnessProgressId: number, wellnessProgress: WellnessProgress): Observable<WellnessProgress> {
    return this.http.put<WellnessProgress>(getApiUrl(`review/medical-receipt-type/${wellnessProgressId}`), wellnessProgress);
  }

  addMedicalReceipt(wellnessProgress: WellnessProgress): Observable<WellnessProgress> {
    var formData: any = new FormData();
    formData.append('serviceDate', wellnessProgress.serviceDate);
    formData.append('providerName', wellnessProgress.providerName);
    formData.append('IndemnityBenefitId', wellnessProgress.indemnityBenefitId);
    formData.append('receipt', wellnessProgress.receipt);
    formData.append('patientName', wellnessProgress.patientName);
    formData.append('patientBirthDate', wellnessProgress.patientBirthDate);
    return this.http.post<WellnessProgress>(getApiUrl('wellness-progress/medical-receipt-type'), formData);
  }

  getWellnessProgressForMember(month?: number, year?: number): Observable<WellnessProgress[]> {
    let url = getApiUrl('wellness-progress/wellness-progress-for-member');
    let queryParamAdded = false;
    if (!!month) {
      queryParamAdded = true;
      url += `?month=${month}`;
    }

    if (!!year) {
      url += queryParamAdded ? '&' : '?';
      url += `year=${year}`;
    }

    return this.http.get<WellnessProgress[]>(url);
    //return this.http.get<WellnessProgress[]>(getApiUrl('wellness-progress/wellness-progress-for-member'));
  }

  getAllWellnessProgressForMedicalReceiptTypeForMember(): Observable<WellnessProgress[]> {
    return this.http.get<WellnessProgress[]>(getApiUrl('wellness-progress/medical-receipt-type-for-member'));
  }

  downloadMedicalReceipt(wellnessProgressId: number): Observable<Blob> {
    return this.http.get<Blob>(getApiUrl(`wellness-progress/medical-receipt-type/download/${wellnessProgressId}`), { responseType: 'blob' as 'json' } );
  }
}
