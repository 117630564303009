import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { getApiUrl } from '../helpers/get-url';
import { Period } from '../models/period';
import { Observable } from 'rxjs/internal/Observable';
import { StringResponse } from '../models/stringResponse';

@Injectable({ providedIn: 'root' })
export class PeriodService {
  constructor(private http: HttpClient) { }

  getAllPeriods(): Observable<any> {
    return this.http.get<any>(getApiUrl("periods/getAllPeriods"));
  }
  getPeriodById(planId: string) {
    return this.http.get<Period>(getApiUrl(`periods/${planId}`));
  }

  addPeriod(period: Period): Observable<Period> {
    return this.http.post<Period>(getApiUrl('periods'), period);
  }

  updatePeriod(periodId: number, period: Period): Observable<Period> {
    return this.http.put<Period>(getApiUrl(`periods/${periodId}`), period);
  }

  deletePeriod(periodId: number) {
    return this.http.delete(getApiUrl(`periods/${periodId}`));
  }
}
