import { Component } from '@angular/core';
import { WellnessProgressService } from 'src/app/services/wellnessProgressService.service';
import { WellnessProgress } from 'src/app/models/wellnessProgress';
import { ColDef } from 'ag-grid-community';
import { ActivatedRoute, Router } from '@angular/router';
import { BtnCellRendererComponent } from 'src/app/shared/controls/renderers/button-renderer/btn.renderer';
import { saveAs as importedSaveAs } from "file-saver";
import { WellnessProgressOverallStatus } from 'src/app/models/enums/wellnessProgressOverallStatus';
import { FormatDate } from '../../../../helpers/format';

@Component({
    selector: 'app-view-medicalreceipts',
    templateUrl: './view-medicalreceipt.component.html'
})
export class ViewMedicalReceiptComponent {
    wellnessProgresses: WellnessProgress[];
    columnDefs: ColDef[] = [];
    defaultColDef = {
        flex: 1,
        minWidth: 150,
        resizable: true,
    };

    filterParams = {
      comparator: function (filterLocalDateAtMidnight, cellValue) {
        if (cellValue == null) { return -1; }
        const dateAsString = cellValue.substr(0, 10);
        const dateParts = dateAsString.split('-');
        const cellDate = new Date(
          Number(dateParts[0]),
          Number(dateParts[1]) - 1,
          Number(dateParts[2])
        );
        if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
          return 0;
        }
        if (cellDate < filterLocalDateAtMidnight) {
          return -1;
        }
        if (cellDate > filterLocalDateAtMidnight) {
          return 1;
        }
      },
      browserDatePicker: true,
    };

    private gridApi;

  constructor(private _wellnessProgressService: WellnessProgressService, private _date: FormatDate, private _route: ActivatedRoute, private _router: Router) {
        const _this = this;
      this.getAllWellnessProgressForMedicalReceiptTypeForMember();

        this.columnDefs = [
            { hide: true, field: 'id' },
            { headerName: 'Provider Name', field: 'providerName', suppressMovable:true, sortable: true, filter: true },
            {
              headerName: 'Service Date', field: 'serviceDate', suppressMovable: true, sortable: true, filter: 'agDateColumnFilter',
              filterParams: this.filterParams, valueFormatter: function (params) {
                return !!params.value ? _date.formatDate(params.value.substr(0, 10)) : "";
              },
            },
            { headerName: 'Benefit Type', field: 'indemnityBenefitName', suppressMovable: true, sortable: true, filter: true },
            { headerName: 'Status', field: 'overallStatus', suppressMovable: true, sortable: true, filter: true },
            { headerName: 'Amount', field: 'amount', suppressMovable: true, sortable: true, filter: true, valueFormatter: params => "$" + params.data.amount },
            { headerName: 'Patient Name', field: 'patientName', suppressMovable: true, sortable: true, filter: true },
            {
              headerName: 'Patient BirthDate', field: 'patientBirthDate', suppressMovable: true, sortable: true, filter: 'agDateColumnFilter',
              filterParams: this.filterParams, valueFormatter: function (params) {
                return !!params.value ? _date.formatDate(params.value.substr(0, 10)) : '';
              },
            },
            {
              headerName: '',
              field: 'id',
              suppressMovable: true,
              cellRendererFramework: BtnCellRendererComponent,
              cellRendererParams: {
                showClickableBtn: true,
                showDownloadBtn: true,
                clicked: function (boolean, id: any) {
                  _this._wellnessProgressService.downloadMedicalReceipt(id).subscribe(blob => {
                      var receipt = _this.wellnessProgresses.filter(wellness => wellness.id === id);
                      let file = _this.makeid(5);
                      if (receipt != null && receipt.length > 0) {
                          file = receipt[0].friendlyFileName;
                      }

                      importedSaveAs(blob, file);
                    });
                }
              },
            }
        ];
    }
    onGridReady(params) {
        this.gridApi = params.api;
        this.gridApi.setDomLayout("autoHeight");
    }

    makeid(length) {
      var result = '';
      var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() *
          charactersLength));
      }
      return result;
    }

    getAllWellnessProgressForMedicalReceiptTypeForMember() {
      this._wellnessProgressService.getAllWellnessProgressForMedicalReceiptTypeForMember().subscribe(wellnessProgresses => {
            this.wellnessProgresses = wellnessProgresses;
          });
    }

  downloadRecipt() {
    this._wellnessProgressService.downloadMedicalReceipt(1005).subscribe(response => {
        console.log(response);
        let filename = "Test.png";
        importedSaveAs(response, filename);
    });
  }
 
  goToAddRecipt() {
    this._router.navigate(['/medicalreceipt']);
  }
}
