import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HRADetails } from '../models/hraDetails';
import { getApiUrl } from '../helpers/get-url';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class HRAService {
  constructor(private http: HttpClient) { }

  addHRA(groupId: string, hraDetails: HRADetails) {
    return this.http.post(getApiUrl(`hra/${groupId}`), hraDetails);
  }

  getHRA(groupId: string, memberExternalId: string): Observable<HRADetails> {
    return this.http.get<HRADetails>(getApiUrl(`hra/${groupId}/${memberExternalId}`));
  }
}
