import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { getApiUrl } from '../helpers/get-url';
import { Observable } from 'rxjs/internal/Observable';
import { BrokerGroup } from '../models/brokerGroup';
import { BrokerMember } from '../models/brokerMember';
import { User } from '../models/User';
import { BrokerRegister } from '../models/brokerRegister';

@Injectable({ providedIn: 'root' })
export class BrokerService {
  constructor(private http: HttpClient) { }

  getAllBrokers(searchString?: string): Observable<User[]> {
    let url = getApiUrl('brokers/GetAllBrokers');
    if (!!searchString) {
      url += `?searchString=${searchString}`;
    }

    return this.http.get<User[]>(url);
  }
  getAvailableBrokers(searchString?: string): Observable<User[]> {
    let url = getApiUrl('brokers/GetAvailableBrokers');
    if (!!searchString) {
      url += `?searchString=${searchString}`;
    }

    return this.http.get<User[]>(url);
  }
  getBroker(brokerReferenceId: string): Observable<User> {
    return this.http.get<User>(getApiUrl(`brokers/${brokerReferenceId}`));
  }

  addBroker(broker: BrokerRegister) {
    return this.http.post(getApiUrl(`brokers/AddBroker`), broker);
  }

  addBrokerToGroup(groupReferenceId: string, brokerReferenceId: string) {
    return this.http.post(getApiUrl(`brokers/AssignBrokerToGroup/${groupReferenceId}/${brokerReferenceId}`), {});
  }

  removeBrokerFromGroup(groupReferenceId: string, brokerReferenceId: string) {
    return this.http.put(getApiUrl(`brokers/groups/${groupReferenceId}/${brokerReferenceId}`), {});
  }

  getAllGroups(): Observable<BrokerGroup[]> {
    return this.http.get<BrokerGroup[]>(getApiUrl('brokers/groups'));
  }

  getAllGroupMembers(groupExternalId: string): Observable<BrokerMember> {
    return this.http.get<BrokerMember>(getApiUrl(`brokers/groups/${groupExternalId}`));
  }
}
