import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Categories } from 'src/app/models/categories';
import { CategoryType } from 'src/app/models/enums/categoryType';
import { CategoriesService } from 'src/app/services/categories.service';
import { UiText } from 'src/app/models/UiText';
import { UiTextType } from 'src/app/models/enums/UiTextType';
import { MemberService } from 'src/app/services/member.service';

@Component({
  selector: 'app-categories-listing',
  templateUrl: './categories-listing.component.html',
  styleUrls: ['./categories-listing.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CategoriesListingComponent implements OnInit {
  topCategory: Categories[];
  secondCategories: Categories[];
  categories: Categories[];
  isEligibleMedicalReceipts: boolean;

  constructor(private _router: Router, private _categoryService: CategoriesService, private _memberService: MemberService) {


    this._memberService.checkIsEligibleForMedicalReceipts().subscribe(data => {
      this.isEligibleMedicalReceipts = data.isEligibleMedicalReceipts;
    });

    this._categoryService.getAllCategories().subscribe(categories => {
      if (this.isEligibleMedicalReceipts) {
        this.categories = categories;
        this.topCategory = this.categories.splice(0, 2);
        this.secondCategories = this.categories.splice(0, 3);
      } else {
        this.categories = categories;
        this.topCategory = this.categories.splice(0, 1);
        this.secondCategories = this.categories.splice(0, 3);
      }
    });
  }

  ngOnInit() {
  }

  getImage(imageName) {
    return `../../../../../assets/img/${imageName}`;
  }

  goToCategory(category: Categories) {

    if (!category.enabled) {
      return;
    }

    if (this.isEqual(category.type, CategoryType.Video)) {
      this._router.navigate([`/videos/${category.filter}`]);
    }

    if (this.isEqual(category.type, CategoryType.VideoCategory)) {
      this._router.navigate([`/videos/categories/${category.filter}`]);
    }

    if (category.type.toString() == "Hra") {
      this._router.navigate(["medicalreceipt"]);
    }
  }

  private isEqual(type: number, categoryType: CategoryType) {
    return type === categoryType || type.toString() == CategoryType[categoryType];
  }
}
