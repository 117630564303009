import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HMALeadService } from 'src/app/services/hmalead.service';

@Component({
  selector: 'app-get-lead',
  templateUrl: './get-lead.component.html',
  styleUrls: ['./get-lead.component.css']
})
export class GetLeadComponent implements OnInit {

  constructor(private route :ActivatedRoute,private service:HMALeadService) { }
public email:string;
  ngOnInit() {
    // Retrieve the email from the query parameters
    this.route.queryParamMap.subscribe(params => {
       this.email = params.get('Email');
       this.service.getLead(this.email).subscribe((response)=>{
        console.log(response)
      });
      console.log(this.email)
     
      
    });
   
  }

  }

