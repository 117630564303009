import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { first } from 'rxjs/operators';
import { HRAService } from 'src/app/services/hra.service';
import { HRADetails } from 'src/app/models/hraDetails';
import { QuestionService } from 'src/app/data-driven/question.service';
import { DynamicControlService } from 'src/app/data-driven/dynamic-control.Service';
import { BrokerMember } from 'src/app/models/brokerMember';
import { UserStore } from '../StateManagement/User/user.store';
import { GroupService } from 'src/app/services/group.service';

@Component({
  templateUrl: 'hra.component.html',
  styleUrls: ['./hra.component.scss']
})
export class HRAFormComponent implements OnInit {
  form: FormGroup;
  questions;
  validLink = false;
  loading = false;
  submitted = false;
  errorMessage = '';
  invalidLink = false;

  private _groupId: string;
  private _externalId: string;
  private _memberinfo: BrokerMember;
  private _defaultErrorMessage = 'Please complete all unanswered questions in red';

  constructor(
    private _router: Router,
    private _hraService: HRAService,
    private _groupService: GroupService,
    private _route: ActivatedRoute,
    private questionService: QuestionService,
    private qcs: DynamicControlService,
    private _userStore: UserStore
  ) {

    this._route.params.subscribe(params => {
      this._groupId = params['groupId'];
      this._externalId = params['memberId'];
    });

    this.getQuestions();
  }

  ngOnInit() {
    this._groupService.validateGroupAndMember(this._groupId, this._externalId).subscribe(x => {
      this._memberinfo = x;
      this.patchValue();
    }, err => {      
      this.invalidLink = false;
    });
  }

  resetForm() {
    this.submitted = false;
    this.form.reset();
    this.form.markAsPristine();
    this.form.markAsUntouched();
    this.patchValue();
  }

  onSubmit() {
    this.errorMessage = '';
    this.submitted = true;
    // reset alerts on submit
    this.form.markAllAsTouched();

    // stop here if form is invalid
    if (this.form.invalid) {
      this.errorMessage = this._defaultErrorMessage;
      return;
    }

    this.loading = true;
    const grp = this.form.controls['demographicCharacteristics'] as FormGroup;
    const formValue = {
      firstName: grp.controls['firstName'].value,
      middleName: grp.controls['middleName'].value,
      lastName: grp.controls['lastName'].value,
      gender: grp.controls['gender'].value,
      externalId: grp.controls['externalId'].value || this._externalId,
      savedForm: JSON.stringify(this.form.getRawValue())
    } as HRADetails;

    this._hraService.addHRA(this._groupId, formValue)
      .pipe(first())
      .subscribe(
        (): void => {
          this._router.navigate(['/hra-form/thankyou']);
          this._userStore.reset();
          this.loading = false;
        },
        error => {
          this.errorMessage = error.error;
          this.loading = false;
        });
  }

  private getQuestions() {
    this.questionService.getQuestions(this._externalId).subscribe(question => {
      if(question.length > 0){
        for(let i = 0;i< question[0].value.length;i++){
          if(question[0].value[i].key == 'firstName'||question[0].value[i].key == 'lastName'||question[0].value[i].key == 'middleName'
          ||question[0].value[i].key == 'age'||question[0].value[i].key == 'gender'){
            question[0].value[i].controlClassName = 'readOnly' ;
          }
        }
      }
      this.questions = question;

      this.form = this.qcs.toFormGroup(this.questions, false);
    });
  }

  private patchValue() {
    const grp = this.form.controls['demographicCharacteristics'] as FormGroup;    
    if (!!grp && !!this._memberinfo) {
      grp.controls['firstName'].patchValue(this._memberinfo.firstName);
      grp.controls['lastName'].patchValue(this._memberinfo.lastName);
      grp.controls['middleName'].patchValue(this._memberinfo.middleName);
      grp.controls['externalId'].patchValue(this._externalId);      
      grp.controls['age'].patchValue(this._memberinfo.age);
      grp.controls['gender'].patchValue(this._memberinfo.gender);
    }
  }  
}
