import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { BtnCellRendererComponent } from 'src/app/shared/controls/renderers/button-renderer/btn.renderer';
import { AgGridModule } from 'ag-grid-angular';
import { BrokerListComponent } from './broker/broker-list/broker-list.component';
import { AddBrokerComponent } from './broker/add-broker/add-broker.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { UpdateGroupComponent } from './groups/update-group/update-group.component';
import { VideoListComponent } from './videos/video-list/video-list.component';
import { UpdateVideoComponent } from './videos/update-video/update-video.component';
import { AddEditGroupComponent } from './groups/add-group/add-edit.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AddEditVideoComponent } from './videos/add-video/add-edit.component';
import { GroupListComponent } from './groups/group-list/group-list.component';
import { ClaimsListComponent } from './claims/claims-list/claims-list.component';
import { GroupMemberListComponent } from './groups/group-members/group-member-list/group-member-list.component';
import { BrowserModule } from '@angular/platform-browser';
import { GroupNameRendererComponent } from './groups/group-list/group-name-renderer/group-name-renderer';
import { AddClaimComponent } from './groups/group-members/add-claim/add-claim.component';
import { AddEditMemberComponent } from './groups/group-members/add-member/add-edit-member.component';
import { UpdateMemberComponent } from './groups/group-members/update-member/update-member.component';
import { IndemnityBenefitListComponent } from './indemnitybenefits/indemnitybenefit-list/indemnitybenefit-list.component';
import { AddEditIndemnityBenefitComponent } from './indemnitybenefits/add-indemnitybenefit/add-edit.component';
import { UpdateIndemnityBenefitComponent } from './indemnitybenefits/update-indemnitybenefit/update-indemnitybenefit.component';
import { MedicalReceiptListComponent } from './wellnessprogresses/medicalreceipt-list/medicalreceipt-list.component';
import { UpdateMedicalReceiptComponent } from './wellnessprogresses/update-medicalreceipt/update-medicalreceipt.component';
import { PlanListComponent } from './plans/plan-list/plan-list.component';
import { AddEditPlanComponent } from './plans/add-plan/add-edit.component';
import { UpdatePlanComponent } from './plans/update-plan/update-plan.component';
import { MembersWithOutClaimsListComponent } from './claims/members-without-claims-list/members-without-claims-list.component';
import { GroupMembersFamilyListComponent } from './groups/group-members-family/group-members-family-list/group-members-family-list.component';
import { UpdateFamilyMembersComponent } from './groups/group-members-family/update-family-members/update-family-members.component';
import { AddEditFamilyMembersComponent } from './groups/group-members-family/add-edit-family-members/add-edit-family-members.component';
import { ClaimPaymentsListComponent } from './claims/claim-payments-list/claim-payments-list.component';
//import { SignaturePadComponent } from 'src/app/shared/controls/signature/signature-pad/signature-pad.component';
import { EnrollmentComponent } from './employee/enrollment/enrollment.component';
import { AddDependentComponent } from './employee/add-dependent/add-dependent.component';
import { UploadLeadComponent } from './leads/upload-lead/upload-lead.component';
import { LeadListComponent } from './leads/lead-list/lead-list.component';
import { MatAutocompleteModule } from '@angular/material';
import { MemberListComponent } from './members/member-list/member-list.component';
import { MemberViewComponent } from './members/member-view/member-view.component';
import { MemberEnrollmentComponent } from './members/enrollment/enrollment.component';
import { PeriodListComponent } from './period/period-list/period-list.component';
import { AddPeriodComponent } from './period/add-period/add-period.component';
import { PeriodScheduleComponent } from './period/period-schedule/period-schedule.component';
import { EditPeriodScheduleComponent } from './period/period-schedule/edit-period-schedule/edit-period-schedule.component';
import { UpdatePeriodScheduleComponent } from './period/period-schedule/update-period-schedule/update-period-schedule.component';
import { MemberNameRendererComponent } from './members/member-list/member-name-renderer/member-name-renderer';
import { GroupViewComponent } from './groups/group-view/group-view.component';
import { ClaimPaymentComponent } from './claims/Claim-payment/claim-payment.component';
import { ClaimLineItemViewComponent } from './claims/claim-line-item/claim-view/claim-view.component';
import { PeriodSchedulePlanComponent } from './period/period-schedule-plan/period-schedule-plan.component';
import { EditPeriodSchedulePlanComponent } from './period/period-schedule-plan/edit-period-schedule-plan/edit-period-schedule-plan.component';
import { UpdatePeriodSchedulePlanComponent } from './period/period-schedule-plan/update-period-schedule-plan/update-period-schedule-plan.component';
import { InvoiceListComponent } from './Invoice/invoice-list/invoice-list.component';
import { InvoiceViewComponent } from './Invoice/invoice-list/invoice-view/invoice-view.component';
import { AddInvoiceComponent } from './Invoice/add-invoice/add-invoice.component';
import { InvoiceDetailComponent } from './Invoice/invoice-detail/invoice-detail.component';

@NgModule({
  entryComponents: [AddDependentComponent],
  declarations: [
    AddBrokerComponent,
    BrokerListComponent,
    GroupListComponent,
    UpdateGroupComponent,
    AddEditGroupComponent,
    VideoListComponent,
    UpdateVideoComponent,
    AddEditVideoComponent,
    ClaimsListComponent,
    MembersWithOutClaimsListComponent,
    GroupMemberListComponent,
    GroupNameRendererComponent,
    AddClaimComponent,
    AddEditMemberComponent,
    UpdateMemberComponent,
    IndemnityBenefitListComponent,
    AddEditIndemnityBenefitComponent,
    UpdateIndemnityBenefitComponent,
    PlanListComponent,
    MemberListComponent,
    AddEditPlanComponent,
    UpdatePlanComponent,
    MedicalReceiptListComponent,
    UpdateMedicalReceiptComponent,
    GroupMembersFamilyListComponent,
    UpdateFamilyMembersComponent,
    AddEditFamilyMembersComponent,
    ClaimPaymentsListComponent,
    //SignaturePadComponent,
    EnrollmentComponent,
    AddDependentComponent,
    UploadLeadComponent,
    LeadListComponent,
    MemberViewComponent,
    MemberEnrollmentComponent,
    InvoiceListComponent,
    PeriodListComponent,
    AddPeriodComponent,
    PeriodScheduleComponent,
    EditPeriodScheduleComponent,
    UpdatePeriodScheduleComponent,
    InvoiceViewComponent,
    MemberNameRendererComponent,
    GroupViewComponent,
    ClaimPaymentComponent,
    ClaimLineItemViewComponent,
    PeriodSchedulePlanComponent, EditPeriodSchedulePlanComponent, UpdatePeriodSchedulePlanComponent,
    AddInvoiceComponent,InvoiceDetailComponent
  ],
  imports: [
    // AdminRoutingModule,
    AgGridModule.withComponents([
      BtnCellRendererComponent,
      GroupNameRendererComponent,
      MemberNameRendererComponent
    ]),
    CommonModule,
    BrowserModule,
    FormsModule,
    NgbModule,
    ReactiveFormsModule,
    SharedModule,
    MatAutocompleteModule
  ],

  exports: [
    AddDependentComponent
  ]
})
export class AdminModule {}
