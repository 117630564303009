import { Component, OnInit } from '@angular/core';
import { ColDef } from 'ag-grid-community';
import { Period } from '../../../../models/period';
import { PeriodService } from '../../../../services/period.service';
import { BtnCellRendererComponent } from '../../../../shared/controls/renderers/button-renderer/btn.renderer';
import { GroupNameRendererComponent } from '../../groups/group-list/group-name-renderer/group-name-renderer';
import { EditButtonRendererComponent } from '../renderButton/view-button-renderer.component';

@Component({
  selector: 'app-period-list',
  templateUrl: './period-list.component.html',
  styleUrls: ['./period-list.component.scss']
})
export class PeriodListComponent implements OnInit {
  periods: Period[];
  defaultColDef = {
    flex: 1,
    minWidth: 150,
    resizable: true,
  };
  columnDefs: ColDef[] = [
    { headerName: 'Id', field: 'id', suppressMovable: true, sortable: true, filter: true },
    { headerName: 'Name', field: 'name', suppressMovable: true, sortable: true, filter: true },
    //{ headerName: 'Action',field: 'id',cellRendererFramework: EditButtonRendererComponent },
    {
      headerName: '',
      field: "id",
      suppressMovable: true,
      cellRendererFramework: BtnCellRendererComponent,
      cellRendererParams: {
        ViewBtn: true
      },
    },
  ];

  constructor(private _periodService: PeriodService) {   
    this._getAllPeriods();
  }
  
  private _gridApi;

  ngOnInit() {
  }
  
  onGridReady(params) {
    this._gridApi = params.api;
  }

  private _getAllPeriods() {
    this._periodService.getAllPeriods().subscribe(periods => {
      this.periods = periods;
      //this.renderPopup = true;
      this._gridApi.hideOverlay();
      if (this.periods.length == 0)
        this._gridApi.showNoRowsOverlay();
    });
  }

  public overlayNoRowsTemplate =
    '<span style="padding: 10px; border: 1px solid #ddd; background: lightgoldenrodyellow;">No rows found</span>';

}
