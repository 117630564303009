import { Component, OnInit } from '@angular/core';
import { ColDef } from 'ag-grid-community';
import { User } from 'src/app/models/User';
import { BrokerService } from 'src/app/services/broker.service';

@Component({
  selector: 'app-broker-list',
  templateUrl: './broker-list.component.html',
  styleUrls: ['./broker-list.component.scss']
})
export class BrokerListComponent implements OnInit {
  addingBroker = false;
  brokers: User[];
  defaultColDef = {
    flex: 1,
    minWidth: 150,
    resizable: true,
  };
  columnDefs: ColDef[] = [
    { hide: true, field: 'id' },
    { headerName: 'First Name', field: 'firstName', suppressMovable:true, sortable: true, filter: true },
    { headerName: 'Last Name', field: 'lastName', suppressMovable: true,  sortable: true, filter: true },
    { headerName: 'Email', field: 'userName', suppressMovable: true,  sortable: true, filter: true },
  ];
  private _gridApi;
  constructor(private _brokerService: BrokerService) {
    this._getAllBrokers();
  }

  ngOnInit() {
  }

  onGridReady(params) {
    this._gridApi = params.api;
  }

  addBroker() {
    this.addingBroker = true;
  }

  showAllBrokers() {
    this._getAllBrokers();
    this.addingBroker = false;
  }

  private _getAllBrokers() {
    this._brokerService.getAllBrokers().subscribe(brokers => {
      this.brokers = brokers;
      this._gridApi.hideOverlay();
      if (this.brokers.length == 0)
        this._gridApi.showNoRowsOverlay();
    });
  }

  public overlayNoRowsTemplate =
    '<span style="padding: 10px; border: 1px solid #ddd; background: lightgoldenrodyellow;">No rows found</span>';

}
