import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mask'
})
export class MaskPipe implements PipeTransform {
  transform(value: string, mask: string): string {
    if (!value) {
      return value;
    }

    let maskedValue = '';
    let j = 0;

    for (let i = 0; i < mask.length; i++) {
      if (mask[i] === '0') {
        maskedValue += value[j] || '';
        j++;
      } else {
        maskedValue += mask[i];
      }
    }

    return maskedValue;
  }
}
