import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {  Location } from '@angular/common';

@Component({
    selector: 'app-update-group',
    templateUrl: './update-group.component.html'
})
export class UpdateGroupComponent {
    selectedGroupId: string;
  constructor(private _route: ActivatedRoute, private _router: Router, private location: Location) {
        this._route.params.subscribe(params => { this.selectedGroupId = params['groupId']; });
    }

  back() {
    this.location.back();
    }
}
