import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { getApiUrl } from '../helpers/get-url';
import { Claim } from '../models/claim';

@Injectable({
  providedIn: "root",
})
export class ClaimService {
  constructor(private http: HttpClient) {}

  getAllClaims(month?: number, year?: number): Observable<Claim[]> {
    let url = getApiUrl("claims/GetAllClaims");
    let queryParamAdded = false;
    if (!!month) {
      queryParamAdded = true;
      url += `?month=${month}`;
    }

    if (!!year) {
      url += queryParamAdded ? "&" : "?";
      url += `year=${year}`;
    }

    return this.http.get<Claim[]>(url);
  }
  getClaimPayments(claimid: string): Observable<Claim[]> {
    return this.http.get<Claim[]>(getApiUrl(`ClaimPayment?claimid=${claimid}`));
  }
  
  addCustomClaim(externalMemberId: string, claim: any): Observable<Claim> {
    return this.http.post<Claim[]>(
      getApiUrl(`claims/${externalMemberId}`),
      claim
    );
  }

  exportToCSV(month?: number, year?: number): Observable<any> {
    let url = getApiUrl("claims/exportClaimToCSV");
    let queryParamAdded = false;
    if (!!month) {
      queryParamAdded = true;
      url += `?month=${month}`;
    }

    if (!!year) {
      url += queryParamAdded ? "&" : "?";
      url += `year=${year}`;
    }

    return this.http.get(url, { responseType: "blob" as "json" });
  }
 
}
