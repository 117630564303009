import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-update-indemnitybenefit-benefit',
    templateUrl: './update-indemnitybenefit.component.html'
})
export class UpdateIndemnityBenefitComponent {
    selectedIndemnityBenefitId: number;
    constructor(private _route: ActivatedRoute, private _router: Router) {
      this._route.params.subscribe(params => { this.selectedIndemnityBenefitId = params['indemnityBenefitId']; });
    }

    showAllIndemnityBenefits() {
      this._router.navigate(['/admin/indemnitybenefits']);
    }
}
