import { DatePipe, Location } from "@angular/common";
import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, NgForm, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material";
import { ActivatedRoute, Router } from "@angular/router";
import { first, map } from "rxjs/operators";
import { AddressValidateModel } from "src/app/models/addressValidateModel";
import { EmployeesService } from "src/app/services/employees.service";
import { GroupService } from "src/app/services/group.service";
import { MemberService } from "src/app/services/member.service";
import { SharedService } from "src/app/services/shared.service";
import { SignatureService } from "src/app/services/signature.service";
import { MustMatch } from "src/app/helpers/must-match.validators";
import { AlertifyService } from "src/app/services/alertify.service";
import { EnrollmentService } from "src/app/services/enrollment.service";
import { environment } from "src/environments/environment";
import { Member } from 'src/app/models/member';

@Component({
  selector: "app-member-enrollment",
  templateUrl: "./enrollment.component.html",
  styleUrls: ["./enrollment.component.css"],
})
export class MemberEnrollmentComponent implements OnInit {
  showssn:boolean = false;
  memberSSN:string;
  addEmployeeForm: FormGroup;
  memberData: Member = new Member();
  memberId: string;
  ipAddress: string;
  hasInsurance: string;
  groupInfo: any;
  constructor(private _route: ActivatedRoute, private _groupService: GroupService,
    private _fb: FormBuilder, private _memberService: MemberService
  ) {

    this._route.params.subscribe(params => {
      this.memberId = params['memberid'];
    });
  }

  ngOnInit() {
    this.getMemberById();//for member details
    this.getMemberAddress(); // for address   
    this.getMemberSignature(); // signature details

  }

  getMemberById() {
    this._memberService.getMemberDetails(this.memberId).subscribe(member => {
      this.memberData = member;
      this.hasInsurance = this.memberData.insurancePolicyNo == null || this.memberData.insurancePolicyNo == '' ? "2" : "1";
      if (this.memberData.groupId != null && this.memberData.groupId != 0) {
        this.getGroupInfo(this.memberData.groupId);

      }
      this.getMemberDependents();
    });
  }
  getMemberDependents() {
    this._memberService.getMemberDependents(this.memberId).subscribe((dependents: any) => {
      console.log(dependents);
      this.memberData.dependents = dependents;
    });
  }
  getMemberAddress() {
    this._memberService.getMemberAddress(this.memberId).subscribe((address: any) => {
      this.memberData.addressLine1 = address.mailingAddress;
      this.memberData.addressLine2 = address.apartmentNumber;
      this.memberData.city = address.city;
      this.memberData.zipCode = address.zipCode;
      this.memberData.state = address.state;      
    });
  }

  getMemberSignature() {
    this._memberService.getMemberSignature(this.memberId).subscribe((signature: any) => {
      if(signature)
      {
      this.memberData.browserName = signature.browserName;
      this.memberData.browserVersion = signature.browserVersion;
      this.ipAddress = signature.ipAddress;
      this.memberData.signatureDateTime = signature.signatureDateTime;
      this.memberData.signaturePath = "data:image/png;base64," + signature.signaturePath;
      }
    });
  }
  getGroupInfo(groupId: any) {
    this._groupService.getGroupByGroupId(Number(groupId)).subscribe(res => {
     
      if (res) {
       
        this.groupInfo = res;
      }
    })

  }
  showSSN(){
    this.showssn = !this.showssn;
    if(this.showssn){
      this._memberService.getSSNByMemberId(this.memberId).subscribe(res=>{
        this.memberSSN = res;
      })
    }
  }
}

