import { DatePipe, Location } from "@angular/common";
import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, NgForm, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material";
import { ActivatedRoute, Router } from "@angular/router";
import { first, map } from "rxjs/operators";
import { AddressValidateModel } from "src/app/models/addressValidateModel";
import { EmployeesService } from "src/app/services/employees.service";
import { GroupService } from "src/app/services/group.service";
import { MemberService } from "src/app/services/member.service";
import { SharedService } from "src/app/services/shared.service";
import { SignatureService } from "src/app/services/signature.service";
import { AddDependentComponent } from "./add-dependent/add-dependent.component";
import { MustMatch } from "src/app/helpers/must-match.validators";
import { AlertifyService } from "src/app/services/alertify.service";
import { EnrollmentService } from "src/app/services/enrollment.service";
import { LeadValidateComponent } from "./lead-validate/lead-validate.component";
import { AuthenticationService } from "src/app/services/authentication.service";

@Component({
  selector: "app-add-enrollment",
  templateUrl: "./enrollment.component.html",
  styleUrls: ["./enrollment.component.css"],
})
export class EnrollmentComponent implements OnInit {
  planId: number;
  selectedPlanName: string;
  selectedGroup: string;
  leadId: number;
  isValidLead: boolean = false;
  leadForm: FormGroup;
  currentUser: any;
  
  isEnrollmentValid: boolean = false;
  validateInprogress: boolean = true;

  groupId: string;
  memberId: string;
  isCheckboxChecked: boolean = false;
  plan: any;
  isHealthInsurance: boolean;
  addEmployeeForm: FormGroup;
  loading = false;
  submitted = false;
  successMessage = "";
  errormsg = "";
  IsAddressMsgTrue = "";
  IsAddressMsgFalse = "";
  errorMessage: string[] = [];
  signature: string;
  AllStates: any[];
  AllPlans: any[];
  groupIdForAddMember: string;
  isAddressVerified: boolean = false;
  addressValidateModel: AddressValidateModel;
  dependentList: any[] = [];
  city: string;
  state: string;
  routingMessage: string;
  isValidRoutingNumber: boolean;
  minPrimaryAge: string;
  maxPrimaryAge: string;
  isMinPrimaryAgeValid: boolean = true;
  isMaxPrimaryAgeValid: boolean = true;

  constructor(
    private _ActivatedRoute: ActivatedRoute,
    private _sharedService: SharedService,
    private location: Location,
    private _fb: FormBuilder,
    private _employeeService: EmployeesService,
    private signatureService: SignatureService,
    private enrollmentService: EnrollmentService,
    private memberService: MemberService,
    private groupService: GroupService,
    private _router: Router,
    private dialog: MatDialog,   
    private authenticationService: AuthenticationService,
    private _alertify: AlertifyService,
  ) {

  }

  ngOnInit() {
    this.addressValidateModel = {
      address1: "",
      address2: "",
      city: "",
      state: "",
      postal: ""
    };
    this.currentUser = this.getCurrentUserFromLocalStorage();
    this._ActivatedRoute.queryParamMap.subscribe((param) => {
      this.groupId = param.get('groupid');
      this.planId = Number(param.get('planid'));
      this.leadId = Number(param.get('leadid'));
    });
    this.bindAllPlans();
    this.bindAllStates();
    if (this.leadId != null && this.leadId != undefined && this.leadId != 0) {
      this.openLeadValidationPopup();
    }
    if (this.planId != null && this.planId != undefined && this.planId != 0) {
      this.getExternalIDFromGroup();
    }
    if (this.leadId == 0 && this.planId == 0) {
      this._router.navigate(['enrollment/error'], {
        queryParams: { error: 'Invalid Link' },
      });
    }
    this.plan = this._sharedService.getData();
    this.addEmployeeForm = this._fb.group({
      id: [""],
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      addressLine1: ["", Validators.required],
      addressLine2: [""],
      middleName: [""],
      planId: ["", Validators.required],
      zipCode: ["", Validators.required],
      mobile: ["", Validators.required],
      secondaryMobile: [""],
      workNumber: [""],
      email: ["", [Validators.required, Validators.email]],
      dob: ["", Validators.required],
      gender: ["", Validators.required],
      maritalStatus: ["", Validators.required],
      ssn: ["", Validators.required],
      hasInsurance: ["1", Validators.required],
      healthInsuranceCompanyName: ["", Validators.required],
      insurancePolicyNo: ["", Validators.required],
      insuranceEffectiveDate: ["", Validators.required],
      insuranceEndDate: ["", Validators.required],
      insuranceAddress: ["", Validators.required],
      accountNumber: ["", Validators.required],
      confirmAccountNumber: ["", Validators.required],
      routingNumber: ["", Validators.required],
      signatureName: ["", Validators.required],
      signaturePath: ["", Validators.required],
      signatureDateTime: new Date().toISOString().slice(0, 10),
      isHealthInsurance: [null],
      groupExternalId: this.groupId,
      accountType: ["", Validators.required],
      browserName: [""],
      browserVersion: [""],
      city: [""],
      state: [""],
      termsAccepted: [false]
    },
      {
        validator: MustMatch('accountNumber', 'confirmAccountNumber'),
      });

    this.groupService.getAllGroups().subscribe((response) => {
      const allGroups: any = response;
      const validGroupIds = allGroups.map((group) => group.id);
     
      // if (!validGroupIds.includes(this.groupIdAsInt)) {
      //   this._router.navigate(['enrollment/error'], {
      //     queryParams: { error: 'Invalid Link' },
      //   });
      //   return false;
      // }

      return true;
    }
    );
    var minPA = new Date();
    minPA.setFullYear(minPA.getFullYear() - 100);
    var yr = minPA.getFullYear();
    var mn = (minPA.getMonth() + 1) > 9 ? (minPA.getMonth() + 1) : '0' + (minPA.getMonth() + 1);
    var dt = minPA.getDate();
    this.minPrimaryAge = yr + '-' + mn + '-' + dt;
    var maxPA = new Date();
    maxPA.setFullYear(maxPA.getFullYear() - 21);
    yr = maxPA.getFullYear();
    mn = (maxPA.getMonth() + 1) > 9 ? (maxPA.getMonth() + 1) : '0' + (maxPA.getMonth() + 1);
    dt = maxPA.getDate();
    this.maxPrimaryAge = yr + '-' + mn + '-' + dt;

  }
  back() {
    this.location.back();
  }

  get f() {
    return this.addEmployeeForm.controls;
  }

  handleClick(value: boolean) {
    this.isHealthInsurance = value;
  }


  CheckingAddressValidation() {
    this.addressValidateModel.address1 = this.addEmployeeForm.value.address1;
    this.addressValidateModel.address2 = this.addEmployeeForm.value.address2;
    this.addressValidateModel.postal = this.addEmployeeForm.value.zipcode;
    this._employeeService.ValidateAddress(this.addressValidateModel).subscribe(
      (response) => {
        this.isAddressVerified = response.isValid;
        if (this.isAddressVerified == true) {
          this.IsAddressMsgFalse = ""
          this.IsAddressMsgTrue = "Address Verified"
        }
        else if (this.isAddressVerified == false) {
          this.IsAddressMsgTrue = ""
          this.IsAddressMsgFalse = "Invalid Address"
        }
      }
    )
  }

  getCurrentUserFromLocalStorage() {
    // Get the currentUser from localStorage (this logic may vary based on your implementation)
    const currentUser = localStorage.getItem('currentUser');
    return currentUser ? JSON.parse(currentUser) : null;
  }

  getExternalIDFromGroup() {
    this.groupService.getGroupDetailsByID(this.groupId).subscribe(
      (response) => {
        this.groupIdForAddMember = response.externalId;
        this.selectedGroup = response.name;
      }, error => {
        console.log(error.error);
        //this._alertify.error(error.error);
        this.authenticationService.currentUser.subscribe(x => {
          this.currentUser = x;
          if (!!this.currentUser) {
            if(this.authenticationService.isAdmin){
              this.back();
            }
            else {
              this._router.navigate(['enrollment/error'], {
                queryParams: { error: error.error },
              });
            }
          } 
          else {
            this._router.navigate(['enrollment/error'], {
              queryParams: { error: error.error },
            });
          }
        });          
      }
    )
  }

  getGroupByGroupId() {
    this.groupService.getGroupByGroupId(Number(this.groupId)).subscribe(
      (response) => {
        this.groupIdForAddMember = response.externalId;
        this.selectedGroup = response.name;
        this.f.groupExternalId.setValue(response.externalId);
      }, error => {
        console.log(error.error);
        //this._alertify.error(error.error);
        this.authenticationService.currentUser.subscribe(x => {
          this.currentUser = x;
          if (!!this.currentUser) {
            if(this.authenticationService.isAdmin){
              this.back();
            }
            else {
              this._router.navigate(['enrollment/error'], {
                queryParams: { error: error.error },
              });
            }
          } 
          else {
            this._router.navigate(['enrollment/error'], {
              queryParams: { error: error.error },
            });
          }
        }); 
      }
    )
  }  

  bindAllStates() {
    this._employeeService.getAllStates().subscribe((response) => {
      this.AllStates = response;
    })
  }

  bindAllPlans() {
    this._employeeService.getAllPlans().subscribe((response) => {
      this.AllPlans = response;
      const plan = this.AllPlans.find(x => x.id === this.planId);
      if (plan) {
        // Set the plan name to a property in your component
        this.selectedPlanName = plan.name + '-' + plan.monthlyPremiumAmount;
        this.validateInprogress = false;
        this.isEnrollmentValid = true;
        this.submitted = false;
      }
      //this.addEmployeeForm.get('plans').setValue(this.AllPlans);
    })
  }

  onCheckboxChange() {
    this.isCheckboxChecked = !this.isCheckboxChecked;
  }

  isSubmitDisabled() {
    return !this.isCheckboxChecked;
  }
  
  dobValidate() {
    const minDate = new Date();
    minDate.setFullYear(minDate.getFullYear() - 100);
    const maxDate = new Date();
    maxDate.setFullYear(maxDate.getFullYear() - 21);
    const dob = new Date(this.addEmployeeForm.controls.dob.value);
    if (dob < minDate) {
      this.isMaxPrimaryAgeValid = false;
    }
    else {
      this.isMaxPrimaryAgeValid = true;
    }

    if (dob > maxDate) {
      this.isMinPrimaryAgeValid = false;
    }
    else {
      this.isMinPrimaryAgeValid = true;
    }
  }
  submitOnEnter(event) {
    if (event.keyCode === 13) {
      this.onSubmit(); //calling submit function on enter
    }
  }
  onSubmit() {
    if (this.addEmployeeForm.controls.hasInsurance.value == 1) {
      this.addEmployeeForm.get('healthInsuranceCompanyName').setValidators([Validators.required]);
      this.addEmployeeForm.get('healthInsuranceCompanyName').updateValueAndValidity();
      this.addEmployeeForm.get('insurancePolicyNo').setValidators([Validators.required]);
      this.addEmployeeForm.get('insurancePolicyNo').updateValueAndValidity();
      this.addEmployeeForm.get('insuranceAddress').setValidators([Validators.required]);
      this.addEmployeeForm.get('insuranceAddress').updateValueAndValidity();
      this.addEmployeeForm.get('insuranceEffectiveDate').setValidators([Validators.required]);
      this.addEmployeeForm.get('insuranceEffectiveDate').updateValueAndValidity();
      this.addEmployeeForm.get('insuranceEndDate').setValidators([Validators.required]);
      this.addEmployeeForm.get('insuranceEndDate').updateValueAndValidity();
    } else {
      this.addEmployeeForm.get('healthInsuranceCompanyName').clearValidators();
      this.addEmployeeForm.get('healthInsuranceCompanyName').updateValueAndValidity();
      this.addEmployeeForm.get('insurancePolicyNo').clearValidators();
      this.addEmployeeForm.get('insurancePolicyNo').updateValueAndValidity();
      this.addEmployeeForm.get('insuranceAddress').clearValidators();
      this.addEmployeeForm.get('insuranceAddress').updateValueAndValidity();
      this.addEmployeeForm.get('insuranceEffectiveDate').clearValidators();
      this.addEmployeeForm.get('insuranceEffectiveDate').updateValueAndValidity();
      this.addEmployeeForm.get('insuranceEndDate').clearValidators();
      this.addEmployeeForm.get('insuranceEndDate').updateValueAndValidity();
      this.addEmployeeForm.controls.healthInsuranceCompanyName.setValue('');
      this.addEmployeeForm.controls.insurancePolicyNo.setValue('');
      this.addEmployeeForm.controls.insuranceAddress.setValue('');
      this.addEmployeeForm.controls.insuranceEffectiveDate.setValue(null);
      this.addEmployeeForm.controls.insuranceEndDate.setValue(null);
    }


    this.submitted = true;
    
    
    this.errormsg = "";
    this.signature = this.signatureService.getSignatureData()
    if (this.signature != undefined) {
      const splitString = this.signature.split("data:image/png;base64,");
      this.addEmployeeForm.controls.signaturePath.setValue(splitString[1]);
    }

    if (!this.isMaxPrimaryAgeValid || !this.isMinPrimaryAgeValid) {
      return;
    }
    if(!this.isValidRoutingNumber){
      return;
    }
    this.addEmployeeForm.controls.termsAccepted.setValue(this.isCheckboxChecked.toString());
    this.addEmployeeForm.controls.planId.setValue(this.planId);
    var browserinfo = this.getBrowserInfo().split(' ');
    this.addEmployeeForm.controls.browserName.setValue(browserinfo[0]);
    this.addEmployeeForm.controls.browserVersion.setValue(browserinfo[1]);
    this.addEmployeeForm.controls.city.setValue(this.city);
    this.addEmployeeForm.controls.state.setValue(this.state);
    if (!this.addEmployeeForm.valid || !this.isCheckboxChecked) {
      return;
    }

    

    this.loading = true;
    this.memberService.AddMemberToDB(this.groupIdForAddMember, this.addEmployeeForm.value, this.dependentList)
      .pipe(first())
      .subscribe(
        (response) => {
          const jsonString: string = JSON.stringify(response);
          const responseArray: any = JSON.parse(jsonString);
          const idValue: string = responseArray.externalId;
          this.check(idValue, this.groupIdForAddMember);
          this.loading = false;
        },
        (error) => {
          if (error.error instanceof Array) {
            this._alertify.error(error.error);
            error.error.map((err) => this.errorMessage.push(err.value));
          } else if (typeof error.error === "string") {
            this.errorMessage.push(error.error);
            this._alertify.error(error.error);
          } else {
            this.errorMessage.push(error);
            this._alertify.error(error.error);
          }
          this.loading = false;
        }
      );

  }

  check(memberId: string, groupId: string) {
    this._router.navigate(['enrollment/complete'], {
      queryParams: {
        groupid: groupId,
        memberid: memberId
      }
    });
  }

  openAddDependent(event: any) {
    event.preventDefault(); 
    const dailogRef = this.dialog.open(AddDependentComponent, {
      width: '500px',
      height: 'auto',
      panelClass: 'custom-modalbox',
      data: { dependentList: this.dependentList },
      disableClose: true,
      hasBackdrop: true
    });
    dailogRef.afterClosed().subscribe(result => {
      if (result) {
        result.memberId = 0;
        this.dependentList.push(result);       
      }
    });
  }

  removeDependent(item: any, index: number) {
    this.dependentList.splice(index, 1);
  }
  getCityStatebyZip(item: any, index: number) {
    this.dependentList.splice(index, 1);
  }

  handleOnChange(event: any) {
    if (event.target.value.length >= 5) {
      let xml: string = '<CityStateLookupRequest USERID="8H6THINK42633"><ZipCode ID="0"><Zip5>' + event.target.value + '</Zip5></ZipCode></CityStateLookupRequest>';
      this.memberService.getCityStateByZip(xml).subscribe((res) => {
        if (res) {
          this.city = res.zipCode.city;
          this.state = res.zipCode.state;
        }
      });
    }
  }

  getBrowserInfo() {
    var ua = navigator.userAgent;
    var tem;
    var M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || []; return 'IE ' + (tem[1] || '');
    }
    if (M[1] === 'Chrome') {
      tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
      if (tem != null)
        return tem.slice(1).join(' ').replace('OPR', 'Opera');
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
    return M.join(' ');
  }
  getAddressFromRoutingNumber() {
    var _this = this;
    this.enrollmentService.validateRoutingNumber(this.addEmployeeForm.value.routingNumber)
      .pipe(first())
      .subscribe(
        (res: any) => {
          _this.routingMessage = res.message;
          _this.isValidRoutingNumber = true;
        },
        (error) => {
          _this.isValidRoutingNumber = false;
          _this.routingMessage = error.error.message;
        }
      );
  }


  getLeadById() {
    this.enrollmentService
      .GetLeadById(this.leadId)
      .pipe(first())
      .subscribe((res1: any) => {
        if (res1 != null) {
          this.setLeadData(res1);
        }
      });
  }
  setLeadData(data: any) {
    this.f.firstName.setValue(data.firstName);
    this.f.lastName.setValue(data.lastName);
    this.f.middleName.setValue(data.middleName);
    this.f.dob.setValue(data.dob.slice(0, 10));
    this.f.email.setValue(data.email);
    this.f.planId.setValue(data.planId);
    this.planId = data.planId;
    this.bindAllPlans();
    this.f.mobile.setValue(data.mobile);
    this.f.gender.setValue(data.gender);
    this.groupId = data.groupId;
    this.getGroupByGroupId();
  }

  openLeadValidationPopup() {
    const dailogRef = this.dialog.open(LeadValidateComponent, {
      width: '500px',
      height: 'auto',
      data: this.leadId,
      disableClose: true,
      hasBackdrop: false
    });
    dailogRef.afterClosed().subscribe(result => {
      if (result) {
        this.validateInprogress = false;
        this.isEnrollmentValid = true;
        this.submitted = false;
        this.getLeadById();
      }
      else {
        this._router.navigate(['enrollment/error'], {
          queryParams: { error: 'Please validate date of birth before enrollment' },
        });
      }
    });
  }

}
