import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';
import { UserToken } from '../models/UserToken';
import { AuthenticationService } from './authentication.service';

@Injectable({ providedIn: 'root' })
export class JWTTokenService {
    jwtToken: string;

    constructor() { }

    setToken(token: string) {
        if (token) {
            this.jwtToken = token;
        }
    }

    get decodeToken() {
        return this.jwtToken ? jwt_decode(this.jwtToken) : {};
    }

    getDecodeToken() {
        return jwt_decode(this.jwtToken);
    }

    getUser() {
        return this.decodeToken['unique_name'];
    }

    getUserRoles(): string[] {
        const roles = this.decodeToken['role'];
        return !!roles ? roles.split(',') : [];
    }

    getEmailId() {
        return this.decodeToken['email'];
    }

    getExpiryTime() {
        return this.decodeToken['exp'];
    }

    isTokenExpired(): boolean {
        const expiryTime = Number(this.getExpiryTime());
        if (expiryTime) {
            return ((1000 * expiryTime) - (new Date()).getTime()) < 5000;
        } else {
            return false;
        }
    }
}
