import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Plan } from "src/app/models/plan";
import { PlanService } from "src/app/services/plan.service";
import { SharedService } from "src/app/services/shared.service";

@Component({
  selector: "app-enrollment",
  templateUrl: "./enrollment.component.html",
  styleUrls: ["./enrollment.component.scss"]
})
export class EnrollmentComponent implements OnInit {
  planId;
  selectedPlanId;
  plans: Plan[];
  model: any = {};
  selectedPlan;
  isDisable:boolean = true
  changePlanForm: FormGroup;
  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _planService: PlanService,
    private _sharedService: SharedService
  ) {}

  ngOnInit() {
    this._activatedRoute.queryParamMap.subscribe((params) => {
      this.planId = params.get("id");
    });

    this.changePlanForm = new FormGroup({
      plan: new FormControl(""),
    });
    this.getAllPlans(this.planId);
  }

  getAllPlans(planId) {
    this._planService.getAllPlans().subscribe((plans) => {
      this.plans = plans;
    });
    this._planService.getPlanById(planId).subscribe((res) => {
      this.selectedPlan = res;
      this.selectedPlanId = res.id;
      this.model.monthlyPremiumAmount = res.monthlyPremiumAmount;
    });
  }

  changePlan(event) {
    this.planId = event.target.value;
    this.getAllPlans(this.planId);
  }

  remove() {
    this.planId = null;
    this.changePlanForm.get("plan").reset();
    this.model.monthlyPremiumAmount = 0;
  }

  // Route Static- groupId/memberId/planId
  AddUpdateEmployee(planId: string) {
    this._sharedService.setData(this.selectedPlan);
    this.planId != null
      ? this._router.navigate(['admin/addemployee'], {
        queryParams: {
          groupid: 1,
          id: planId
        }
      })
      : alert("Please Select at least one plan");
  }
  Update() {
    this.isDisable = false;
    console.log('this.isDisable: ', this.isDisable);
  }
}
