import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { getApiUrl } from '../helpers/get-url';
import { Claim } from '../models/claim';
import { ClaimLineItem, ClaimPayment } from '../models/claimPayment';
import { Data } from '@angular/router';

@Injectable({
  providedIn: "root",
})
export class ClaimPaymentService {
  constructor(private http: HttpClient) {}

  
  getClaimPayments(claimid: string): Observable<Claim[]> {
    return this.http.get<Claim[]>(getApiUrl(`ClaimPayment?claimid=${claimid}`));
  }
  getAllClaimPayments(groupId: string): Observable<ClaimPayment[]> {
    return this.http.get<ClaimPayment[]>(
      getApiUrl(`claimPayment/${groupId}/getAllClaimPayment`)
    );
  }
  
  
  addClaimPayment(claim: ClaimPayment, groupId: string): Observable<ClaimPayment> {
    
    return this.http.post<ClaimPayment>(getApiUrl(`claimPayment/AddClaimPayment/${groupId}`), claim);
  }
  addClaimLineItem(claim: any): Observable<any> {
    return this.http.post<any>(getApiUrl(`claimPayment/AddClaimLineItem`), claim);
  }
  getMemberClaims(groupReferenceId: string, claimPaymentId: number, periodScheduleId:number): Observable<any[]> {
    return this.http.get<any[]>(
      getApiUrl(`claimPayment/${groupReferenceId}/GetMemberClaims/${claimPaymentId}/${periodScheduleId}`)
    );
  }
  getClaimLineItem(claimPaymentId:number): Observable<any[]> {
    return this.http.get<any[]>(
      getApiUrl(`claimPayment/claimLineItem/${claimPaymentId}`)
    );
  }

  getClaimPaymentById(claimPaymentId: number): Observable<ClaimPayment> {
    return this.http.get<ClaimPayment>(
      getApiUrl(`claimPayment/${claimPaymentId}/getClaimPaymentById`)
    );
  }
  getClaimLineItemById(lineItemId: number): Observable<ClaimLineItem> {
    return this.http.get<ClaimLineItem>(getApiUrl(`claimPayment/${lineItemId}`));
  }

  
  updateClaimLineItem(id:number,claimLineItem:any):Observable<any>{
    return this.http.put<any>(
      getApiUrl(`claimPayment/UpdateClaimLineItem/${id}`),claimLineItem
    )
  }
  sendToNacha(id: number, paymentDate: string): Observable<any> {
    return this.http.put<any>(
      getApiUrl(`claimPayment/sendToNacha/${id}`), { paymentDate }
    )
  }
  CancelLineItemById(lineItemId: number): Observable<any> {
    return this.http.put<any>(
      getApiUrl(`claimPayment/CancelLineItemById/${lineItemId}`),null);
  }

 
}
