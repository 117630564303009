import { Component } from '@angular/core';
import { WellnessProgressService } from 'src/app/services/wellnessProgressService.service';
import { WellnessProgress } from 'src/app/models/wellnessProgress';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ColDef } from 'ag-grid-community';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-view-view-wellnessprogress',
    templateUrl: './view-wellnessprogress.component.html'
})
export class ViewWellnessprogressComponent {
    wellnessProgresses: WellnessProgress[];
    yearOptions = [];
    monthOptions = [
      { key: 'All', value: 0 },
      { key: 'Jan', value: 1 },
      { key: 'Feb', value: 2 },
      { key: 'Mar', value: 3 },
      { key: 'Apr', value: 4 },
      { key: 'May', value: 5 },
      { key: 'Jun', value: 6 },
      { key: 'Jul', value: 7 },
      { key: 'Aug', value: 8 },
      { key: 'Sep', value: 9 },
      { key: 'Oct', value: 10 },
      { key: 'Nov', value: 11 },
      { key: 'Dec', value: 12 }]
      columnDefs: ColDef[] = [];
      filterWellnessprogressForm: FormGroup;
      defaultColDef = {
          flex: 1,
          minWidth: 150,
          resizable: true,
      };
      submitted = false;
      loading = false;

    filterParams = {
      comparator: function (filterLocalDateAtMidnight, cellValue) {
        if (cellValue == null) { return -1; }
        const dateAsString = cellValue.substr(0, 10);
        const dateParts = dateAsString.split('-');
        const cellDate = new Date(
          Number(dateParts[0]),
          Number(dateParts[1]) - 1,
          Number(dateParts[2])
        );
        if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
          return 0;
        }
        if (cellDate < filterLocalDateAtMidnight) {
          return -1;
        }
        if (cellDate > filterLocalDateAtMidnight) {
          return 1;
        }
      },
      browserDatePicker: true,
    };

  private gridApi;
  private gridColumnApi;

  constructor(private _formBuilder: FormBuilder,private _wellnessProgressService: WellnessProgressService, private _route: ActivatedRoute, private _router: Router) {
      const _this = this;
      const date = new Date();
      for (let i = 2020; i <= date.getFullYear(); i++) {
        this.yearOptions.push(i.toString());
      }

      this.filterWellnessprogressForm = this._formBuilder.group({
        wellnessProgressMonth: [date.getMonth() + 1],
        wellnessProgressYear: [date.getFullYear()]
      });

      this.getWellnessProgressForMember();

        this.columnDefs = [
            { hide: true, field: 'id' },
            {
              headerName: 'Service Date', field: 'serviceDate', suppressMovable: true, sortable: true,
              valueFormatter: function (params) {
                return !!params.value ? params.value.substr(0, 10) : '';
              },
            },
            { headerName: 'Benefit Type', field: 'indemnityBenefitName', suppressMovable: true, },
            { headerName: 'Amount', field: 'amount', suppressMovable: true, valueFormatter: params => "$" + params.data.amount }
        ];
    }

    setTotal() {
      setTimeout(() => {
        let pinnedBottomData = this.generatePinnedBottomData();
        this.gridApi.setPinnedBottomRowData([pinnedBottomData]);
      }, 2000);
    }

    onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi.setDomLayout("autoHeight");
        this.setTotal();
    }

    onFilterChanged(params) {
      this.setTotal();
    }

    generatePinnedBottomData() {
      // generate a row-data with null values
      let result = {};

      this.gridColumnApi.getAllGridColumns().forEach(item => {
        console.log(item.colId);
        if (item.colId == "indemnityBenefitName") {
          result[item.colId] = "Total";
        }
        else {
          result[item.colId] = null;
        }
        
      });
      return this.calculatePinnedBottomData(result);
    }

    calculatePinnedBottomData(target: any) {
      let columnsWithAggregation = ['amount']
      columnsWithAggregation.forEach(element => {        
        this.gridApi.forEachNodeAfterFilter((rowNode) => {
          if (rowNode.data[element])
              target[element] += Number(rowNode.data[element].toFixed(2));
          });
          if (target[element])
            target[element] = `${target[element].toFixed(2)}`;
      })
      return target;
    }

    onSubmit() {
      this.submitted = true;

      // stop here if form is invalid
      if (this.filterWellnessprogressForm.invalid) {
        return;
      }

      this.loading = true;
      this.getWellnessProgressForMember();
      this.setTotal();
    }

    getWellnessProgressForMember() {
          const wellnessProgressMonth = this.filterWellnessprogressForm.controls['wellnessProgressMonth'].value;
          const wellnessProgressYear = this.filterWellnessprogressForm.controls['wellnessProgressYear'].value;
          this._wellnessProgressService.getWellnessProgressForMember(wellnessProgressMonth, wellnessProgressYear).subscribe(wellnessProgresses => {
            this.wellnessProgresses = wellnessProgresses;
            this.loading = false;
          });
    }
}
