import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AlertService } from 'src/app/services/alertService.service';
import { BrokerService } from 'src/app/services/broker.service';

@Component({
  selector: 'app-add-broker',
  templateUrl: './add-broker.component.html',
  styleUrls: ['./add-broker.component.scss']
})
export class AddBrokerComponent implements OnInit {

  @Output()
  public agentaddedEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  brokerForm: FormGroup;
  loading = false;
  submitted = false;
  errorMessage: string[] = [];

  get f() { return this.brokerForm.controls; }

  constructor(private _formBuilder: FormBuilder, private _brokerService: BrokerService, private _router: Router, private _alertService: AlertService) { }

  ngOnInit() {
    this.brokerForm = this._formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: [''],
    });

    this.brokerForm.get('password').valueChanges
      .subscribe(password => {
        if (!!password) {
          this.brokerForm.get('password').setValidators([Validators.minLength(6)]);
        }
      });
  }

  onSubmit() {
    this.submitted = true;

    // reset alerts on submit
    this.errorMessage = [];
    this._alertService.clear();

    // stop here if form is invalid
    if (this.brokerForm.invalid) {
      return;
    }

    this.loading = true;
    this._brokerService.addBroker(this.brokerForm.value)
      .pipe(first())
      .subscribe(
        () => {
          this.loading = false;
          this._alertService.success(`Agent ${this.brokerForm.value['email']} added successfully`);
          this.agentaddedEvent.emit(true);
        },
        error => {
          if (error.error instanceof Array) {
            error.error.map(err => this.errorMessage.push(err.value));
          } else if (typeof error.error === 'string') {
            this.errorMessage.push(error.error);
          } else {
            this.errorMessage.push(error);
          }
          this.loading = false;
        });
  }

}
