import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { first } from 'rxjs/operators';
import { GroupService } from 'src/app/services/group.service';
import { User } from 'src/app/models/User';
import { ColDef } from 'ag-grid-community';
import { AlertifyService } from 'src/app/services/alertify.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { PeriodService } from '../../../../services/period.service';
import { Period } from '../../../../models/period';
import { Group } from '../../../../models/group';
import { PeriodScheduleService } from '../../../../services/periodSchedule.service';
import { ClaimPayment } from '../../../../models/claimPayment';
import { ClaimPaymentService } from '../../../../services/claimPayment.service';



@Component({
  selector: 'app-view-claimpayment',
  templateUrl: './claim-payment.component.html',
  styleUrls: ['./claim-payment.component.scss'],
})
export class ClaimPaymentComponent implements OnInit {
  @Input()
  groupRefrenceId: string;
  Id: number;
  periodScheduleId: string;
  periodId: number;
  claimForm: FormGroup;
  periodValue: string;
  state: string;
  loading = false;
  loadingBroker = false;
  groupData: Group = new Group();
  members: any;
  submitted = false;
  groupId: number;
  ShowGrid = false;
  periodScheduleList: any;
  period: Period[];


  errorMessage: string[] = [];
  successMessage = '';
  addUpdateError: string[] = [];
  columnDefs: ColDef[] = [];

  defaultColDef = {
    flex: 1,
    minWidth: 150,
    resizable: true,
    cellDataType: 'text'
  };
  claimFormData: any;
  claimPaymentId: number;
  private gridApi;
  selectedPeriod: string;
  step = 1;
  constructor(
    private _groupService: GroupService, private _datepipe: DatePipe,
    private _formBuilder: FormBuilder,
    private _claimService: ClaimPaymentService,
    private _periodService: PeriodService,
    private _fb: FormBuilder, private _periodScheduleService: PeriodScheduleService,
    private _alertify: AlertifyService,
    private _router: Router, private _route: ActivatedRoute
  ) {

    this._route.params.subscribe(params => {
      this.groupRefrenceId = params['id'];
    });
    const _this = this;
    this.columnDefs = [
      { hide: true, field: 'referenceId' },
      { hide: true, field: 'claimId' },
      { hide: true, field: 'planId' },
      { hide: true, field: 'id' },

      {
        headerName: 'Member Id', field: 'memberId', suppressMovable: true, maxWidth: 180, sortable: true, filter: true
      },
      {
        headerName: 'Name', field: 'memberName', suppressMovable: true, maxWidth: 150, sortable: true, filter: true
      },
      {
        headerName: 'Payment Amount', field: 'paymentAmount', sortable: true, maxWidth: 300, filter: true, editable: true
      },
      { headerName: 'Notes', field: 'notes', sortable: true, maxWidth: 100, filter: true, editable: true },

      //showClaimBtn
    ];
  }

  ngOnInit() {

    this.claimForm = this._formBuilder.group({
      id: [0],
      /*name: ["", Validators.required],*/
      periodStartDate: ["", [Validators.required]],
      periodEndDate: ["", [Validators.required]],
      claimStartDate: ["", Validators.required],
      claimEndDate: ["", Validators.required],
      paymentDate: ["", Validators.required],
      // paymentPercentage: [""]
    });

    this.getGroupById();
  }

  getAllMembers(claimPaymentId, periodScheduleId) {

    this._claimService.getMemberClaims(this.groupRefrenceId, claimPaymentId,Number(periodScheduleId)).subscribe((members) => {
      this.members = members;
    });
  }
  getGroupById() {
    if (!!this.groupRefrenceId) {
      this._groupService.getGroup(this.groupRefrenceId).subscribe(group => {
        this.groupData = group;
        this.periodId = group.periodId;
        this.groupId = group.id;
        this._getAllSchedule(this.periodId);
      });

    }
  }
  _getAllSchedule(periodid) {
    this._periodScheduleService.getPeriodScheduleById(periodid).subscribe(data => {
      this.periodScheduleList = data;
    });
  }
  changePeriod(event) {
    this.ShowGrid = true;
    this.periodScheduleId = event.target.value;
    this.selectedPeriod = event.target.options[event.target.options.selectedIndex].text;
    this.setDependentForm();
  }
  next() {

    if (this.step == 1) {
      this.submitted = true;
      if (this.claimForm.invalid) { return }
      this.loading = true;
      this._claimService.addClaimPayment(this.claimForm.value, this.groupRefrenceId)
        .pipe(first())
        .subscribe(
          (data: ClaimPayment) => {

            this.loading = false;
            this.claimPaymentId = data.id;
            // this._router.navigate(['/admin/periods']);
            this.getAllMembers(this.claimPaymentId, this.periodScheduleId);
            if(this.members.length > 0){
              this._alertify.success('Claim payment added successfully.');
            }
            else{
              this._alertify.error('Matching members not found.');
              this._router.navigate(['/admin/group-view/' + this.groupRefrenceId]);
            }
          },
          error => {
            if (error.error instanceof Array) {
              error.error.map(err => this.errorMessage.push(err.value));
            } else if (typeof error.error === 'string') {
              this.errorMessage.push(error.error);
            } else {
              this.errorMessage.push(error);
            }
            this.loading = false;
          });
      this.step++
    }
    if (this.step == 2) {



    }
  }

  submit() {

    if (this.step == 2) {
      this.submitted = true;
      this.loading = true;
      if (this.members.length > 0) {
        let convertedArray = this.members.map(item => ({ ...item, paymentAmount: item.paymentAmount.toString() }));

        this._claimService.addClaimLineItem(convertedArray)
          .pipe(first())
          .subscribe(
            () => {
              this.loading = false;
              this._alertify.success('Claim line item added successfully.');
              this._router.navigate(['/admin/claim-payment-view/' + this.claimPaymentId]);
            },
            error => {
              if (error.error instanceof Array) {
                error.error.map(err => this.errorMessage.push(err.value));
              } else if (typeof error.error === 'string') {
                this.errorMessage.push(error.error);
              } else {
                this.errorMessage.push(error);
              }
              this.loading = false;
            });
      }
    }
  }


  setDependentForm() {
    this.periodScheduleList.forEach(periodSchedule => {
      if(periodSchedule.id == this.periodScheduleId){
        this.claimForm.controls["claimStartDate"].patchValue(
          this._getDate(periodSchedule.claimStartDate)
        );
        this.claimForm.controls["claimEndDate"].patchValue(
          this._getDate(periodSchedule.claimEndDate)
        );
        this.claimForm.controls["paymentDate"].patchValue(
          this._getDate(periodSchedule.claimPaymentDate)
        );
        this.claimForm.controls["periodStartDate"].patchValue(
          this._getDate(periodSchedule.startDate)
        );
        this.claimForm.controls["periodEndDate"].patchValue(
          this._getDate(periodSchedule.endDate)
        );
      }
    });

  }

  // convenience getter for easy access to form fields
  get f() {
    return this.claimForm.controls;
  }
  formatter = (x: User) => `${x.firstName} ${x.lastName} (${x.userName})`;

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridApi.setDomLayout("autoHeight");
  }
  onSubmit() {
    this.submitted = true;
    this.successMessage = '';
    this.addUpdateError = [];

    if (this.claimForm.invalid) {
      return;
    }

    this.loading = true;
    this._claimService.addClaimPayment(this.claimForm.value, this.groupRefrenceId)
      .pipe(first())
      .subscribe(
        () => {
          this.loading = false;
          this._alertify.success('Claim payment added successfully.');
          this._router.navigate(['/admin/periods']);
        },
        error => {
          if (error.error instanceof Array) {
            error.error.map(err => this.errorMessage.push(err.value));
          } else if (typeof error.error === 'string') {
            this.errorMessage.push(error.error);
          } else {
            this.errorMessage.push(error);
          }
          this.loading = false;
        });
  }
  private _getDate(date: Date): string {
    return this._datepipe.transform(date, "yyyy-MM-dd");
  }

}

