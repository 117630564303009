import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-add-dependent',
  templateUrl: './add-dependent.component.html',
  styleUrls: ['./add-dependent.component.css']
})
export class AddDependentComponent implements OnInit {

  addDependentForm: FormGroup;
  constructor(private _fb: FormBuilder,
    public dialogRef: MatDialogRef<AddDependentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.addDependentForm = this._fb.group({
      relationship:["",Validators.required],
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      ssn: ["", Validators.required],
      dob: ["", Validators.required],
      gender: ["", Validators.required],
    });
  }

  handleOnClose(event: any) {
    this.dialogRef.close(false);
  }

  handleOnSave(event: any) {
    if (this.addDependentForm.valid) {
      this.dialogRef.close(this.addDependentForm.value);
    }
  }
}
