import { DatePipe } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MemberService } from 'src/app/services/member.service';
import { PlanService } from 'src/app/services/plan.service';
import { Plan } from 'src/app/models/plan';
import { ValidatorsService } from '../../../../../helpers/validators.service';
import { FormatDate } from '../../../../../helpers/format';

@Component({
    selector: 'app-add-member',
    templateUrl: './add-edit-member.component.html',
    styleUrls: ['./add-edit-member.component.scss']
})
export class AddEditMemberComponent implements OnInit {
    @Input()
    groupId: string;

    @Input()
    memberId: string;

    memberForm: FormGroup;
    loading = false;
    submitted = false;
    errorMessage = '';
    successMessage = '';
    addUpdateError: string[] = [];
    plans: Plan[];

  constructor(private _formBuilder: FormBuilder, private _memberService: MemberService, private _date: FormatDate, private _planService: PlanService, private validatorsService: ValidatorsService) {
    }

    ngOnInit() {
        if (!!this.memberId) {
            this._memberService.getMemberInfo(this.memberId).subscribe(member => {

                var payPeriodText = member.payPeriod ? member.payPeriod.toString() : "";
                if (payPeriodText == "SemiMonthly") {
                   member.payPeriod = 1;
                }
                else if (payPeriodText == "Monthly") {
                   member.payPeriod = 0;
                }
                else if (payPeriodText == "Weekly") {
                   member.payPeriod = 2;
                }
                else if (payPeriodText == "BiWeekly") {
                   member.payPeriod = 3;
                }
                else
                {
                   member.payPeriod = -1;
                }

                this.memberForm.patchValue(member);
              this.memberForm.controls['dob'].patchValue(this._date.formatDate(member.dob));
              this.memberForm.controls['startDate'].patchValue(this._date.formatDate(member.startDate));
              this.memberForm.controls['terminationDate'].patchValue(this._date.formatDate(member.terminationDate));
              this.memberForm.controls['planStartDate'].patchValue(this._date.formatDate(member.planStartDate));
            });
        }

        this.memberForm = this._formBuilder.group({
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            externalId: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            dob: [],
            wellnessBenefitAmount: [0],
            startDate: [],
            terminationDate: [],
            isLyrics:[],
            planId: [null],
            planStartDate: [],
            payPeriod: [''],
            salaryPerPayPeriod: ['', this.validatorsService.nonNegativeValidator()],
        });

        this._planService.getAllPlans().subscribe(plans => {
          this.plans = plans;
        });
    }

    // convenience getter for easy access to form fields
    get f() { return this.memberForm.controls; }
  
    get isEdit() {
        return !!this.memberId && this.memberId.trim().length > 0 ? true : null;
    }

    onSubmit() {
        this.submitted = true;

        // reset alerts on submit
        this.successMessage = '';
        this.addUpdateError = [];

        // stop here if form is invalid
        if (this.memberForm.invalid) {
            return;
        }
        
        this.memberForm.value.payPeriod = this.memberForm.value.payPeriod == -1 ? null : this.memberForm.value.payPeriod;

        this.loading = true;
        if (!!this.memberId) {
            this.memberForm.value.planId = parseInt(this.memberForm.value.planId);
            console.log(this.memberForm.value);
            console.log('this.groupId, this.memberId: ', this.groupId, this.memberId);
            // return
            this._memberService.updateMember(this.groupId, this.memberId, this.memberForm.value)
                .pipe(first())
                .subscribe(
                    () => {
                        // return to list
                        this.successMessage = 'Member updated successfully.';
                        this.loading = false;
                    },
                    error => {
                        if (error.error instanceof Array) {
                            error.error.map(err => this.addUpdateError.push(err.value));
                        } else if (typeof error.error === 'string') {
                            this.addUpdateError.push(error.error);
                        } else {
                            this.addUpdateError.push(error);
                        }
                        this.loading = false;
                    });
        } else {
            this.memberForm.value.planId = parseInt(this.memberForm.value.planId);
            this._memberService.addMember(this.groupId, this.memberForm.value)
                .pipe(first())
                .subscribe(
                    (member) => {
                        // return to list
                        this.successMessage = 'Member added successfully.';
                        this.loading = false;
                        this.memberId = member.externalId
                    },
                    error => {
                        if (error.error instanceof Array) {
                            error.error.map(err => this.addUpdateError.push(err.value));
                        } else if (typeof error.error === 'string') {
                            this.addUpdateError.push(error.error);
                        } else {
                            this.addUpdateError.push(error);
                        }
                        this.loading = false;
                    });
        }
    }
}


