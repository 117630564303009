import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-update-family-members",
  templateUrl: "./update-family-members.component.html",
})
export class UpdateFamilyMembersComponent {
  selectedId: string;
  selectedMembersReferenceId: string;
  header: string;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
  ) {
    this._route.params.subscribe((params) => {
      this.selectedId = params["id"];
    });
    this._route.params.subscribe((params) => {
      this.selectedMembersReferenceId = params["memberId"];
     
    });
  }

  showAllMembers() {
    this._router.navigate([
      `/admin/groups/${this.selectedMembersReferenceId}/familyMembers`,
    ]);
  }
}
