import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { getApiUrl } from '../helpers/get-url';
import { Plan } from '../models/plan';
import { Observable } from 'rxjs/internal/Observable';
import { StringResponse } from '../models/stringResponse';

@Injectable({ providedIn: 'root' })
export class PlanService {
  constructor(private http: HttpClient) { }

  getAllPlans(): Observable<Plan[]> {
    return this.http.get<Plan[]>(getApiUrl("plans/GetAllPlans"));
  }

  getPlanById(planId: number) {
    return this.http.get<Plan>(getApiUrl(`plans/${planId}`));
  }

  addPlan(plan: Plan): Observable<Plan> {
    return this.http.post<Plan>(getApiUrl('plans'), plan);
  }

  updatePlan(planId: number, plan: Plan): Observable<Plan> {
    return this.http.put<Plan>(getApiUrl(`plans/${planId}`), plan);
  }

  deletePlan(planId: number) {
    return this.http.delete(getApiUrl(`plans/${planId}`));
  }
}
