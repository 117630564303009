import { Component } from '@angular/core';
@Component({
    selector: 'layout-form',
    templateUrl: './layout-form.ctrl.html',
    styleUrls: ['./layout-form.ctrl.scss']
})
/**
 * Layout form control used as a common style for the two panel layout setup
 */
export class LayoutFormControl {

}