import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Videos } from 'src/app/models/videos';
import { VideoService } from 'src/app/services/video.service';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss']
})
export class VideoPlayerComponent implements OnInit {
  selectedVideoId: string;
  currentSelectedVideo: Videos;
  constructor(private _route: ActivatedRoute, private videoService: VideoService) {
    this._route.params.subscribe(params => { this.selectedVideoId = params['videoId']; });

  }

  ngOnInit() {
    this.videoService.getVideoById(this.selectedVideoId).subscribe(video => this.currentSelectedVideo = video);
  }

}
