import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-invalid-enrollment-link',
  templateUrl: './invalid-enrollment-link.component.html',
  styleUrls: ['./invalid-enrollment-link.component.css']
})
export class InvalidEnrollmentLinkComponent implements OnInit {

  message:string;
  currentUser:any;
  constructor(private _ActivatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this._ActivatedRoute.queryParamMap.subscribe((param) => {
      this.message = param.get('error');
    });
    this.currentUser = this.getCurrentUserFromLocalStorage();
  }

  getCurrentUserFromLocalStorage() {
    // Get the currentUser from localStorage (this logic may vary based on your implementation)
    const currentUser = localStorage.getItem('currentUser');
    return currentUser ? JSON.parse(currentUser) : null;
  }

}
