import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { catchError, debounceTime, distinctUntilChanged, first, map, switchMap, tap } from 'rxjs/operators';
import { GroupService } from 'src/app/services/group.service';
import { ColDef } from 'ag-grid-community';
import { AlertifyService } from 'src/app/services/alertify.service';
import { ActivatedRoute, Route, Router, UrlSerializer } from '@angular/router';
import { DatePipe, Location } from '@angular/common';
import { PeriodService } from '../../../../services/period.service';
import { Period } from '../../../../models/period';
import { Group } from '../../../../models/group';
import { Member } from '../../../../models/member';
import { MemberStatus } from '../../../../models/enums/memberStatus';
import { ClaimService } from 'src/app/services/claim.service';
import { MatDialog } from '@angular/material/dialog';
import { PopupComponent } from '../../../pop-up/popup/popup.component';



@Component({
  selector: 'app-view-group',
  templateUrl: './group-view.component.html',
  styleUrls: ['./group-view.component.scss'],
})
export class GroupViewComponent implements OnInit {
  @Input()
  groupRefrenceId: string;
  Id: number;
  periodId: number;
  claimForm: FormGroup;
  showClaimList = false;
  periodValue: string;
  state: string;
  city: string;
  loading = false;
  loadingBroker = false;
  groupData: Group = new Group();
  members: Member[];
  submitted = false;
  groupId: number;
  ShowGrid = false;
  periodScheduleList: any;
  period: Period[];
  
  externalId: string;
  errorMessage: string[] = [];
  successMessage = '';
  addUpdateError: string[] = [];
  columnDefs: ColDef[] = [];
  selectedEnumValue: MemberStatus = MemberStatus.All; // Set the default selected value
  defaultColDef = {
    flex: 1,
    minWidth: 150,
    resizable: true,
  };
  claimFormData: any;
  baseUrl: string = '';
  private gridApi;
  personalDetails!: FormGroup;
  addressDetails!: FormGroup;
  educationalDetails!: FormGroup;
  personal_step = false;
  address_step = false;
  education_step = false;
  step = 1;
  constructor(
    private _groupService: GroupService, private _datepipe: DatePipe, private location: Location,
    private _formBuilder: FormBuilder, private urlSerializer: UrlSerializer,
    private _claimService: ClaimService,
    private _periodService: PeriodService,
    private _fb: FormBuilder,
    private _alertify: AlertifyService, private dialog: MatDialog,
    private _router: Router, private _route: ActivatedRoute
  ) {

    this._route.params.subscribe(params => {
      this.groupRefrenceId = params['id'];
    });

  }
  activeTab = 'member';

  member(activeTab) {
  
    this.activeTab = activeTab;
  }

  claim(activeTab) {
    this.showClaimList = true;
    this.activeTab = activeTab;
  }
  ngOnInit() {
    this.getGroupById();
  }
  redirectToGroupEdit() {
    this._router.navigate([`/admin/groups/${this.groupRefrenceId}`]);
  }
  getGroupById() {
    if (!!this.groupRefrenceId) {
      this._groupService.getGroup(this.groupRefrenceId).subscribe(group => {
        
        this.groupData = group;
        if (group != null) {
          this.city = group.city;
          this.state = group.state;
        }
        this.externalId= group.externalId;
        //this.periodId = group.periodId;
        //this.groupId = group.id;
        this.getPeriods();
        
      });

    }
  }
  showPayment() {//claim-payment/:id
    this._router.navigate([`/admin/claim-payment/` + this.groupRefrenceId]);
  }
  showInoicePayment() {//claim-payment/:id
    this._router.navigate([`/admin/invoice/` + this.groupRefrenceId]);
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.claimForm.controls;
  }
  
  getPeriods() {
    this._periodService
      .getAllPeriods()
      .subscribe((periods) => {
        if (periods.length > 0) {
          this.period = periods.filter(x => parseInt(x.id) === this.groupData.periodId).map(x => x.name);
        }
      });
  }
  OnRedirectToLeadList() {
    let groupid = this.groupRefrenceId;
    this._router.navigate(['admin/groups/' + this.externalId + '/lead-list'])
  }
  // copy data on button click
  copyData() {
    this.successMessage = '';
    this.baseUrl = this.getBaseUrl();
    const routeUrlTree = this._router.createUrlTree(['/plans/selection'], {
      queryParams: { groupid: this.externalId },
    });
    const urlString = this.urlSerializer.serialize(routeUrlTree);
    this.copyTextToClipboard(this.baseUrl + urlString);
    this.successMessage = 'Link copied successfully.';
    // alert(this.successMessage)
    this.dialog.open(PopupComponent, {
      data: this.successMessage,
      panelClass: 'custom-dialog-container'
    });
  }
  // get dynamic url
  private getBaseUrl(): string {
    const protocol = window.location.protocol;
    const hostname = window.location.hostname;
    const port = window.location.port;
    // Use the detected protocol, hostname, and port to form the base URL
    return `${protocol}//${hostname}${port ? ':' + port : ''}`;
  }


  // copy data 
  private copyTextToClipboard(text: string) {
    const dummyInput = document.createElement('input');
    dummyInput.style.position = 'fixed';
    dummyInput.style.opacity = '0';
    dummyInput.value = text;
    document.body.appendChild(dummyInput);

    dummyInput.select();
    document.execCommand('copy');
    document.body.removeChild(dummyInput);
  }
  back() {
    this.location.back();
  }
}

