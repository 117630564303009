import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ColDef } from "ag-grid-community";
import { GroupService } from "src/app/services/group.service";
import { MemberService } from "src/app/services/member.service";
import { BtnCellRendererComponent } from "src/app/shared/controls/renderers/button-renderer/btn.renderer";
import { FamilyMembers } from "src/app/models/family-member";
import moment from "moment";
@Component({
  selector: "app-group-members-family-list",
  templateUrl: "./group-members-family-list.component.html",
  styleUrls: ["./group-members-family-list.component.scss"],
})
export class GroupMembersFamilyListComponent {
  memberId: string;
  members: FamilyMembers[];
  header: string = "Family Members";
  headerForAddMember: string = "Family Members";
  addingFamilyMember = false;
  selectedMemberId;

  defaultColDef = {
    flex: 1,
    minWidth: 150,
    resizable: true,
  };
  columnDefs: ColDef[] = [];

  private _gridApi;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _groupService: GroupService,
    private _memberService: MemberService
  ) {
    this._route.params.subscribe((params) => {
      this.memberId = params["memberId"];
    });
    this._route.params.subscribe((params) => {
      this.selectedMemberId = params["memberId"];
    });

    this.getAllMembers();

    const _this = this;
    this.columnDefs = [
      {
        headerName: "First Name",
        field: "firstName",
        suppressMovable: true,
        sortable: true,
        filter: true,
      },
      {
        headerName: "Last Name",
        field: "lastName",
        suppressMovable: true,
        sortable: true,
        filter: true,
      },

      {
        headerName: "Relation",
        field: "relation",
        suppressMovable: true,
        sortable: true,
      },
      {
        headerName: "Date of Birth",
        field: "dateOfBirth",
        suppressMovable: true,
        sortable: true,
        valueFormatter: this.dateFormatter,
      },
      {
        headerName: "",
        field: "id",
        suppressMovable: true,
        cellRendererFramework: BtnCellRendererComponent,
        cellRendererParams: {
          showDeleteBtn: false,
          showEditBtn: true,
          showDeActivateBtn: false,
          clicked: function (
            isDelete: boolean,
            id: any,
            isDeactivate: boolean
          ) {
            _this._router.navigate([
              `admin/groups/${_this.memberId}/familymember/${id}`,
            ]);
          },
        },
      },
    ];
  }

  dateFormatter(params) {
    return moment(params.value).format("D MMM YYYY");
  }

  onGridReady(params) {
    this._gridApi = params.api;
    this._gridApi.setDomLayout("autoHeight");
  }

  addMember() {
    this.addingFamilyMember = true;
  }

  showAllMembers() {
    this.getAllMembers();
    this.addingFamilyMember = false;
  }

  getAllMembers() {
    this._groupService
      .getGroupMembersFamily(this.memberId)
      .subscribe((members) => {
        this.members = members;
      });
  }
}
