import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { ValidatorsService } from '../../../../../helpers/validators.service';
import { AlertifyService } from '../../../../../services/alertify.service';
import { PeriodScheduleService } from '../../../../../services/periodSchedule.service';


@Component({
  selector: 'app-edit-schedule-plan',
  templateUrl: './edit-period-schedule-plan.component.html',
  styleUrls: ['./edit-period-schedule-plan.component.scss']
})
export class EditPeriodSchedulePlanComponent implements OnInit {

  periodScheduleForm: FormGroup;
  loading = false;
  submitted = false;
  errorMessage: string[] = [];
  @Input() periodScheduleId: string;
  get f() { return this.periodScheduleForm.controls; }

  constructor(private _formBuilder: FormBuilder, private _route: ActivatedRoute,
    private _periodScheduleService: PeriodScheduleService, private _router: Router,
    private _alertify: AlertifyService, private validatorsService: ValidatorsService) {
    this._route.params.subscribe(params => { this.periodScheduleId = params['id']; });
  }

  ngOnInit() {
    this.periodScheduleForm = this._formBuilder.group({
      id: 0,
      periodScheduleId: [0],
      premiumAmount: [0, [Validators.required, this.validatorsService.nonNegativeValidator()]],
      claimAmount: [0, [Validators.required, this.validatorsService.nonNegativeValidator()]],
    });
    
    if (!!this.periodScheduleId) {

      this._periodScheduleService.getSchedulePlanById(Number(this.periodScheduleId)).subscribe(schedule => {
        
        this.periodScheduleForm.patchValue(schedule);

      });

    }

  }

  onSubmit() {
    this.submitted = true;

    // reset alerts on submit
    this.errorMessage = [];

    // stop here if form is invalid
    if (this.periodScheduleForm.invalid) {
      return;
    }

    this.loading = true;

    this.periodScheduleForm.controls.premiumAmount.setValue(+this.periodScheduleForm.value.premiumAmount);
    this.periodScheduleForm.controls.claimAmount.setValue(+this.periodScheduleForm.value.claimAmount);
    if (!!this.periodScheduleId) {
      this._periodScheduleService.updatePeriodSchedulePlan(Number(this.periodScheduleId), this.periodScheduleForm.value)
        .pipe(first())
        .subscribe(
          () => {
            this.loading = false;
            this._alertify.success('Period schedule plan updated successfully.');
            this._router.navigate(['/admin/periodSchedule-plan/' + this.periodScheduleForm.value.periodScheduleId]);

          },
          error => {
            if (error.error instanceof Array) {
              error.error.map(err => this.errorMessage.push(err.value));
            } else if (typeof error.error === 'string') {
              this.errorMessage.push(error.error);
            } else {
              this.errorMessage.push(error);
            }
            this.loading = false;
          });
    } else {

    }
  }

}
