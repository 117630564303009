export class ApiEndPoints {
  public static readonly login: string = 'Account/Login';
  public static readonly register: string = 'Account/Register';
  public static readonly createUser: string = 'Account/CreateUser';
  public static readonly resource: string = 'Resource/';
  public static readonly faq: string = 'FAQ';
  public static readonly askAQuestion: string = 'Member/askaquestion';
  public static readonly externalAPI: string = 'AccountSummary';
  public static readonly dashboard: string = 'Dashboard';
  public static readonly transaction: string = 'AccountTransactions';
  public static readonly memberSumary: string = 'Member/GetMemberById';
  public static readonly getAllMembers: string = 'Member/Admin/GetMembers';
  public static readonly getMemberAcceptedTCs: string = 'Member/Admin/GetAcceptTCs';
  public static readonly memberSearch: string = 'Member/Admin/MemberSearch';
  public static readonly userSearch: string = 'User/Admin/UserSearch';
  public static readonly getMemberFromIdForAdmin: string = 'Member/Admin/member';
  public static readonly getNotificationsForAdmin: string = 'Member/Admin/member/notifications';
  public static readonly getAcceptedTCbyMemberForAdmin: string = 'Member/Admin/member/acceptedtc';

  public static readonly getMember: string = 'Account/GetUserInfo';
  public static readonly askQsn: string = 'Enquiry/GeneralQuestion';
  public static readonly newCard: string = 'Enquiry/RequestNewCard';
  public static readonly getAdd: string = 'Address';
  public static readonly lostCard: string = 'Enquiry/ReportStolenCard';
  public static readonly updateLevel: string = 'Enquiry/UpdateLevel';
  public static readonly updateInfo: string = 'Enquiry/UpdateInfo';
  public static readonly updateTCAccept: string = 'member/updateTermsAndConditions';
  public static readonly getStates: string = 'States';
  public static readonly getSecurityQsn: string = 'SecurityQuestions';
  public static readonly updateSecurityQsn: string = 'Account/UpdateSecurityQuestionWeb';
  public static readonly updatePassword: string = 'Account/ChangePassword';
  public static readonly updateProfile: string = 'Account/ProfileUpdate';
  public static readonly updateProfilePic: string = 'UploadDishImage';
  public static readonly saveCroppedImage: string = 'Account/SaveCroppedImage';
  public static readonly updateEmailByMember: string = 'member/UpdateEmail';
  public static readonly sendContract: string = 'member/SendContract';

  public static readonly forgotPassword: string = 'Account/ForgotPassword';
  public static readonly addFaq: string = 'faq/Admin/AddFaq';
  public static readonly getFaqs: string = 'faq/Admin/GetFaqs';
  public static readonly getFaqById: string = 'faq/Admin/GetFaqById';
  public static readonly updateFaq: string = 'faq/Admin/updateFaq';
  public static readonly deleteFaqs: string = 'faq/Admin/DeleteFaq';

  public static readonly addResource: string = 'Resource/Admin/AddResource';
  public static readonly getResource: string = 'Resource/Admin/GetResources';
  public static readonly getResourceById: string = 'Resource/Admin/GetResourceById';
  public static readonly updateResource: string = 'Resource/Admin/updateResource';
  public static readonly deleteResource: string = 'Resource/Admin/DeleteResource';

  public static readonly updateAdminAsMember: string = 'Account/updateAdminAsMember';
  public static readonly removeAdminAsMember: string = 'Account/removeAdminAsMember';
  public static readonly updateStatus: string = 'Member/admin/updateStatus';

  public static readonly getUsers: string = 'User/Admin/Users';
  public static readonly updateAdminUserStatus: string = 'User/admin/updateStatus';
  public static readonly addUser: string = 'User/Admin/CreateAdmin';
  public static readonly editUser: string = 'User/Admin/GetUser';
  public static readonly updateUser: string = 'User/Admin/UpdateAdmin';

  public static readonly updateUserPassword: string = 'User/Admin/ChangePassword';
  public static readonly forgetPasswordGetQuestion: string = 'Account/ForgetPasswordGetQuestion';
  public static readonly resetPassword: string = 'Account/ResetPassword';
  public static readonly changePasswordByAdmin: string = 'Account/Admin/ChangePasswordByAdmin';

  public static readonly addClaim: string = 'claim/member-claim';
  public static readonly updateClaimByMember: string = 'claim/member-claim';
  public static readonly getClaimById: string = 'claim/member-claim';
  public static readonly getClaimByIdForAdmin: string = 'claim/Admin/GetClaim';
  public static readonly downloadClaim: string = 'claim/download';
  public static readonly updateClaim: string = 'claim/Admin/UpdateClaim';
  public static readonly getAllpatients: string = 'claim/patients';
  public static readonly getMemberClaims: string = 'claim/member-claims';
  public static readonly getAdminClaims: string = 'claim/Admin/GetClaims';
  public static readonly exportAdminClaims: string = 'claim/Admin/export-claims';
  public static readonly getClaimSummery: string = 'claim/Admin/ClaimStatusSummary';
  public static readonly getClaimSubmittedwithDate: string = 'claim/Admin/claimsubmittedwithDate';
  public static readonly getDenyReasons: string = 'DenyReason';
  public static readonly updateClaimStatusByAdmin: string = 'claim/Admin/update-status';
  public static readonly approveClaimByAdmin: string = 'claim/Admin/approve-claim';
  public static readonly underReviewClaimByAdmin: string = 'claim/Admin/under-review-claim';
  public static readonly exportAdminPreApproveClaims: string = 'claim/Admin/export-pre-approve-claims';
  public static readonly getApprovedClaims: string = 'claim/Admin/ApprovedClaims';
  public static readonly exportAdminApproveClaims: string = 'claim/Admin/export-approve-claims';
  public static readonly changeClaimStatusToPaymentFailed: string = 'claim/Admin/claim-status-payment-failed';

  public static readonly markClaimCompleted: string = 'claim/Admin/claim-completed';

  public static readonly addLead: string = 'lead/add-leads';
  public static readonly updateLead: string = 'lead/update-leads';
  public static readonly updateLeadFromAdmin: string = 'lead/Admin/update-leads';
  public static readonly setupLeadPayment: string = 'lead/setup-lead-payement';
  public static readonly validateLeadPaymentCallback: string = 'lead/validate-lead-payment-callback';
  public static readonly updateLeadPayment: string = 'lead/update-lead-payment';
  public static readonly createLeadUser: string = 'lead/create-lead-user';
  public static readonly getMainLead: string = 'lead/main-lead';
  public static readonly getLeadsFromFamilyIdForAdmin: string = 'lead/Admin';
  public static readonly getLeadByIdForAdmin: string = 'lead/Admin/lead';
  public static readonly updateCompletedSteps: string = 'lead/update-completed-steps';
  public static readonly validateEnrollment: string = 'lead/validate-enrollment';
  public static readonly validateCaptcha: string = 'lead/validate-captcha';
  public static readonly getRecaptchaPublicKey: string = 'lead/get-recaptcha-public-key';
  public static readonly validateEnrollmentFromFamilyId: string = 'lead/validate-enrollment-family';
  public static readonly validateEnrollmentFromLeadId: string = 'leads/VerifyLeadExist';
  public static readonly getLeadById: string = 'leads/GetLeadById';
  public static readonly getAdminLeads: string = 'lead/Admin/leads';
  public static readonly getLeadsForExport: string = 'lead/Admin/export-leads';
  public static readonly deleteLeadFromAdmin: string = 'lead/Admin/lead';

  public static readonly getAlertsForMember: string = 'alert/alerts-member';

  public static readonly getCityStateFromZipCode: string = 'address/city-state';
  public static readonly validateAddress: string = 'address/validate-address';

  public static readonly validateSetupMemberPayment: string = 'payment/validate-setup-memeber-payment';
  public static readonly updateMemberPayment: string = 'payment/update-member-payment';
  public static readonly getStripePublicKey: string = 'payment/stripe-public-key';

  public static readonly validateSetupForMember: string = 'payment/setup-payment';
  public static readonly updatePaymentForMember: string = 'payment/update-payment';
  public static readonly getAllBankDetails: string = 'payment/bank-informations';
  public static readonly addNewBankDetails: string = 'payment/add-bank-information';
  public static readonly getBanksForMemberFromAdmin: string = 'payment/admin/bank-information';
  public static readonly addBankForMemberFromAdmin: string = 'payment/admin/bank-information/member';
  public static readonly validateRoutingNumber: string = 'enroll/validate-routing-number';
  public static readonly updateBankDetailsForClaim: string = 'payment/bank-informationsForClaim';
  public static readonly addBankInfoWithVoidCheck: string = 'payment/add-bank-information-with-void-check';
  public static readonly bankInformationsForClaimWithVoidCheck: string =
    'payment/bank-informationsForClaim-with-void-check';
}
