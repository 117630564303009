import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertifyService } from 'src/app/services/alertify.service';

@Component({
  selector: 'app-add-dependent',
  templateUrl: './add-dependent.component.html',
  styleUrls: ['./add-dependent.component.css']
})
export class AddDependentComponent implements OnInit {
  submitted = false;
  addDependentForm: FormGroup;
  dependentList: any[] = [];
  minDependentAge: string;
  maxDependentAge: string;
  minChildAge: string;
  isMinDependentAgeValid: boolean = true;
  isMaxDependentAgeValid: boolean = true;
  
  constructor(private _fb: FormBuilder,private _alertify: AlertifyService,
    public dialogRef: MatDialogRef<AddDependentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.dependentList=data.dependentList;
     }

  ngOnInit() {
    this.addDependentForm = this._fb.group({
      relationship:["",Validators.required],
      firstName: ["", Validators.required],
      middleName: [""],
      lastName: ["", Validators.required],
      dob: ["", Validators.required],
      gender: ["", Validators.required],
    });
    var minPA = new Date();
    var yr = minPA.getFullYear();
    var mn = (minPA.getMonth() + 1) > 9 ? (minPA.getMonth() + 1) : '0' + (minPA.getMonth() + 1);
    var dt = minPA.getDate();
    this.minChildAge = yr + '-' + mn + '-' + dt;
    minPA.setFullYear(minPA.getFullYear() - 100);
    yr = minPA.getFullYear();
    mn = (minPA.getMonth() + 1) > 9 ? (minPA.getMonth() + 1) : '0' + (minPA.getMonth() + 1);
    dt = minPA.getDate();
    this.minDependentAge = yr + '-' + mn + '-' + dt;
    var maxPA = new Date();
    maxPA.setFullYear(maxPA.getFullYear() - 21);
    yr = maxPA.getFullYear();
    mn = (maxPA.getMonth() + 1) > 9 ? (maxPA.getMonth() + 1) : '0' + (maxPA.getMonth() + 1);
    dt = maxPA.getDate();
    this.maxDependentAge = yr + '-' + mn + '-' + dt;

  }

  handleOnClose(event: any) {
    this.dialogRef.close(false);
  }

  handleOnSave(event: any) {
    this.submitted=true;
    if (!this.isMaxDependentAgeValid || !this.isMinDependentAgeValid) {
      return;
    }
    if (this.addDependentForm.valid) {
      if(this.addDependentForm.controls.relationship.value=='Spouse' &&  this.dependentList.filter(x=>x.relationship=='Spouse').length>0){
        this._alertify.error("You have already added Spouse");
      }
      else{
        this.dialogRef.close(this.addDependentForm.value);
      }
    }
  }

  get f() {
    return this.addDependentForm.controls;
  }

  dobValidate() {
    const minDate = new Date();
    const minAge = this.addDependentForm.controls.relationship.value == 'Child'? 21 : 100
    minDate.setFullYear(minDate.getFullYear() - minAge);
    const maxDate = new Date();
    const maxAge = this.addDependentForm.controls.relationship.value == 'Child'? 0 : 21
    maxDate.setFullYear(maxDate.getFullYear() - maxAge);
    const dob = new Date(this.addDependentForm.controls.dob.value);
    if (dob < minDate) {
      this.isMaxDependentAgeValid = false;  
    }
    else {
      this.isMaxDependentAgeValid = true;
    }

    if (dob > maxDate) {
      this.isMinDependentAgeValid = false;      
    }
    else {
      this.isMinDependentAgeValid = true;
    }
  }

  updateAgeValidation(){
    if(this.addDependentForm.controls.relationship.value =='Spouse'){
      var minPA = new Date();      
      minPA.setFullYear(minPA.getFullYear() - 100);
      var yr = minPA.getFullYear();
      var mn = (minPA.getMonth() + 1) > 9 ? (minPA.getMonth() + 1) : '0' + (minPA.getMonth() + 1);
      var dt = minPA.getDate();
      this.minDependentAge = yr + '-' + mn + '-' + dt;
      var maxPA = new Date();
      maxPA.setFullYear(maxPA.getFullYear() - 21);
      yr = maxPA.getFullYear();
      mn = (maxPA.getMonth() + 1) > 9 ? (maxPA.getMonth() + 1) : '0' + (maxPA.getMonth() + 1);
      dt = maxPA.getDate();
      this.maxDependentAge = yr + '-' + mn + '-' + dt;
    }
    else if(this.addDependentForm.controls.relationship.value =='Child'){
      var maxPA = new Date();
      maxPA.setFullYear(maxPA.getFullYear() - 21);
      yr = maxPA.getFullYear();
      mn = (maxPA.getMonth() + 1) > 9 ? (maxPA.getMonth() + 1) : '0' + (maxPA.getMonth() + 1);
      dt = maxPA.getDate();
      this.minDependentAge = yr + '-' + mn + '-' + dt;
      this.maxDependentAge = this.minChildAge;
    }
  }

}

